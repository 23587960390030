import React, { useContext, useState } from "react";
import {
  Typography,
  Modal,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Button,
  Grid,
  TextField,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import CancelIcon from "@mui/icons-material/Cancel";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";

const RejectNewReservationModal = (props) => {
  const matches = useMediaQuery("(min-width:450px)");
  const [data, setData] = useState({
    rejectionReject: "",
  });

  const [otherReason, setOtherReason] = useState(false);

  const handleChange = (event) => {
    setOtherReason(event.target.checked);
    console.log(otherReason);
  };

  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };

  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const rejectReservationHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      if (!otherReason && data.rejectionReject === "") {
        snackbar("Please select the reason", "error");
      } else {
        const res = await axios.post(
          `/api/v1/owner/reservation/reject?reservationId=${props.reservationId}`,
          {
            ...data,
          }
        );
        snackbar(res.data.message, "success");
      }

      setIsLoading(false);
      props.handleCloseRejectNewReservationModal();
      props.getAllReservation();
    } catch (error) {
      console.log(error);
      props.handleCloseRejectNewReservationModal();
      setIsLoading(false);

    }
  };

  const mainHeading = {
    fontWeight: 500,
    fontSize: "16px",
    color: "green.secondary",
  };

  const reasonHeading = {
    fontWeight: 600,
    fontSize: "16px",
    color: "black.secondary",
  };

  const btn = {
    textTransform: "capitalize",
    color: "white.main",
    fontWeight: 600,
    fontSize: "18px",
    backgroundColor: "blue.primary",
    "&:hover": {
      backgroundColor: "blue.primary",
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "400px" : "300px",

    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    p: 2,
  };
  const textFieldStyle = {
    width: { xs: "100%", sm: "80%" },
    borderRadius: "5px",
    backgroundColor: "purple.secondary",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black.tertiary",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.rejectNewReservationModal}
        onClose={props.handleCloseRejectNewReservationModal}
      >
        <Fade in={props.rejectNewReservationModal}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{ position: "relative", borderBottom: "1px solid black" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "rgba(13, 30, 55, 0.67)",
                    justifySelf: "start",
                  }}
                >
                  Why ?
                </Typography>
                <IconButton
                  onClick={props.handleCloseRejectNewReservationModal}
                  aria-label="delete"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>

              <Grid
                container
                sx={{
                  borderRadius: "11px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  component="form"
                  onSubmit={rejectReservationHandler}
                >
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="rejectionReject"
                        onChange={onChangeHandler}
                      >
                        <FormControlLabel
                          sx={{ mt: 3 }}
                          value="Reservation Full"
                          disabled={otherReason ? true : false}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography sx={mainHeading}>
                                Reservation Full
                              </Typography>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="Table Not Available"
                          disabled={otherReason ? true : false}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography sx={mainHeading}>
                                Table Not Available
                              </Typography>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="Restaurant is Closed"
                          disabled={otherReason ? true : false}
                          control={<Radio />}
                          label={
                            <div>
                              <Typography sx={mainHeading}>
                                Restaurant is Closed
                              </Typography>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    <Checkbox
                      checked={otherReason}
                      onChange={handleChange}
                      // inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography mt={1} sx={reasonHeading}>
                      Other Reason
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      name="rejectionReject"
                      required
                      placeholder="Reason"
                      sx={textFieldStyle}
                      disabled={otherReason ? false : true}
                      onChange={onChangeHandler}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button type="submit" variant="outlined" sx={btn}>
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RejectNewReservationModal;
