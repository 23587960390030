import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import { time } from "../../ExtractTime";
const OrderItemCard = (props) => {
  let orderTime = "";
  // orderTime = time(props.item ? props.item.deliveredAt : "2023-05-08T10:13:28.686Z");
  orderTime = time(props.item.deliveredAt);
  const findDate = (str) => {
    const date = new Date(str);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };
  const date = findDate(props.item ? props.item.createdAt : "2023-05-08T10:13:28.686Z");

  const handOverTime = (createdAt, updatedAt) => {
    const createdDate = new Date(createdAt);
    const updatedDate = new Date(updatedAt);
    const timeDifferenceInMilliseconds = updatedDate - createdDate;

    const timeDifferenceInMinutes = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    );

    return timeDifferenceInMinutes;
  };
  const handOverTIme = handOverTime(props.item.createdAt, props.item.updatedAt);
  return (
    <>   
      <Grid item xs={12} sm={6} md={4} p={3} >
        <Box mt={2} sx={{ boxShadow: 5, borderRadius: "15px",  }}>
          <Card elevation={0}>
            <CardContent
              elevation={10}
              sx={{
                backgroundColor: "whisper.main",
                width: "100%",
                boxShadow: 2,
                borderRadius: "10px",
                minHeight:220,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    background:
                      "linear-gradient(90deg, #0DC18B 28.15%, rgba(13, 193, 139, 0) 94.07%)",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "white.main",
                    }}
                  >
                    DELIVERED
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      mb: 1,
                      textDecoration: "underline",
                      textUnderlineOffset: "3px",
                    }}
                  >
                    ID : {props.item.orderId}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={0}>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 400,
                      color: "black.primary",
                    }}
                  >
                    {orderTime} | {date}
                    
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: 600,
                        color: "black.primary",
                      }}
                    >
                     By {props.item.deliveryPartner.name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={0}>
                <Grid item xs={9}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 400,
                      color: "black.primary",
                    }}
                  >
                    {props.item.orderItems.map((item, index) => (
                      <>{`${item.quantity} X ${item.menuItemTitle}, `}</>
                    ))}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: 600,
                        color: "black.primary",
                      }}
                    >
                      ₹
                      {Math.round(
                        (props.item.totalTax + props.item.totalPrice) * 100
                      ) / 100}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                mt={1}
                pb={2}
                sx={{
                  background:
                    "linear-gradient(90.05deg, #D5FFD5 6.79%, rgba(255, 181, 94, 0) 94.36%)",
                  display: "flex",
                  alignItems: "center",
                  mb: -2,
                }}
              >
                <Grid
                  item
                  xs={8}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {/* {props.item.status}  */}
                  <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "black.primary",
                      }}
                    >
                      {" "}
                      Handover time{" "}
                    </Typography>
                  {/* {handOverTIme < props.item.preparationTime ? (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "black.primary",
                      }}
                    >
                      {" "}
                      Handover on time{" "}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "red.tertiary",
                      }}
                    >
                      {" "}
                      Delay in food handover{" "}
                    </Typography>
                  )} */}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 500,
                      color: "black.primary",
                    }}
                  >
                    {handOverTIme}:00 min
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  );
};

export default OrderItemCard;
