import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PickedUpItemDetailsModal from "../modals/PickedUpItemDetailsModal";
import { time } from "../../ExtractTime";

import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const PickedUpItemCard = (props) => {
  const [openPickedUpItemDetailModal, setOpenPickedUpItemDetailModal] =
    useState(false);
  const handlOpenPickedUpItemDetailModal = () =>
    setOpenPickedUpItemDetailModal(true);
  const handlClosePickedUpItemDetailModal = () =>
    setOpenPickedUpItemDetailModal(false);

  let orderTime = "";
  orderTime = time(props.pickedUpItem.createdAt);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let orderItemLength = props.pickedUpItem.orderItems.length;
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return (
      <Box
        sx={{
          color: "blue.main",
          fontSize: "14px",
          display: orderItemLength <= 1 ? "none" : "block",
        }}
      >
        See More  <IconButton color="blue" {...other} />
      </Box>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <>
      <Grid item xs={12} sm={6} md={4} p={3} mt={3}>
        <Card elevation={0}>
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
              minHeight: 370,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sx={{
                  backgroundColor: "red.light",
                  borderRadius: "10px 0px 10px 0px",
                }}
              >
                <Typography
                  onClick={handlOpenPickedUpItemDetailModal}
                  sx={{
                    color: "white.main",
                    fontSize: "20px",
                    fontWeight: 600,
                    mb: 2,
                    cursor: "pointer",
                  }}
                >
                  {`Picked up >`}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: 1,
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                  ID : {props.pickedUpItem.orderId}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={0}
              mb={2}
              sx={{
                background:
                  "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
              }}
            >
              <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 2,
                    color: "red.tertiary",
                  }}
                >
                  YBITES DELIVERY
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 1 }}>
                  {orderTime}
                </Typography>
              </Grid>
            </Grid>

            {props.pickedUpItem.orderItems.map((item, index) =>
              index !== 0 ? (
                <></>
              ) : (
                <>
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <Box sx={{ display: "flex" }}>
                        <img
                          src="/images/orderItemImg.svg"
                          alt="orderItemImg"
                        />
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                        >
                          {item.quantity} X {item.menuItemTitle}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {item.customizationItemTitles.map(
                          (customizationItemTitles, index) =>
                            `${customizationItemTitles}, `
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        Add Ons -
                        {item.addOnItemTitles.map(
                          (addOnItemTitles, index) => ` ${addOnItemTitles}, `
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {item.optionItemTitles.map(
                          (optionItemTitles, index) => `${optionItemTitles}, `
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                        ₹{Math.round(item.totalPrice * 100) / 100}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )
            )}

            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {props.pickedUpItem.orderItems.map((item, index) =>
                index === 0 ? (
                  <></>
                ) : (
                  <>
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={8}>
                        <Box sx={{ display: "flex" }}>
                          <img
                            src="/images/orderItemImg.svg"
                            alt="orderItemImg"
                          />
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                          >
                            {item.quantity} X {item.menuItemTitle}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          {item.customizationItemTitles.map(
                            (customizationItemTitles, index) =>
                              `${customizationItemTitles}, `
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          Add Ons -
                          {item.addOnItemTitles.map(
                            (addOnItemTitles, index) => ` ${addOnItemTitles}, `
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          {item.optionItemTitles.map(
                            (optionItemTitles, index) => `${optionItemTitles}, `
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", justifyContent: "end" }}
                      >
                        <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                          ₹{Math.round(item.totalPrice * 100) / 100}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )
              )}
            </Collapse>

            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,

                    color: "black.tertiary",
                  }}
                >
                  {/* Instr- We want 2 different parcel of veg and non-veg */}
                  Instr - {props.pickedUpItem.note}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={0}
              sx={{ backgroundColor: "purple.tertiary" }}
            >
              <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,

                    color: "black.tertiary",
                    mt: -1.5,
                  }}
                >
                  Total Price
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "black.tertiary",
                    mt: -1.5,
                  }}
                >
                  ₹{Math.round(props.pickedUpItem.price * 100) / 100}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,

                    color: "black.tertiary",
                  }}
                >
                  Taxes
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "black.tertiary",
                  }}
                >
                  {`₹${Math.round(props.pickedUpItem.totalTax * 100) / 100}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Total Bill
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 400,
                      mx: 1,
                      color: "black.primary",
                      border: "1px solid rgba(0, 153, 80, 0.4)",
                      borderRadius: "2px",
                      letterSpacing: "0.265em",
                      px: 1,
                    }}
                  >
                    PAID
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "black.primary",
                    }}
                  >
                    ₹
                    {Math.round(
                      (props.pickedUpItem.totalTax +
                        props.pickedUpItem.totalPrice) *
                        100
                    ) / 100}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* onClick={handlOpenPickedUpItemDetailModal} */}
          </CardContent>
        </Card>
      </Grid>

      <PickedUpItemDetailsModal
        openPickedUpItemDetailModal={openPickedUpItemDetailModal}
        handlClosePickedUpItemDetailModal={handlClosePickedUpItemDetailModal}
        pickedUpItem={props.pickedUpItem}
      />
    </>
  );
};

export default PickedUpItemCard;
