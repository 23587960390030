import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import ComplaintsTabs from "./complaintsTabs/ComplaintsTabs"
import TabHeading from "./complaintsTabs/TabHeading"
import { useLocation } from "react-router-dom";
const Complaints = () => {
  const location = useLocation();
  const currentTabHeading = location.pathname;
  return (
    <>
    <ComplaintsTabs />
    <Container fixed mx={3}>
      {currentTabHeading === "/complaints/ticket-history" ? <></> :  <TabHeading /> }
     
      
      <Outlet />
    </Container>
  </>
  )
}

export default Complaints