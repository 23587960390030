import { Paper, Box, Grid, Container, Button } from "@mui/material";
import React from "react";

import { NavLink, useLocation } from "react-router-dom";

const active = {
  width: "auto",

  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
  color: "white",

  padding: 10,
  textTransform: "capitalize",
  fontSize: {xs:"13px", sm:"15px", md:"17px"},
  fontWeight: 500,
  mt: 1,
  textDecoration: "none",
  borderRadius: "5px",
};
// { textDecoration: "none", borderRadius: "11px" }
const style = {
  width: "auto",

  // background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
  // boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
  border: "1px solid rgba(0, 0, 0, 0.26)",
  color: "rgba(0, 0, 0, 0.42)",

  padding: 10,
  textTransform: "capitalize",
  fontSize: {xs:"13px", sm:"15px", md:"17px"},
  fontWeight: 500,
  mt: 1,
  textDecoration: "none",
  borderRadius: "5px",
};
const linkBox = { mx: { xs: 0.5, sm: 1, md: 2 }, mt: 4 };

const MenuTabs = (props) => {
  const location = useLocation();
  const currentUrl = location.pathname;

  return (    
    <>
      <Paper
        elevation={3}
        sx={{ px: 5, pb: 4, display: "flex", justifyContent: "start" }}
      >
        <Grid container>
          <Grid item>
            <Box onClick={props.handleChange} sx={linkBox}>
              <NavLink
                to="/menu"
                style={
                  currentUrl === "/menu" ||
                  currentUrl === "/menu/add-menu-item" ||
                  currentUrl === "/menu/edit-menu-item"   
                    ? active
                    : style
                }
              >
                Menu items
              </NavLink>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={linkBox}>
              <NavLink
                to="addOn"
                style={currentUrl === "/menu/addOn" ? active : style}
              >
                Add-ons
              </NavLink>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={linkBox}>
              <NavLink
                to="menu-category-details"
                style={
                  currentUrl === "/menu/menu-category-details" ? active : style
                }
              >
                Category Details
              </NavLink>
            </Box>
          </Grid>

          {/* <Grid item>
            <Box sx={{ mx: 4, mt: 4 }}>
              <NavLink
                to="menu-edit-history"
                style={currentUrl === "/menu/menu-edit-history" ? active : style}
              >
                Menu Edit History
              </NavLink>
            </Box>
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
};

export default MenuTabs;
