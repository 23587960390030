import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

const RefundedCard = () => {
  return (
    <>
      <Grid item xs={12} sm={6} p={3} mt={3}>
        <Card
          elevation={0}
          sx={{
            borderRadius: "10px",
          
          }}
        >
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
              backgroundImage: `url(${"/images/refunded.svg"})`,

              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black.tertiary",
                  }}
                >
                  Richa Jaiswal
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box sx={{ display: "flex" }}>
                  <img src="/images/orderItemImg.svg" alt="orderItemImg" />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      mx: 1,
                      color: "black.tertiary",
                    }}
                  >
                    2 x Veg Thali
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 700,
                    color: "black.tertiary",
                  }}
                >
                  ₹ 398
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.tertiary",
                  }}
                >
                  Total Bill
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.tertiary",
                  }}
                >
                  ₹ 110
                </Typography>
              </Grid>
            </Grid>

            <Grid container pt={1} spacing={2}>
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "red.light",
                  }}
                >
                  Poor packaging
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default RefundedCard;
