export const time = (str) => {

  const timeInput = str;
  const date = new Date(timeInput);
  // const formattedTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  const formattedTime = new Intl.DateTimeFormat('en-IN', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(date);
  // console.log("local time is  ",formattedTime);
  return formattedTime;
    // const date = new Date(str);

    // // Extract hours, minutes, and seconds from the Date object
    // const hours = date.getUTCHours();
    // const minutes = date.getUTCMinutes();
    // const seconds = date.getUTCSeconds();

    // // Convert hours to 12-hour format
    // const hours12 = hours % 12 || 12;

    // // Determine if it's AM or PM
    // const period = hours < 12 ? "AM" : "PM";

    // // Construct the time string in AM/PM format
    // const timeString = `${hours12}:${String(minutes).padStart(
    //   2,
    //   "0"
    // )} ${period}`;

    // return formattedTime;
  };