import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { AdminDataContext } from "./../../../useContext/AdminContext";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@emotion/react";
import DragsAndDrop from "../../../reusableComponent/imageUpload/DragsAndDrop";

const CreateMenuItem = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuSubCategories, setSubMenuCategories] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [addOnIds, setAddOnIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cuisine, setCuisine] = useState([]);
  const [image, setImage] = useState("");
  const [menuItemData, setMenuItemData] = useState({
    discount: 0,
    unitPrice: 0,
    price: 0,
  });
  // /category

  const getAllMenuCategory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/menu-category?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMenuCategories(res.data.menuCategory);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllCategory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/owner/category`);
      setCategories(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllCuisine = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/owner/cuisine`);
      setCuisine(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllAddOns = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/addon?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setAddOns(res.data.addOn);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCuisine();
    getAllCategory();
    getAllAddOns();
    getAllMenuCategory();
  }, []);

  const onChangeHandlerMenuCategories = (event) => {
    setMenuItemData({
      ...menuItemData,
      [event.target.name]: event.target.value._id,
      menuSubCategory: null,
    });

    setSubMenuCategories(event.target.value.menuSubCategories);
  };
  const onChangeHandler = (event) => {
    if (event.target.name === "discount") {
      let price =
        menuItemData.unitPrice -
        (event.target.value * menuItemData.unitPrice) / 100;
      setMenuItemData({
        ...menuItemData,
        [event.target.name]: event.target.value,
        price: Math.floor(price),
      });
    } else if (event.target.name === "unitPrice") {
      let price =
        event.target.value - (menuItemData.discount * event.target.value) / 100;
      setMenuItemData({
        ...menuItemData,
        [event.target.name]: event.target.value,
        price: Math.floor(price),
      });
    } else {
      setMenuItemData({
        ...menuItemData,
        [event.target.name]: event.target.value,
      });
    }
    console.log(menuItemData);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/menuItem?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        {
          ...menuItemData,
          addOnIds,
        }
      );
      if (image) {
        let formData = new FormData();
        formData.append("image", image);
        const res2 = await axios.patch(
          `/api/v1/owner/menuItem?menuItemId=${res.data.menuItem._id}`,
          formData
        );
      }
      snackbar(res.data.message, "success");
      setIsLoading(false);
      // getAllMenuCategory();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  return (
    <Grid
      container
      sx={{ margin: "10px", justifyContent: "center" }}
      component="form"
      onSubmit={submitHandler}
    >
      <Grid item sm={6}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Title"
            name="title"
            required
            variant="outlined"
            value={menuItemData.title}
            onChange={onChangeHandler}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Description"
            name="description"
            variant="outlined"
            required
            value={menuItemData.description}
            onChange={onChangeHandler}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Unit Price"
            name="unitPrice"
            type="number"
            variant="outlined"
            required
            value={menuItemData.unitPrice}
            onChange={onChangeHandler}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Discount"
            name="discount"
            type="number"
            variant="outlined"
            max="100"
            required
            value={menuItemData.discount}
            onChange={onChangeHandler}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Final Price"
            name="price"
            type="number"
            disabled
            variant="outlined"
            required
            value={menuItemData.price}
            // onChange={onChangeHandler}
          />

          {/* discount */}
          <TextField
            fullWidth
            id="outlined-basic"
            label="Preparation Time"
            name="preparationTime"
            variant="outlined"
            type="number"
            required
            value={menuItemData.preparationTime}
            onChange={onChangeHandler}
          />
        </Stack>
      </Grid>
      <Grid item sm={6}>
        <Stack spacing={2}>
          <FormControl required fullWidth>
            <InputLabel id="select-label">Select Food Type</InputLabel>
            <Select
              labelId="select-label"
              name="menuItemType"
              onChange={onChangeHandler}
            >
              <MenuItem value="Veg" selected>
                Veg
              </MenuItem>
              <MenuItem value="Non-Veg">Non-Veg</MenuItem>
              <MenuItem value="Jain">Jain</MenuItem>
            </Select>
          </FormControl>

          <FormControl required fullWidth>
            <InputLabel id="select-label">Select Menu Category</InputLabel>
            <Select
              labelId="select-label"
              name="menuCategory"
              onChange={onChangeHandlerMenuCategories}
              required
            >
              {menuCategories.map((item) => (
                <MenuItem key={item._id} value={item}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Sub Menu Category</InputLabel>
            <Select
              labelId="select-label"
              name="menuSubCategory"
              onChange={onChangeHandler}
            >
              <MenuItem value={null}>null</MenuItem>
              {menuSubCategories.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Cuisine</InputLabel>
            <Select
              labelId="select-label"
              name="cuisine"
              onChange={onChangeHandler}
            >
              <MenuItem value={null}>null</MenuItem>
              {cuisine.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-label">Select Category</InputLabel>
            <Select
              labelId="select-label"
              name="category"
              onChange={onChangeHandler}
              required
            >
              <MenuItem value={null}>null</MenuItem>
              {categories.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Autocomplete
            multiple
            id="tags-outlined"
            sx={{ width: "100%" }}
            options={addOns}
            getOptionLabel={(option) => `${option.title}`}
            onChange={(event, value) => {
              // console.log(value);
              const array = [];
              for (let index = 0; index < value.length; index++) {
                const element = value[index];
                array.push(element._id);
              }
              setAddOnIds(array);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Add On"
                placeholder="Select Add On"
              />
            )}
          />
        </Stack>
      </Grid>
      <Stack spacing={2} m={2}>
        <Box width="400px">
          <DragsAndDrop
            heading="Upload Image"
            inputName="Image"
            aspect={2 / 1}
            uploadFile={(x) => {
              setImage(x);
            }}
          />
        </Box>
        <Button variant="contained" type="submit">
          Create Menu
        </Button>
      </Stack>
    </Grid>
  );
};

export default CreateMenuItem;
