import React, { useContext, useEffect, useState } from "react";

import { Grid, Box } from "@mui/material";
import PreparingItemCard from "./card/PreparingItemCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { Pagination } from "@mui/material";

const Preparing = () => {
  const { setIsLoading, snackbar, setApiCall } = useContext(AdminDataContext);
  const [preparingItems, setPreparingItems] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [pages, setPages] = useState(1);
  setApiCall(false);
  const limit = 12;
  const handlePageClick = async (event, pageNo) => {
    try {
      setPages((p) => {
        return pageNo;
      });
      getAllPreparingItems(pageNo);
    } catch (error) {}
  };  
  




  const getAllPreparingItems = async (page) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/orders?page=${page}&limit=${limit}&restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&status=Preparing`
      );

      setPreparingItems(res.data.data);
      setTotalItems(res.data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPreparingItems(1);
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getAllPreparingItems(1);
  //   }, 30000);
  //   return () => clearInterval(interval);
  // }, []);

    return (
    <>  
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "start" }}
      >
        {preparingItems.map((preparingItem, index) => (
          <PreparingItemCard key={index} preparingItem={preparingItem} pages = {pages} getAllPreparingItems = {getAllPreparingItems}  />
        ))}

        {preparingItems.length < 1 ? (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              marginTop={7}
              sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}
              src="/images/noPreparingFoodAvailable.svg"
            ></Box>
          </Grid>
        </>
      ) : (
        <></>
      )}

      </Grid>

      <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            sx={{ justifyContent: { xs: "center", md: "end" }, my: 3 }}
          >
            <Pagination
              count={
                Math.trunc(totalItems / limit) +
                (totalItems % limit === 0 ? +0 : +1)
              }
              color="secondary"
              onChange={handlePageClick}
            />
          </Grid>
        </Grid>

    </>
  );
};

export default Preparing;
