import React, { useEffect, useContext } from "react";
import { Container, Paper, Box, Grid, Divider } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Button } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { useNavigate, Route, Routes } from "react-router-dom";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { TextField } from "@mui/material";

import { styled } from "@mui/material/styles";
import axios from "axios";
import { AdminDataContext } from "../../../../useContext/AdminContext";

const YourAccount = () => {
  const { snackbar, setIsLoading } = useContext(AdminDataContext);

  const [bankDetails, setBankDetails] = useState({});

  const onBankDetailChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  const getBankDetails = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/details?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );

      setBankDetails(res.data.data.bankDetail);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  

useEffect(() => {
  getBankDetails();
}, [])


const textFieldStyle = {
  width: { xs: "100%", sm: "80%" },
  borderRadius: "10px",

  color: "rgba(255, 255, 255, 0.96)",
  border: "1px solid black",
  "& fieldset": {
    border: "none",
    color: "rgba(255, 255, 255, 0.96);",
  },
}


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 3,
        }}
      >
        <form
          style={{ width: "100%" }}
          // onSubmit={(e) => handleBankDetailSubmit(e)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Bank Name*
              </Typography>

              <TextField
                name="bankName"
                value={bankDetails.bankName}
                //  defaultValue={restaurantDetails.bankDetail.bankName}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                disabled
                required
                sx={textFieldStyle}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Account Holder Name*
              </Typography>

              <TextField
                name="accountHolderName"
                value={bankDetails.accountHolderName}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                disabled
                required
                sx={textFieldStyle}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Branch*
              </Typography>

              <TextField
                name="branch"
                value={bankDetails.branch}
                // defaultValue={restaurantDetails.bankDetail.branch}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                disabled
                type="text"
                required
                sx={textFieldStyle}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                IFSC Code*
              </Typography>

              <TextField
                name="ifscCode"
                value={bankDetails.ifscCode}
                // defaultValue={restaurantDetails.bankDetail.ifscCode}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                disabled
                required
                sx={textFieldStyle}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Account Number*
              </Typography>

              <TextField
                name="accountNumber"
                value={bankDetails.accountNumber}
                // defaultValue={restaurantDetails.bankDetail.accountNumber}
                onChange={(e) => onBankDetailChange(e)}
                size="small"
                disabled
                type="number"
                required
                sx={textFieldStyle}
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { xs: "center", sm: "end" },
              }}
            >
              {/* <Button
                // type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                  color: "white.main",
                  me: 5,
                }}
              >
                Submit
              </Button> */}
            </Box>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default YourAccount;
