import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import { Grid, Box } from "@mui/material";
import NewReservationCard from "./card/NewReservationCard";


const NewReservation = () => {
  const [newReservation, setNewReservation] = useState([]);
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const getAllReservation = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/reservation?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&page=1&limit=10`
      );
      
      setNewReservation(res.data.data)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


useEffect(() => {
  getAllReservation();
}, [])
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {newReservation.map((reservation, index) => (
          <NewReservationCard 
          key={index}
          reservation = {reservation}
          getAllReservation = {getAllReservation}
          />
        ))}

        {newReservation.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}

                src="/images/zeroReservationImg.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default NewReservation;
