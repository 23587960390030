import React, { useEffect, useState, useContext } from "react";
import { Grid, Box } from "@mui/material";
import OrderItemCard from "./card/OrderItemCard";
import OrderItemDetailsCard from "./card/OrderItemDetailsCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { Pagination } from "@mui/material";


const PastOrders = () => {
  const { setIsLoading, snackbar, setApiCall } = useContext(AdminDataContext);
  const [pastOrderItems, setPastOrderItems] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [pages, setPages] = useState(1);
  setApiCall(false)

  const limit = 12;
  const handlePageClick = async (event, pageNo) => {
    try {
      setPages((p) => {
        return pageNo;
      });
      getAllPastOrderItems(pageNo);
    } catch (error) {}
  };

  const getAllPastOrderItems = async (page) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/orders?page=${page}&limit=${limit}&restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&status=Delivered`
      );

      setPastOrderItems(res.data.data);
      setTotalItems(res.data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPastOrderItems(1);
  }, []);
 
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: {xs:"center", md:"start"} }}
      >
        {pastOrderItems.map((item, index) => (
          <OrderItemCard key={index} item={item} />
        ))}

        {pastOrderItems.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}
                src="/images/noPastOrderAvai.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            sx={{ justifyContent: { xs: "center", md: "end" }, my: 3 }}
          >
            <Pagination
              count={
                Math.trunc(totalItems / limit) +
                (totalItems % limit === 0 ? +0 : +1)
              }
              color="secondary"
              onChange={handlePageClick}
            />
          </Grid>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={12}
          md={4}
          // border={2}
          sx={{
            maxHeight: "90vh",
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {pastOrderItems.map((item, index) => (
            <OrderItemCard key={index} item={item} />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          //  border={2}
        >
          <OrderItemDetailsCard />
        </Grid> */}
      </Grid>
    </>
  );
};

export default PastOrders;
