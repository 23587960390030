import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyALAjNno_hjBiv6dpe272fMx8kqRmFQMWM",
  authDomain: "ybites-3d723.firebaseapp.com",
  projectId: "ybites-3d723",
  storageBucket: "ybites-3d723.appspot.com",
  messagingSenderId: "192222808663",
  appId: "1:192222808663:web:489ef2289eed0c6572f112",
  measurementId: "G-1026YHXCG6",
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const messaging = getMessaging(firebase);
function requestPermission() {
  // console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BMZbqUoUzFCy192ekaffP3QikTvvcfja15xcwK6_mHWC1r19c9NrM5RuDNnzZJ7QibDty7qRFLpCyXWV7g5LGsw",
      })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("Firebase Token", currentToken);
            return currentToken;
          } else {
            // Show permission request UI
            // console.log(
            //   "No registration token available. Request permission to generate one."
            // );
            return null;
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
      console.log("Notification permission granted.");
    } else {
      return null;
    }
  });
}

const getNotificationToken = () => {
  requestPermission();
};

// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   // ...
// });
export { firebase, auth, getNotificationToken };
