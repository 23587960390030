import React, { useState, useContext, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import PendingComplaintsCard from "./card/PendingComplaintsCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";

const PendingComplaints = () => {
  const [pendingComplaints, setPendingComplaints] = useState([]);
  // /api/v1/owner/order/complain?restaurantId=63f50656e9f939f9ed66b268&page=1&limit=10

  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const limit = 10;
  const getAllPendingComplaints = async () => {
    try {
      setIsLoading(true);
      const res = await axios.put(
        `/api/v1/owner/order/complain?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&page=${1}&limit=${limit}`
      );
      setPendingComplaints(res.data.data);
      setIsLoading(false);  
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllPendingComplaints();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {pendingComplaints.map((complaint, index) => (
          <PendingComplaintsCard
            key={index}
            complaint={complaint}
            getAllPendingComplaints={getAllPendingComplaints}
          />
        ))}

        {pendingComplaints.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}

                src="/images/zeroPendingComplaints.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default PendingComplaints;
