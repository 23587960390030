import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import { Grid, Box, Typography } from "@mui/material";
import TodaysReservationCard from "./card/TodaysReservationCard";


const TodaysReservation = () => {
  const [todaysReservation, setTodaysReservation] = useState([]);
  // /api/v1/owner/reservation/date?restaurantId=63f50656e9f939f9ed66b268&page=1&limit=10
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const date = new Date();
  const todayDate = date.toLocaleDateString('en-CA');

  const getAllTodayReservation = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/reservation/date?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&page=1&limit=10`, {"date": todayDate}
      );
      
      setTodaysReservation(res.data.data)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

    }
  };


useEffect(() => {
  getAllTodayReservation();
}, [])




  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} mt={1}>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "green.secondary" }}
          >
          
          </Typography>
        </Grid>

        {todaysReservation.map((reservation, index) => (
          <TodaysReservationCard 
          key={index}
          reservation = {reservation}
          getAllTodayReservation = {getAllTodayReservation}
          
          />
        ))}

        {todaysReservation.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}
                src="/images/zeroTodayReservation.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default TodaysReservation;
