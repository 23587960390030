import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import {
  Typography,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import OtpInput from "react18-input-otp";

import { Stack } from "@mui/system";
import axios from "axios";

import { AdminDataContext } from "../../useContext/AdminContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { auth, firebase } from "./../firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { getNotificationToken } from "./../firebase";

const Login = ({ getAdminDataApi }) => {
  const { snackbar, setIsLoading } = useContext(AdminDataContext);
  const matches = useMediaQuery("(min-width:1100px)");
  const [phoneNumber, setPhoneNumber] = useState();
  const [otp, setOtp] = React.useState("");
  const [final, setfinal] = React.useState("");
  const [createData, setCreateData] = React.useState({});
  const [token, setToken] = React.useState("");
  const changeHandler = (event) => {
    setCreateData({ ...createData, [event.target.name]: event.target.value });
  };

  const handleOtpChange = (enteredOtp) => {
    setOtp(enteredOtp);
     
  };

  const [index, setIndex] = React.useState(0);
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth
    );
  }, []);
  const sendOtpHandler = () => {
    setIsLoading(true);
    signInWithPhoneNumber(
      auth,
      "+" + `+91${phoneNumber}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
         setfinal(confirmationResult);
        snackbar("Otp Send to your Phone Number", "success");
        setIsLoading(false);
        setIndex(1);
      })

      .catch((error) => {
        console.log(error);
        // Error; SMS not sent
        // ...
      });
  };
  const signUpHandler = async (e) => {
    try {
      e.preventDefault();
      const notificationToken = await getNotificationToken();
      const res = await axios.post("/api/v1/owner/signUp", {
        authToken: token,
        notificationToken,
        ...createData,
      });
      // console.log(res.data);
// console.log("notificationToken ",notificationToken);
      getAdminDataApi();
      snackbar(res.data.message, "success");
    } catch (error) {
      setIndex(3);
    }
  };
  const verifyOtp = (code) => {
    final
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        // console.log(user.accessToken);
        setToken(user.accessToken);
        loginHandler(user.accessToken);
        // ...
      })
      .catch((error) => {
        snackbar("invalid OTP", "error");

      });
  };
  const loginHandler = async (authToken) => {
    try {
      const notificationToken = await getNotificationToken();
      const res = await axios.post("/api/v1/restaurant/login", {
        authToken,
        notificationToken,
      });
      // console.log(res.data);

      getAdminDataApi();
      snackbar(res.data.message, "success");
    } catch (error) {
      setIndex(3);
      snackbar(error.response.data.message, "error");
    }
  };
  const textFieldStyle = {
    boxShadow: 2,
    // width: matches ? "300px" : "340px",
    borderRadius: "10px",
    background: "rgba(217, 217, 217, 0.21)",
    color: "rgba(255, 255, 255, 0.96)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  }



  return (
    <>
      <Container
        sx={{
          alignContent: "center",
          height: "97vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Stack sx={{ width: "100%" }}>
            <Paper
              sx={{
                width: "100%",
                bgcolor: "bgColor.main",
                borderRadius: "20px",
              }}
              elevation={10}
            >
              <Box
                sx={{
                  width: {xs:"90vw", md:"80vw"},
                  height: "80vh",
                }}
              >
                <Grid
                  container
                  display="flex"
                  spacing={0}
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    md={6}
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      display: {
                        xs: "none",
                        md: "block",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: -50,

                        width: "20%",
                        height: "20%",
                        display: matches ? "block" : "none",
                      }}
                      component="img"
                      alt="logo"
                      // sx={{ width: "50px" }}
                      src="/images/loginImg2.svg"
                    ></Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "70%",
                          height: "70%",
                          // border: "5px solid red ",
                        }}
                        component="img"
                        alt="logo"
                        sx={{ width: "50px" }}
                        src="/images/logoCreateRest.svg"
                      ></Box>
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,

                        width: "20%",
                        height: "20%",
                        display: matches ? "block" : "none",
                        borderRadius: "20px",
                      }}
                      component="img"
                      alt="logo"
                      // sx={{ width: "50px" }}
                      src="/images/loginImge1.svg"
                    ></Box>
                  </Grid>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    item
                    alignItems="center"
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      height: "100%",
                      margin: { md: 0 },
                      backgroundColor: "#F75B28",
                      borderRadius: {
                        xs: "20px 20px",
                        md: "0px 20px 20px 0px",
                      },
                      width: "100%",
                    }}
                  >
                    <Stack
                      // display="flex"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0}
                      sx={{ width: "100%" }}
                    >
                      {/* Login component */}
                      <Box
                        display={index === 0 ? "block" : "none"}
                        sx={{ width: "100%" }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ width: "100%" }}
                        >
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            fontSize="18px"
                            sx={{ color: "rgba(255, 255, 255, 0.46)" }}
                          >
                            YBITES Restaurant Partner
                          </Typography>

                          <Typography
                            variant="h4"
                            sx={{ color: "white.main", mt: 1 }}
                            fontWeight={700}
                          >
                            Welcome Back
                          </Typography>

                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="15px"
                            lineHeight="20px"
                            sx={{ color: "rgba(255, 255, 255, 0.71)" }}
                          >
                            Enter your mobile no.
                          </Typography>

                          <Box
                            marginTop={3}
                            sx={{ width: { xs: "85%", sm: "65%", md: "70%" } }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={500}
                              fontSize="16px"
                              sx={{ color: "white.main" }}
                            >
                              Mobile No
                            </Typography>

                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              type="text"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              placeholder="Enter phone number"
                              sx={{
                                boxShadow: 2,
                                // width: matches ? "300px" : "340px",
                                borderRadius: "10px",
                                background: "rgba(255, 255, 255, 0.31)",
                                color: "rgba(255, 255, 255, 0.96)",
                                "& fieldset": {
                                  border: "none",
                                  color: "rgba(255, 255, 255, 0.96);",
                                },
                              }}
                            />
                          </Box>

                          <Button
                            id="sign-in-button"
                            sx={{
                              m: 1,
                              width: { xs: "85%", sm: "65%", md: "70%" },
                              marginTop: "50px",
                              backgroundColor: "bgWhite.main",
                              color: "primary.main",
                              "&:hover": {
                                backgroundColor: "#FFF",
                              },
                            }}
                            variant="contained"
                            size="large"
                            onClick={sendOtpHandler}
                          >
                            SEND OTP
                          </Button>
                        </Box>
                      </Box>

                      {/* OTP Component */}

                      <Box display={index === 1 ? "block" : "none"}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          px={2}
                        >
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            fontSize="18px"
                            sx={{ color: "rgba(255, 255, 255, 0.46)" }}
                          >
                            YBITES Restaurent Partner
                          </Typography>

                          <Typography
                            variant="h4"
                            sx={{
                              color: "white.main",
                              mt: 1,
                              textAlign: "center",
                            }}
                            fontWeight={700}
                          >
                            Enter Verification code
                          </Typography>

                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="15px"
                            lineHeight="20px"
                            sx={{ color: "rgba(255, 255, 255, 0.71)" }}
                          >
                            {`We have sent a verification code to ${phoneNumber}`}
                          </Typography>

                          <Box marginTop={3}>
                            <Box sx={{ width: "100%" }}>
                              <OtpInput
                                value={otp}
                                onChange={handleOtpChange}
                                numInputs={6}
                                inputStyle={{
                                  width: "55%",
                                  height: "40px",
                                  borderRadius: "10px",
                                  background: "rgba(255, 255, 255, 0.31)",
                                  "& fieldset": { border: "none" },
                                  mt: 1,
                                  margin: "auto",
                                  border: "none",
                                  color: "white",
                                  fontSize: "22px",
                                  fontWeight: 700,
                                }}
                                separator={<span> </span>}
                              />
                            </Box>

                            <Typography
                              onClick={sendOtpHandler}
                              variant="h6"
                              fontWeight={500}
                              marginTop="10px"
                              fontSize="15px"
                              lineHeight="20px"
                              sx={{ color: "rgba(255, 255, 255, 0.71)", mx: 2, cursor:"pointer"  }}
                            >
                              Re-send OTP
                            </Typography>
                          </Box>

                          <Button
                            disabled={!(otp.length === 6)}
                            sx={{
                              m: 1,
                              width: "50%",
                              marginTop: "100px",
                              backgroundColor: "bgWhite.main",
                              color: "primary.main",
                              "&:hover": {
                                backgroundColor: "#FFF",
                              },
                              textTransform: "capitalize",
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            size="large"
                            onClick={verifyOtp}
                          >
                            Verify
                          </Button>
                        </Box>
                      </Box>

                      <Box
                        display={index === 3 ? "block" : "none"}
                        sx={{ width: "100%" }}
                      >
                        <Box
                          sx={{ width: "100%" }}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          component="form"
                          onSubmit={signUpHandler}
                        >
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            fontSize="18px"
                            sx={{ color: "white.main" }}
                          >
                            YBITES Restaurent Partner
                          </Typography>

                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="15px"
                            lineHeight="20px"
                            sx={{ color: "rgba(255, 255, 255, 0.71)" }}
                          >
                            Please Enter your Contact Details
                          </Typography>

                          <Box
                            marginTop={3}
                            sx={{ width: { xs: "85%", sm: "65%", md: "70%" } }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={500}
                              fontSize="16px"
                              sx={{ color: "white.main" }}
                            >
                              Mobile No
                            </Typography>

                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              type="text"
                              value={phoneNumber}
                              disabled
                              // onChange={(e) => setPhoneNumber(e.target.value)}
                              placeholder="Enter phone number"
                              sx={textFieldStyle}
                            />
                          </Box>
                          <Box
                            marginTop={3}
                            sx={{ width: { xs: "85%", sm: "65%", md: "70%" } }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={500}
                              fontSize="16px"
                              sx={{ color: "white.main" }}
                            >
                              Name
                            </Typography>

                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              type="text"
                              name="name"
                              value={createData.name}
                              onChange={changeHandler}
                              placeholder="Enter Name"
                              sx={textFieldStyle}
                            />
                          </Box>
                          <Box
                            marginTop={3}
                            sx={{ width: { xs: "85%", sm: "65%", md: "70%" } }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={500}
                              fontSize="16px"
                              sx={{ color: "white.main" }}
                            >
                              Email
                            </Typography>

                            <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              type="email"
                              name="email"
                              value={createData.email}
                              onChange={changeHandler}
                              placeholder="Enter email"
                              sx={textFieldStyle}
                            />
                          </Box>

                          <Box
                            marginTop={3}
                            sx={{ width: { xs: "85%", sm: "65%", md: "70%" } }}
                          >
                            <FormControl
                              sx={{ color: "white.main", marginLeft: "0px" }}
                            >
                              <Typography
                                variant="h6"
                                fontWeight={500}
                                fontSize="16px"
                                sx={{ color: "white.main" }}
                              >
                                Gender
                              </Typography>

                              <RadioGroup
                                sx={{ color: "white.main" }}
                                onChange={changeHandler}
                                name="gender"
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                              >
                                <FormControlLabel
                                  sx={{ color: "white.main" }}
                                  value="Female"
                                  control={<Radio color="white" />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="Male"
                                  control={<Radio color="white" />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Other"
                                  control={<Radio color="white" />}
                                  label="Other"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>

                          <Box
                            marginTop={3}
                            sx={{ width: { xs: "85%", sm: "65%", md: "70%" } }}
                          >
                            <Button
                              id="sign-in-button"
                              sx={{
                                m: 1,
                                width: "100%",

                                backgroundColor: "bgWhite.main",
                                color: "primary.main",
                                "&:hover": {
                                  backgroundColor: "#FFF",
                                },
                              }}
                              variant="contained"
                              size="large"
                              type="submit"
                            >
                              Create Account
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Stack>
        </div>
      </Container>
    </>
  );
};

export default Login;
