import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import EditIcon from "@mui/icons-material/Edit";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { imgUrl } from "../../../../url";
import { AdminDataContext } from "../../../../useContext/AdminContext";

import HelpCenterModal from "../../liveOrders/tabHeading/HelpCenterModal";
import EditItemCategory from "../modal/modalForEditCategory&Subcategory/EditItemCategory";
import EditItemSubCategory from "../modal/modalForEditCategory&Subcategory/EditItemSubCategory";

const AllMenuItem = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [menuItems, setMenuItems] = useState([]);
  const [data, setData] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [expandedForCategory, setExpandedForCategory] = useState(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const hadleCategoryAccordian = (panel) => (event, isExpanded) => {
    setExpandedForCategory(isExpanded ? panel : false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);

  const [helpCenterModal, setHelpCenterModal] = React.useState(false);
  const handleOpenHelpCenterModal = () => setHelpCenterModal(true);
  const handleCloseHelpCenterModal = () => setHelpCenterModal(false);

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseItemModal = () => setOpenItemModal(false);
  const handleOpenModal = (item) => {
    setOpenModal(true);
    setData(item);
  };
  const handleOpenItem = (item) => {
    setOpenItemModal(true);

    setData(item);
  };

  const navigate = useNavigate();
  const onClickEditMenuItem = (singleMenuItemId) => {
    navigate(`edit-menu-item/${singleMenuItemId}`);
  };

  const onClickAddMenuItem = () => {
    navigate("add-menu-item");
  };
  const getMenuItems = async () => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/menuItem?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMenuItems(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMenuItems();
  }, []);

  const availableMenuItem = async (id, available) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/menuItem/available?menuItemId=${id}`,
        { available }
      );
      snackbar(res.data.message, "success");
      setIsLoading(false);
      getMenuItems();
    } catch (error) {
      snackbar(error.response.data.message, "success");
      setIsLoading(false);
    }
  };
 

  const headingTextStyle = {
    fontWeight: 600,
    fontSize: "18px",
    color: "white.main",
    border: "none",

    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };

  const subCategoryHeading = {
    fontWeight: 500,
    fontSize: "16px",
    color: "black.main",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };

  const itemBtn = {
    backgroundColor: "transparent",
    color: "black.secondary",
    // width: "max-content",
    boxShadow: 0,
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "capitalize",
    m: 0,
    p: 0,
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: 0,
    },
  };
  const itemDetails = {
    fontWeight: 600,
    fontSize: "15px",
  };
  const itemEmpty = {
    fontWeight: 500,
    fontSize: "15px",
    color: "yellow.tertiary",
    mx: 1,
  };

  const itemValue = {
    display: "flex",
    justifyContent: "end",
  };

  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",

    paddingY: 1,
    paddingX: { xs: 0.5, sm: 2 },

    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
  };

  const accordianHeading = {
    fontWeight: 600,
    fontSize: "20px",
    mt: "20px",
    mx: 1,
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "whisper.main",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid rgba(0, 0, 0, 0.14)",
          p: { xs: 1, sm: 2 },
          borderRadius: "12px",
        }}
      >
        <Button onClick={onClickAddMenuItem} variant="outlined" sx={active}>
          Add Menu item
        </Button>
        <Button
          onClick={handleOpenHelpCenterModal}
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            // color: "#397DFF",
            color: "blue.primary",
            fontWeight: 600,
            fontSize: "13px",
            border: "1px solid #397DFF",
            p: { xs: 0.8, sm: 1.3 },
            "&:hover": {
              border: "1px solid #397DFF",
            },
          }}
          startIcon={<HelpOutlineIcon />}
        >
          Help Centre
        </Button>
      </Paper>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <Typography sx={accordianHeading}>Menu Item Category</Typography>
          {menuItems.length <= 0 ? (
            <Typography sx={itemEmpty}>
              {"  Menu item category is empty "}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        {menuItems.map((category, index) => (
          <Grid item xs={12} sx={{ marginX: "10px" }}>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
            >
              <AccordionSummary
                sx={{
                  background:
                    "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                  borderRadius: "2px",
                }}
                expandIcon={<ExpandMoreIcon color="white" />}
                aria-controls={`panel${index + 1}a-content`}
                id={`panel${index + 1}a-header`}
              >
                <Button
                  onClick={() => handleOpenModal(category)}
                  variant="outlined"
                  boxShadow={0}
                  sx={headingTextStyle}
                  endIcon={<EditIcon />}
                >
                  {category.title}
                </Button>
              </AccordionSummary>
              <Grid item>
                <Typography sx={accordianHeading}>
                  Menu Item Sub Category
                </Typography>
                {category.menuSubCategories.length <= 0 ? (
                  <Typography sx={itemEmpty}>
                    {"  Menu item sub category is empty "}
                  </Typography>
                ) : (
                  <></>
                )}
              </Grid>
              <AccordionDetails>
                {category.menuSubCategories.map((subCategory, index) => (
                  <Grid xs={12} sx={{ margin: "10px" }}>
                    <Accordion
                      expanded={expandedForCategory === `panel${index}`}
                      onChange={hadleCategoryAccordian(`panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}b-content`}
                        id={`panel${index + 1}b-header`}
                        sx={{
                          backgroundColor: "bgWhite.subAccordian",
                          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                          borderRadius: "2px",
                        }}
                      >
                        <Button
                          onClick={() => handleOpenItem(subCategory)}
                          variant="outlined"
                          boxShadow={0}
                          sx={subCategoryHeading}
                          endIcon={<EditIcon />}
                        >
                          {subCategory.title}
                        </Button>
                      </AccordionSummary>
                      <Typography sx={accordianHeading}>Menu Item</Typography>
                      {subCategory.menuItems.length <= 0 ? (
                        <Typography sx={itemEmpty}>
                          {"  Menu item is empty "}
                        </Typography>
                      ) : (
                        <></>
                      )}

                      <AccordionDetails>
                        <Grid
                          container
                          spacing={2}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          {subCategory.menuItems.map((menuItem, index) => (
                            <>
                              <Grid item spacing={2} xs={12} sm={12} md={6}>
                                <Box
                                  sx={{
                                    backgroundColor: "green.light",
                                    borderRadius: "8px",
                                    py: 1,
                                    px: "10px",
                                    mt: 2,
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Grid item xs={12} sm={12} md={4}>
                                      <Grid item>
                                        <Box
                                          component="img"
                                          width="100%"
                                          src={
                                            menuItem.image
                                              ? `${imgUrl}/${menuItem.image}`
                                              : "/images/ifNoMenuItem.svg"
                                          }
                                          sx={{
                                            minHeight: "130px",
                                            borderRadius: "13px",
                                          }}
                                        ></Box>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                          <Button
                                            onClick={() =>
                                              onClickEditMenuItem(menuItem._id)
                                            }
                                            variant="contained"
                                            sx={itemBtn}
                                            endIcon={
                                              <EditIcon color="primary" />
                                            }
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: 600,
                                                fontSize: {
                                                  xs: "16px",
                                                },
                                              }}
                                            >
                                              {menuItem.title}
                                            </Typography>
                                          </Button>
                                        </Grid>
                                        <Grid item xs={3} sx={itemValue}>
                                          <Box sx={{ m: 0, p: 0 }}>
                                            <FormControlLabel
                                              sx={{ m: 0, p: 0 }}
                                              onChange={(e) =>
                                                availableMenuItem(
                                                  menuItem._id,
                                                  e.target.checked
                                                )
                                              }
                                              control={
                                                <Switch
                                                  checked={menuItem.available}
                                                />
                                              }
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          {" "}
                                          <Typography sx={itemDetails}>
                                            Unit Price:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={itemValue}>
                                          <Typography sx={itemDetails}>
                                            ₹ {menuItem.unitPrice}
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography sx={itemDetails}>
                                            Final Price:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={itemValue}>
                                          <Typography sx={itemDetails}>
                                            ₹ {menuItem.price}
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          {" "}
                                          <Typography sx={itemDetails}>
                                            Discount:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={itemValue}>
                                          <Typography sx={itemDetails}>
                                            {menuItem.discount}%
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography sx={itemDetails}>
                                            Preparation Time:{" "}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={itemValue}>
                                          <Typography sx={itemDetails}>
                                            {menuItem.preparationTime} min
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

                <Grid
                  container
                  spacing={2}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {category.menuItems.length > 0 ? (
                    <Grid item xs={12}>
                      <Typography sx={accordianHeading}>
                        Menu Item Without Sub Category
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {category.menuItems.map((menuItem, index) => (
                    <>
                      <Grid item spacing={2} xs={12} sm={12} md={6}>
                        <Box
                          sx={{
                            backgroundColor: "green.light",
                            borderRadius: "8px",
                            py: 1,
                            px: "10px",
                            mt: 2,
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} sm={12} md={4}>
                              <Grid item>
                                <Box
                                  component="img"
                                  width="100%"
                                  src={
                                    menuItem.image
                                      ? `${imgUrl}/${menuItem.image}`
                                      : "/images/ifNoMenuItem.svg"
                                  }
                                  sx={{
                                    minHeight: "130px",
                                    borderRadius: "13px",
                                  }}
                                ></Box>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                              <Grid container spacing={2}>
                                <Grid item xs={9}>
                                  <Button
                                    onClick={() =>
                                      onClickEditMenuItem(menuItem._id)
                                    }
                                    variant="contained"
                                    sx={itemBtn}
                                    endIcon={<EditIcon color="primary" />}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: {
                                          xs: "16px",
                                        },
                                      }}
                                    >
                                      {menuItem.title}
                                    </Typography>
                                  </Button>
                                </Grid>
                                <Grid item xs={3} sx={itemValue}>
                                  <FormGroup>
                                    <FormControlLabel
                                      onChange={(e) =>
                                        availableMenuItem(
                                          menuItem._id,
                                          e.target.checked
                                        )
                                      }
                                      control={
                                        <Switch checked={menuItem.available} />
                                      }
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  {" "}
                                  <Typography sx={itemDetails}>
                                    Unit Price:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={itemValue}>
                                  <Typography sx={itemDetails}>
                                    ₹ {menuItem.unitPrice}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography sx={itemDetails}>
                                    Final Price:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={itemValue}>
                                  <Typography sx={itemDetails}>
                                    ₹ {menuItem.price}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  {" "}
                                  <Typography sx={itemDetails}>
                                    Discount:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={itemValue}>
                                  <Typography sx={itemDetails}>
                                    {menuItem.discount}%
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography sx={itemDetails}>
                                    Preparation Time:{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={itemValue}>
                                  <Typography sx={itemDetails}>
                                    {menuItem.preparationTime} min
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      <EditItemCategory
        openEditCategoryModal={openModal}
        handleCloseEditCategoryModal={handleCloseModal}
        getMenuItems={getMenuItems}
        component="menu-list"
        setData={setData}
        data={data}
      />

      <EditItemSubCategory
        openEditSubCategoryModal={openItemModal}
        handleCloseEditSubCategoryModal={handleCloseItemModal}
        getMenuItems={getMenuItems}
        component="menu-list"
        setData={setData}
        data={data}
      />

      <HelpCenterModal
        helpCenterModal={helpCenterModal}
        handleCloseHelpCenterModal={handleCloseHelpCenterModal}
      />
    </>
  );
};

export default AllMenuItem;
