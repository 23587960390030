import React from "react";
import { Paper, Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const active = {
  width: "auto",
  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
  color: "white",
  padding: 10,
  textTransform: "capitalize",
  fontSize: { xs: "13px", sm: "15px", md: "17px" },
  fontWeight: 500,
  mt: 1,
  textDecoration: "none",
  borderRadius: "5px",
};

const style = {
  width: "auto",  
  border: "1px solid rgba(0, 0, 0, 0.26)",
  color: "rgba(0, 0, 0, 0.42)",
  padding: 10,
  textTransform: "capitalize",
  fontSize: { xs: "13px", sm: "15px", md: "17px" },
  fontWeight: 500,
  mt: 1,
  textDecoration: "none",
  borderRadius: "5px",
};

const linkBox = { mx: { xs: 0.5, sm: 1, md: 2 }, mt: 4 };

const Tabs = (props) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  return (
    <>
      <Paper
        elevation={3}
        sx={{ px: 5, pb: 4, display: "flex", justifyContent: "start" }}
      >
        <Grid container>
          <Grid item>
            <Box onClick={props.handleChange} sx={linkBox}>
              <NavLink
                to=""
                style={currentUrl === "/manage-outlet" ? active : style}
              >
                About Outlet
              </NavLink>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={linkBox}>
              <NavLink
                to="contact-details"
                style={
                  currentUrl === "/manage-outlet/contact-details"    
                    ? active
                    : style
                }
              >
                Contact Details
              </NavLink>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={linkBox}>
              <NavLink
                to="outlet-timing"
                style={
                  currentUrl === "/manage-outlet/outlet-timing" ? active : style
                }
              >
                Outlet Timings
              </NavLink>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={linkBox}>
              <NavLink
                to="your-account"
                style={
                  currentUrl === "/manage-outlet/your-account" ? active : style
                }
              >
                Account Details
              </NavLink>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={linkBox}>
              <NavLink
                to="documents"
                style={
                  currentUrl === "/manage-outlet/documents" ? active : style
                }
              >
                Documents
              </NavLink>
            </Box>
          </Grid>

          <Grid item>
          <Box  sx={linkBox}>
            <NavLink
              // to=""
              target="_blank" 
              to="https://ybites.com/privacy-policy.html"
              style={currentUrl === "/legal" ? active : style}
            >
              Privacy Policy
            </NavLink>
          </Box>
        </Grid>

        <Grid item>
          <Box sx={linkBox}>
            <NavLink
              target="_blank" 
              to="https://ybites.com/terms-and-conditions.html"
              style={
                currentUrl === "/legal/terms-&-condition"    
                  ? active
                  : style
              }
            >
           Terms and Conditions
            </NavLink>
          </Box>
        </Grid>

        </Grid>
      </Paper>
    </>
  );
};

export default Tabs;
