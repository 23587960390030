export const questions = [
  {
    questions: "How can I sign up as a restaurant partner?",
    answer:
      "You can usually sign up through the app's website or contact their customer support to inquire about becoming a restaurant partner. You may need to provide some information about your restaurant, such as your menu and operating hours",
  },
  {
    questions: "How does the ordering process work? ",
    answer:
      "Customers can browse your restaurant's menu through the app, place an order, and pay using the app's payment system. You will receive the order through the app and can prepare the food for pickup or delivery.",
  },
  {
    questions: "How do I manage my menu?",
    answer:
      "Most food delivery apps allow you to manage your menu through a partner portal. You can add or remove menu items, update prices, and change the availability of certain items.",
  },

  {
    questions: "What if I have issues with an order or delivery?",
    answer:
      " You should contact the app's customer support to report any issues with orders or deliveries. They will typically handle communication with the customer and may offer assistance in resolving the issue.",
  },

{
  questions: "How can I contact customer support?",
  answer: `You can call us on 08069060360, 
  Mail us on - wecare@ybites.com`
},


  {
    questions: "How can I increase my visibility on the app?",
    answer:
      " You can improve your visibility by offering competitive prices, providing high-quality food and service, and maintaining positive customer ratings. You may also be able to participate in promotions or marketing campaigns offered by the app.",
  },
  {
    questions: "How does the app handle refunds or cancellations?",
    answer:
      "The app will usually handle refunds or cancellations initiated by the customer. If the order has already been prepared, the app may still deduct their commission from the refund amount.",
  },

  {
    questions:
      "What if I need to temporarily close my restaurant or change my operating hours? ",
    answer:
      "You should update your availability on the app's partner portal or contact their customer support to make changes to your operating hours. Customers will not be able to place orders during times when your restaurant is closed.",
  },
  {
    questions: "How can I manage my orders efficiently?",
    answer:
      "You can usually view and manage your orders through the app's partner portal or a separate order management system. You should prioritize timely preparation and delivery of orders to ensure customer satisfaction.",
  },
  {
    questions: "Can I offer promotions or discounts through the app? ",
    answer:
      "Many food delivery apps offer promotions or discounts for new or repeat customers. You may also be able to offer your own promotions or discounts through the app's partner portal.",
  },
  {
    questions: "How does the app handle customer reviews and ratings? ",
    answer:
      "Customers can usually leave reviews and ratings for your restaurant through the app. You should monitor these reviews and strive to address any negative feedback or issues. Positive reviews can help improve your visibility and attract more customers.",
  },
  {
    questions: "What if I have questions or issues with the app or my account?",
    answer: `You should contact the app's customer support for assistance with any technical or account-related issues. 
    They may also provide resources or training to help you use the app more effectively.
    `,
  },
];
