import React, { useContext, useState } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Grid,
  IconButton,
  Button,
  TextField,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { AdminDataContext } from "../../../../../useContext/AdminContext";

const RaiseATicketModal = (props) => {
  const matches = useMediaQuery("(min-width:450px)");

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;

  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [data, setData] = useState({});

  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleRaiseATicketSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const res = await axios.post(
        `/api/v1/owner/restaurant/ticket?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        {
          ...data,
        }
      );

      setData({});
      snackbar(res.data.message, "success");

      props.getAllTicketHistory();
      props.handleCloseRaiseModal();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const mainHeading = {
    fontWeight: 600,
    fontSize: "18px",
    color: "black.primary",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "400px" : "300px",

    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
  };

  const textFields = {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    borderRadius: "5px",
    background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openRaiseModal}
        onClose={props.handleCloseRaiseModal}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openRaiseModal}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{
                  position: "relative",
                  backgroundColor: "pink.dawnPink",
                  px: 2,
                  py: 1,
                  borderRadius: "6px ",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "black.primary",
                    justifySelf: "start",
                  }}
                >
                  Raise A Ticket
                </Typography>
                <IconButton
                  onClick={props.handleCloseRaiseModal}
                  aria-label="cancel"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>

              <Grid
                container
                sx={{
                  borderRadius: "11px",
                }}
              >
                <Grid
                  container
                  component={"form"}
                  onSubmit={handleRaiseATicketSubmit}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    mx={2}
                    mt={0}
                    display="flex"
                    justifyContent={"center"}
                  >
                    <Box
                      component={"img"}
                      src="/images/raiseATicket.svg"
                      width="110px"
                    ></Box>
                  </Grid>

                  <Grid item xs={12} mx={2} mb={2}>
                    <Typography sx={mainHeading}>Title*</Typography>

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      required
                      type="text"
                      name="title"
                      onChange={onChangeHandler}
                      value={data.title}
                      placeholder="Title"
                      sx={textFields}
                    />
                  </Grid>
                  <Grid item xs={12} mx={2} mb={2}>
                    <Typography sx={mainHeading}>Description*</Typography>

                    <TextField
                      fullWidth
                      multiline
                      id="outlined-basic"
                      size="small"
                      rows={3}
                      type="text"
                      required
                      name="description"
                      value={data.description}
                      onChange={onChangeHandler}
                      placeholder="Description"
                      sx={textFields}
                    />
                  </Grid>
                  <Grid item xs={12} mx={2} mb={2}>
                    <Typography sx={mainHeading}>Create Date*</Typography>

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      type="text"
                      disabled
                      value={currentDate}
                      placeholder="Create Date"
                      sx={textFields}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    mx={2}
                    mb={2}
                    display="flex"
                    justifyContent={"center"}
                  >
                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{
                        textTransform: "capitalize",
                        color: "white.main",
                        fontWeight: 500,
                        fontSize: "18px",
                        background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                        border: "none",
                        width: "100%",
                        mx: 5,
                        "&:hover": {
                          border: "none",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RaiseATicketModal;
