import React, { useContext, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Divider, Grid, Modal, TextField } from "@mui/material";

import { AdminDataContext } from "./../../useContext/AdminContext";
import axios from "axios";
import { Box } from "@mui/system";
const AddOns = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  //
  const [addOns, setAddOns] = useState([]);
  const [data, setData] = useState({});
  const [createAddOn, setCreateAddOn] = useState(false);
  const handleOpen = () => setCreateAddOn(true);
  const handleClose = () => setCreateAddOn(false);
  const [dataItem, setDataItem] = useState({});
  const [addonId, setAddonId] = useState("");
  const [createAddOnItem, setCreateAddOnItem] = useState(false);
  const handleOpenItem = (id) => {
    setCreateAddOnItem(true);
    setAddonId(id);
  };
  const handleCloseItem = () => setCreateAddOnItem(false);
  const handleChangeItem = (event) => {
    setDataItem({ ...dataItem, [event.target.name]: event.target.value });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const createAddOnHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/owner/addon`, {
        ...data,
        restaurantId: localStorage.getItem("restaurantId"),
      });
      snackbar(res.data.message, "success");
      setData({});
      setIsLoading(false);
      handleClose();
      getAllAddOns();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const createAddOnItemHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.patch(`/api/v1/owner/addon?addOnId=${addonId}`, {
        ...dataItem,
      });
      snackbar(res.data.message, "success");
      setDataItem({});
      setIsLoading(false);
      handleCloseItem();
      getAllAddOns();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const getAllAddOns = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/addon?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setAddOns(res.data.addOn);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllAddOns();
  }, []);

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Add Add On
      </Button>
      {addOns.map((item, index) => (
        <Accordion key={item._id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}a-content`}
            id={`panel${index + 1}a-header`}
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.addOnItems.length === 0 ? (
              <Button
                onClick={() => handleOpenItem(item._id)}
                variant="contained"
              >
                Create AddOn Item
              </Button>
            ) : (
              <>
                {item.addOnItems.map((addOnItem) => (
                  <Box key={addOnItem._id}>
                    <Typography>
                      Title : {addOnItem.title}| Price : {addOnItem.price}
                    </Typography>
                    <Divider />
                  </Box>
                ))}
                <Button
                  onClick={() => handleOpenItem(item._id)}
                  variant="contained"
                >
                  Create AddOn Item
                </Button>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}

      <Modal
        open={createAddOn}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item sx={12} component="form" onSubmit={createAddOnHandler}>
              <TextField
                id="outlined-basic"
                fullWidth
                label="Title"
                name="title"
                value={data.title}
                required
                variant="outlined"
                onChange={handleChange}
              />

              <Button variant="contained" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={createAddOnItem}
        onClose={handleCloseItem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              sx={12}
              component="form"
              onSubmit={createAddOnItemHandler}
            >
              <TextField
                id="outlined-basic"
                fullWidth
                label="Title"
                name="title"
                value={data.title}
                required
                variant="outlined"
                onChange={handleChangeItem}
              />
              <TextField
                id="outlined-basic"
                fullWidth
                type="number"
                label="Price"
                name="price"
                value={data.price}
                required
                variant="outlined"
                onChange={handleChangeItem}
              />

              <Button variant="contained" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default AddOns;
