import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Button,
  Grid,
  Divider,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CardContent from "@mui/material/CardContent";
import { Radio } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Slider from "@mui/material/Slider";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { time } from "../../ExtractTime";
const PickedUpItemDetailsModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:450px)");
  let orderTime = "";
  orderTime = time(props.pickedUpItem.createdAt);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: matches ? "400px" : "300px",
    width: matches ? "50%" : matchesDown ? "80%" : "95%",
    // bgcolor: "whisper.main",
    borderRadius: "5px",
    boxShadow: 24,
    overflow: "auto",
    maxHeight: "90vh",
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openPickedUpItemDetailModal}
        onClose={props.handlClosePickedUpItemDetailModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openPickedUpItemDetailModal}>
          <Box component="form" noValidate sx={style}>
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardContent
                  elevation={10}
                  sx={{
                    backgroundColor: "whisper.main",
                    minWidth: "100%",
                    boxShadow: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      sx={{
                        backgroundColor: "red.light",
                        borderRadius: "5px 0px 5px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white.main",
                          fontSize: "20px",
                          fontWeight: 600,
                          mb: 2,
                        }}
                      >
                        Picked up
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={8}
                      md={9}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          mb: 1,
                          mx: 1,
                        }}
                      >
                        ID : {props.pickedUpItem.orderId}
                      </Typography>
                      <IconButton
                        onClick={props.handlClosePickedUpItemDetailModal}
                        aria-label="close"
                        sx={{ position: "absolute", top: -8, right: -25 }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    mt={0}
                    mb={2}
                    sx={{
                      background:
                        "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", md: "18px" },
                          fontWeight: 600,
                          mb: 2,
                          letterSpacing: "0.42em",
                          color: "red.tertiary",
                        }}
                      >
                        YBITES DELIVERY
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "15px" },
                          fontWeight: 300,
                          mb: 2,

                          color: "black.primary",
                        }}
                      >
                        Placed : {orderTime}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}   
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", sm: "18px" },
                          fontWeight: 600,
                        }}
                      >
                         {props.pickedUpItem.customer.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", sm: "end" },
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        startIcon={<PhoneForwardedIcon />}
                      >
                        Call
                      </Button> */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 300,
                          color: "black.tertiary",
                        }}
                      >
                        {/* 1st order Lokmanya nagar : Thane West (3 kms, 15 min
                        away) */}
                        {`${props.pickedUpItem.customerAddress.completeAddress} 
                        ${props.pickedUpItem.customerAddress.landmark} 
                        ${props.pickedUpItem.customerAddress.city} 
                        ${props.pickedUpItem.customerAddress.state}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  {props.pickedUpItem.orderItems.map((item, index) => (
                    <>
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={8}>
                          <Box sx={{ display: "flex" }}>
                            <img
                              src="/images/orderItemImg.svg"
                              alt="orderItemImg"
                            />
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                            >
                              {item.quantity} X {item.menuItemTitle}
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            {item.customizationItemTitles.map(
                              (customizationItemTitles, index) =>
                                `${customizationItemTitles}, `
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            Add Ons -
                            {item.addOnItemTitles.map(
                              (addOnItemTitles, index) =>
                                ` ${addOnItemTitles}, `
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            {item.optionItemTitles.map(
                              (optionItemTitles, index) =>
                                `${optionItemTitles}, `
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Typography
                            sx={{ fontSize: "15px", fontWeight: 700 }}
                          >
                           ₹{Math.round(item.totalPrice*100)/100}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}

                  <Grid container spacing={2} mt={0}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 500,
                        
          
                          color: "black.tertiary",
                        }}
                      >
                        {/* Instr- We want 2 different parcel of veg and non-veg */}
                        Instr - {props.pickedUpItem.note}
                      </Typography>
                    </Grid>
                
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    mt={0}
                    sx={{ backgroundColor: "purple.tertiary" }}
                  >
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 500,

                          color: "black.tertiary",
                          mt: -1.5,
                        }}
                      >
                        Total Price
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "black.tertiary",
                          mt: -1.5,
                        }}
                      >
                        ₹{Math.round(props.pickedUpItem.price*100)/100}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,

                          color: "black.tertiary",
                        }}
                      >
                        Taxes
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                           {`₹${Math.round(props.pickedUpItem.totalTax*100)/100}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 700,
                          color: "black.primary",
                        }}
                      >
                        Total Bill
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            mx: 1,
                            color: "black.primary",
                            border: "1px solid rgba(0, 153, 80, 0.4)",
                            borderRadius: "2px",
                            letterSpacing: "0.265em",
                            px: 1,
                          }}
                        >
                          PAID
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "black.primary",
                          }}
                        >
                       
                          ₹{Math.round((props.pickedUpItem.totalTax + props.pickedUpItem.totalPrice)*100)/100}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* <Divider sx={{ borderBottomWidth: 3, mt: 2 }} /> */}

                  


                  {props.pickedUpItem.deliveryPartner ? (
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={12}>
                        <Box border={0.2} mt={1} p={2} borderRadius={2}>
                          <FormControlLabel
                            value="1"
                            control={
                              <Box
                                component="img"
                                src="/images/deliveryBoy.svg"
                              ></Box>
                            }
                            label={
                              <div>
                                <Typography
                                  sx={{ fontSize: "15px", fontWeight: 700 }}
                                >
                                  {props.pickedUpItem.deliveryPartner
                                    ? props.pickedUpItem.deliveryPartner.name
                                    : ""}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "15px", fontWeight: 700 }}
                                >
                                  {props.pickedUpItem.deliveryPartner
                                    ? props.pickedUpItem.deliveryPartner.phone
                                    : ""}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: 400,
                                    color: "black.primary",
                                  }}
                                >
                                  On the way
                                </Typography>
                              </div>
                            }
                          />

                          {/* <Button
                                          sx={{ padding: 0.3 }}
                                          variant="outlined"
                                          startIcon={<PhoneForwardedIcon />}
                                        >
                                          Call
                                        </Button> */}
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PickedUpItemDetailsModal;
