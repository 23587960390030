import React, { useContext, useEffect, useState, useRef } from "react";
import { Paper, Box, Grid, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import WelcomeModal from "./WelcomeModal";

const active = {
  width: "auto",

  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
  color: "white",

  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  textTransform: "capitalize",
  fontSize: { xs: "13px", sm: "15px", md: "17px" },
  fontWeight: 500,
  mt: 1,
  textDecoration: "none",
  borderRadius: "5px",
};

const style = {
  width: "auto",

  border: "1px solid rgba(0, 0, 0, 0.26)",
  color: "rgba(0, 0, 0, 0.42)",

  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  textTransform: "capitalize",
  fontSize: { xs: "13px", sm: "15px", md: "17px" },
  fontWeight: 500,
  mt: 1,
  textDecoration: "none",
  borderRadius: "5px",
};

const item = {
  padding: 10,
  borderRadius: "5px",
  backgroundColor: "rgba(255, 255, 255, 0.53)",
  color: "rgba(0, 0, 0, 0.62)",
  borderLeft: "1px solid rgba(0, 0, 0, 0.26)",
  marginLeft: 5,
};

const linkBox = { mx: { xs: 0.5, sm: 1, md: 2 }, mt: 4 };

const OrderTabs = (props) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [prevCount, setPrevCount] = useState(0);
  const [totalCount, setTotalCount] = useState({});
  const { setIsLoading, apiCall, setApiCall } = useContext(AdminDataContext);

  const [count, setCount] = useState(0);

  const audio = new Audio("/audio/ring.mpeg");

  const getAllLiveOrderCount = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/order/count?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );

      setTotalCount(res.data);

      setCount(res.data.totalPending);
      setIsLoading(false);
      setApiCall(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const callForRing = () => {
    if (count > prevCount) {
      audio.play();
    } else {
      audio.pause();
    }
    setPrevCount(count);
  };

  useEffect(() => {
    callForRing();
  }, [count, prevCount]);

  useEffect(() => {
    getAllLiveOrderCount();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getAllLiveOrderCount();
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  // 30000

  if (apiCall === true) {
    // console.log("call api call");
    getAllLiveOrderCount();
  }

  const callApiHandle = () => {
    setApiCall(true);
  };

  const offRing = () => {
    audio.currentTime = 0; // Reset the audio to the beginning
    audio.pause();

    console.log(audio.pause());
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{ px: 5, pb: 4, display: "flex", justifyContent: "start" }}
      >
        <Grid container>
          <Grid item>
            <Box sx={linkBox} onClick={() => callApiHandle()}>
              <NavLink
                to=""
                style={
                  currentUrl === "/" || currentUrl === "/live-orders"
                    ? active
                    : style
                }
              >
                New Order <span style={item}>{totalCount.totalPending}</span>
              </NavLink>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={linkBox} onClick={() => callApiHandle()}>
              <NavLink
                to="live-orders/preparing"
                style={currentUrl === "/live-orders/preparing" ? active : style}
              >
                Preparing
                <span style={item}>{totalCount.totalPreparing}</span>
              </NavLink>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={linkBox} onClick={() => callApiHandle()}>
              <NavLink
                to="live-orders/ready"
                style={currentUrl === "/live-orders/ready" ? active : style}
              >
                Ready
                <span style={item}>{totalCount.totalReady}</span>
              </NavLink>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={linkBox} onClick={() => callApiHandle()}>
              <NavLink
                to="live-orders/picked-up"
                style={currentUrl === "/live-orders/picked-up" ? active : style}
              >
                Picked up
                <span style={item}>{totalCount.totalPickedUp}</span>
              </NavLink>
            </Box>
          </Grid>

          <Grid item onClick={() => callApiHandle()}>
            <Box sx={linkBox}>
              <NavLink
                to="live-orders/past-order"
                style={
                  currentUrl === "/live-orders/past-order" ? active : style
                }
              >
                Delivered
                <span style={item}>{totalCount.totalDelivered}</span>
              </NavLink>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={linkBox} onClick={() => callApiHandle()}>
              <NavLink
                to="live-orders/rejected-order"
                style={
                  currentUrl === "/live-orders/rejected-order" ? active : style
                }
              >
                Rejected
                <span style={item}>{totalCount.totalReject}</span>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default OrderTabs;
