import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { findDate } from "../../ExtractDate";
import { time } from "../../ExtractTime";

const TomorrowReservationCard = (props) => {
  const date = findDate(props.reservation.time);
  const getTime = time(props.reservation.time);
  return (
<>
      <Grid item xs={12} sm={6} md={4} p={3} mt={3}>
        <Card elevation={0}>
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
            }}
          >
            <Grid
              container
              spacing={2}
              mb={2}
              sx={{
                background:
                  "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
              }}
            >
              <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 2,
                    color: "red.tertiary",
                  }}
                >
                  YBITES DELIVERY
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: 1,
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                   {`ID : ${props.reservation.reservationId}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black.primary",
                  }}
                >
             {props.reservation.customer.name}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Number of Guests
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                 {props.reservation.people}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid container pt={1} spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Date
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon sx={{ fontSize: "18px" }} />

                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "black.primary",
                      //   ms:1
                    }}
                  >
                        {date}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />

            <Grid container pt={1} spacing={2}>
              <Grid item xs={5}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Time slot
                </Typography>
              </Grid>
              <Grid item xs={7} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                 {getTime}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default TomorrowReservationCard