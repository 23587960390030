import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
  Button,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomersFeedbacks from "./CustomersFeedbacks";
import Feedback from "./Feedback";

const FeedbackNotifications = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [notifications, setNtifications] = useState(false);

  const handleOpenNotificationModal = () => setNtifications(true);
  const handleCloseNotificationModal = () => setNtifications(false);

  const [customerNotifications, setCustomerNotifications] = useState(false);

  const handleOpenCustomerNotificationModal = () =>
    setCustomerNotifications(true);
  const handleCloseCustomerNotificationModal = () =>
    setCustomerNotifications(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
  };

  const btn = {
    minWidth: "140px",
    height: "50px",
    backgroundColor: "blue.primary",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: 1,
    textDecoration: "none",
    borderRadius: "11px",
    px: 5,
    "&:hover": {
      backgroundColor: "blue.primary",
      color: "white.main",
    },
  };

  return (
    <>
      <Modal
        open={props.notificationModal}
        onClose={props.handleCloseNotificationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent={"space-between"}
              gap={1}
              sx={{
                position: "relative",
                backgroundColor: "grey.midGrey",
                p: 2,
                borderRadius: "16px 16px 0px 0px ",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: "22px",

                  color: "white.main",
                  justifySelf: "start",
                }}
              >
                Feedback
              </Typography>

              <IconButton
                onClick={props.handleCloseNotificationModal}
                aria-label="delete"
                sx={{ color: "white.main" }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FiberManualRecordIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Give Feedback"
                        secondary="please share us your experience"
                      />
                    </ListItem>
                  </List>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleCloseNotificationModal();
                      handleOpenNotificationModal();
                    }}
                    sx={btn}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <FiberManualRecordIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Customer feedback"
                        secondary="See Customer feedback’s"
                      />
                    </ListItem>
                  </List>
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleCloseNotificationModal();
                      handleOpenCustomerNotificationModal();
                    }}
                    sx={btn}
                  >
                    Check
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Feedback
        notifications={notifications}
        handleCloseNotificationModal={handleCloseNotificationModal}
      />

      <CustomersFeedbacks
        customerNotifications={customerNotifications}
        handleCloseCustomerNotificationModal={
          handleCloseCustomerNotificationModal
        }
      />
    </>
  );
};

export default FeedbackNotifications;
