import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useContext } from "react";
import { Container, Paper, Box, Grid, Divider } from "@mui/material";

import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Button } from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { TextField } from "@mui/material";

import axios from "axios";
import { AdminDataContext } from "../../../../useContext/AdminContext";

import moment from "moment";

const weeks = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const OutletTiming = (props) => {
  const [time, setTime] = useState({
    sunday: {
      startTime: { hours: 10, min: 10 },
      endTime: { hours: 12, min: 59 },
    },
    monday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    tuesday: {
      startTime: { hours: 1, min: 12 },
      endTime: { hours: 1, min: 17 },
    },
    wednesday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    thursday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    friday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
    saturday: {
      startTime: { hours: 9, min: 0 },
      endTime: { hours: 12, min: 59 },
    },
  });

  const { snackbar, setIsLoading } = useContext(AdminDataContext);

  const getOutletTiming = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/details?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );

      setTime(res.data.data.timing);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOutletTiming();
  }, []);

 


  const submitTimeHandler = async (e) => {
    try {
      setIsLoading(true);

      const res = await axios.post(
        `/api/v1/owner/restaurant/time/?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        { ...time }
      );

      setIsLoading(false);
      snackbar(res.data.message, "success");
   
    } catch (error) {
      snackbar(error.response.data.message, "error");
      setIsLoading(false);
      console.log(error);
    }
  };
  const createDateValue = (hh, mm) => {
    return moment(`2018-01-01 ${hh}:${mm}`);
  };
  const timeChangeHandler = (newTimeValue, item, slot, otherSlot) => {
     setTime({
      ...time,
      [item]: {
        ...otherSlot,
        [slot]: {
          // ...[slot],
          hours: newTimeValue.hours(),
          min: newTimeValue.minute(),
        },
      },
    });

  };

  return (
    <>
      <div>
        <form
        //  onSubmit={(e) => onTimeSubmit(e)}
         >
          <Grid
            container
            spacing={2}
            mb={1}
            sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  color: "color: rgba(34, 34, 34, 0.79);",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                Days
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "color: rgba(34, 34, 34, 0.79);",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                Start Time
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "color: rgba(34, 34, 34, 0.79);",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                End Time
              </Typography>
            </Grid>
          </Grid>

          {weeks.map((item, index) => (
            <>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  xs={4}
                  sm={3}
                  md={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#FF8A00",
                      fontWeight: 700,
                      fontSize: "20px",
                      textTransform: "uppercase",
                    }}
                  >
                    {item[0].toUpperCase() + item.substring(1)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider border={2} dateAdapter={AdapterMoment}>
                    <MobileTimePicker
                      // label="Select Time"
                      sx={{ width: "15ch" }}
                      minutesStep={1}
                      value={createDateValue(
                        time[item].startTime.hours,
                        time[item].startTime.min
                      )}
                      onChange={(newValue) => {
                        timeChangeHandler(newValue, item, "startTime", {
                          endTime: time[item].endTime,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  {/* <Typography
                    sx={{
                      color: "rgba(13, 30, 55, 0.63)",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    to
                  </Typography> */}
                </Grid>

                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileTimePicker
                      // label="Select Time"

                      sx={{ width: "15ch" }}
                      minutesStep={1}
                      value={createDateValue(
                        time[item].endTime.hours,
                        time[item].endTime.min
                      )}
                      onChange={(newValue) => {
                        timeChangeHandler(newValue, item, "endTime", {
                          startTime: time[item].startTime,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {item[0].toUpperCase() + item.substring(1) === "Saturday" ? (
                <></>
              ) : (
                <Divider sx={{ mt: 1, mb: 1 }} />
              )}
            </>
          ))}

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "end" },
            }}
          >
            <Button
              id="sign-in-button"
              sx={{
                my: 3,
                background:
                  "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
              }}
              variant="contained"
              // type="submit"
              size="large"
              onClick={submitTimeHandler}
            >
              Update Time
            </Button>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default OutletTiming;
