import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import EditIcon from "@mui/icons-material/Edit";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { imgUrl } from "../../../url";
import { AdminDataContext } from "../../../useContext/AdminContext";
const MenuOldH = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [menuItems, setMenuItems] = useState([]);
  const getMenuItems = async () => {
    const res = await axios.get(
      `/api/v1/owner/menuItem?restaurantId=${localStorage.getItem(
        "restaurantId"
      )}`
    );
    setMenuItems(res.data.data);
  };
  useEffect(() => {
    getMenuItems();
  }, []);

  const availableMenuItem = async (id, available) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/menuItem/available?menuItemId=${id}`,
        { available }
      );
      snackbar(res.data.message, "success");
      setIsLoading(false);
      getMenuItems();
    } catch (error) {
      snackbar(error.response.data.message, "success");
      setIsLoading(false);
    }
  };

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Typography sx={{ margin: "10px" }}>Menu Item Category</Typography>
      </Grid>
      {menuItems.map((category, index) => (
        <Grid item sx={{ margin: "10px" }}>
          <Accordion>
            <AccordionSummary
              sx={{
                background:
                  "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                borderRadius: "2px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
            >
              <Typography>{category.title}</Typography>
            </AccordionSummary>
            <Grid item>
              <Typography sx={{ margin: "10px" }}>
                Menu Item Sub Category
              </Typography>
            </Grid>
            <AccordionDetails>
              <Stack spacing={2}>
                {category.menuItems.map((menuItem, index) => (
                  <Accordion
                    sx={{
                      margin: "12px",
                      backgroundColor: "addMenuItemColor.light",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}b-content`}
                      id={`panel${index + 1}b-header`}
                    >
                      <Typography>{menuItem.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item>
                          <Box
                            component="img"
                            width="400px"
                            src={`${imgUrl}/${menuItem.image}`}
                          ></Box>
                        </Grid>
                        <Grid margin={"10px"} item>
                          <Typography>
                            Unit Price: &#8377; {menuItem.unitPrice}
                          </Typography>
                          <Typography>
                            Final Price: &#8377; {menuItem.price}
                          </Typography>
                          <Typography>
                            Discount: {menuItem.discount}%
                          </Typography>

                          <Typography>
                            Preparation Time: {menuItem.preparationTime} min
                          </Typography>
                          <FormGroup>
                            <FormControlLabel
                              onChange={(e) =>
                                availableMenuItem(
                                  menuItem._id,
                                  e.target.checked
                                )
                              }
                              control={<Switch checked={menuItem.available} />}
                              label="available"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Stack>
              {category.menuSubCategories.map((subCategory) => (
                <Grid>
                  <Accordion
                    sx={{
                      margin: "12px",
                      backgroundColor: "addMenuItemColor.light",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}b-content`}
                      id={`panel${index + 1}b-header`}
                    >
                      <Typography>{subCategory.title}</Typography>
                    </AccordionSummary>
                    <Typography sx={{ margin: "10px" }}>Menu Item</Typography>
                    <AccordionDetails>
                      <Grid>
                        {subCategory.menuItems.map((menuItem, index) => (
                          <Accordion
                            sx={{
                              margin: "12px",
                              backgroundColor: "addMenuItemColor.light",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${index + 1}b-content`}
                              id={`panel${index + 1}b-header`}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Typography>{menuItem.title}</Typography>
                                <EditIcon
                                  sx={{ position: "absolute", right: "50px" }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                {menuItem.image ? (
                                  <Grid item>
                                    <Box
                                      component="img"
                                      width="400px"
                                      src={`${imgUrl}/${menuItem.image}`}
                                    ></Box>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                <Grid margin={"10px"} item>
                                  <Typography>
                                    Unit Price: &#8377; {menuItem.unitPrice}
                                  </Typography>
                                  <Typography>
                                    Final Price: &#8377; {menuItem.price}
                                  </Typography>
                                  <Typography>
                                    Discount: {menuItem.discount}%
                                  </Typography>

                                  <Typography>
                                    Preparation Time: {menuItem.preparationTime}{" "}
                                    min
                                  </Typography>
                                  <FormGroup>
                                    <FormControlLabel
                                      onChange={(e) =>
                                        availableMenuItem(
                                          menuItem._id,
                                          e.target.checked
                                        )
                                      }
                                      control={
                                        <Switch checked={menuItem.available} />
                                      }
                                      label="available"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default MenuOldH;
