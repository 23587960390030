import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import ModalForRejectNewOrder from "../modals/ModalForRejectNewOrder";
import ModalForAcceptNewOrder from "../modals/ModalForAcceptNewOrder";
  
const NewOrderCard = (props) => {
  const [openRejectOrderModal, setOpenRejectOrderModal] = useState(false);
  const handlOpenRejectOrderModal = () => setOpenRejectOrderModal(true);
  const handlCloseRejectOrderModal = () => setOpenRejectOrderModal(false);

  const [openAcceptOrderModal, setOpenAcceptOrderModal] = useState(false);
  const handlOpenAcceptOrderModal = () => setOpenAcceptOrderModal(true);
  const handlCloseAcceptOrderModal = () => setOpenAcceptOrderModal(false);

  const time = (str) => {

    const isoTime = str;
  const date = new Date(isoTime);
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(date);
 

    return formattedTime;
  };


  const createdAt = time(props.createdTime);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let orderItemLength = props.orderItems.length;
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return (
      <Box
        sx={{
          color: "blue.main",
          fontSize: "14px",
          display: orderItemLength <= 1 ? "none" : "block",
        }}
      >
        See More  <IconButton color="blue" {...other} />
      </Box>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const rejectBtn = {
    backgroundColor: "bgColor.secondary",
    color: "black.primary",
    width: "100%",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",

    "&:hover": {
      backgroundColor: "bgColor.secondary",
    },
  };
  const acceptBtn = {
    backgroundColor: "yellow.tertiary",
    color: "white.main",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    // border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    width: "100%",

    "&:hover": {
      backgroundColor: "yellow.tertiary",
    },
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} p={3} mt={3}>
        <Card elevation={0}>
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
              minHeight: 350,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sx={{
                  backgroundColor: "blue.secondary",
                  borderRadius: "10px 0px 10px 0px",
                }}
              >
                <Typography
                  sx={{
                    color: "white.main",
                    fontSize: "20px",
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  New Orders
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: 1,
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                  ID : {props.orderId}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={0}
              mb={2}
              sx={{
                background:
                  "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
              }}
            >
              <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 2,
                    color: "red.tertiary",
                  }}
                >
                  YBITES DELIVERY
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400, mb: 1 }}>
                  {createdAt}
                </Typography>
              </Grid>
            </Grid>

            {props.orderItems.map((item, index) =>
              index !== 0 ? (
                <></>
              ) : (
                <>
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <Box sx={{ display: "flex" }}>
                        <img
                          src="/images/orderItemImg.svg"
                          alt="orderItemImg"
                        />
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                        >
                          {item.quantity} X {item.menuItemTitle}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {item.customizationItemTitles.map(
                          (customizationItemTitles, index) =>
                            `${customizationItemTitles}, `
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        Add Ons -
                        {item.addOnItemTitles.map(
                          (addOnItemTitles, index) => ` ${addOnItemTitles}, `
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {item.optionItemTitles.map(
                          (optionItemTitles, index) => `${optionItemTitles}, `
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                        ₹{Math.round(item.totalPrice * 100) / 100}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )
            )}

            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {props.orderItems.map((item, index) =>
                index === 0 ? (
                  <></>
                ) : (
                  <>
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={8}>
                        <Box sx={{ display: "flex" }}>
                          <img
                            src="/images/orderItemImg.svg"
                            alt="orderItemImg"
                          />
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                          >
                            {item.quantity} X {item.menuItemTitle}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          {item.customizationItemTitles.map(
                            (customizationItemTitles, index) =>
                              `${customizationItemTitles}, `
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          Add Ons -
                          {item.addOnItemTitles.map(
                            (addOnItemTitles, index) => ` ${addOnItemTitles}, `
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          {item.optionItemTitles.map(
                            (optionItemTitles, index) => `${optionItemTitles}, `
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", justifyContent: "end" }}
                      >
                        <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                          ₹{Math.round(item.totalPrice * 100) / 100}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )
              )}
            </Collapse>

            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,

                    color: "black.tertiary",
                  }}
                >
                  {/* Instr- We want 2 different parcel of veg and non-veg */}
                  Instr - {props.note}
                </Typography>
              </Grid>
            </Grid>


            <Grid container spacing={2}>
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,

                          color: "black.primary",
                        }}
                      >
                        Total Price
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                       sx={{ fontSize: "14px", fontWeight: 600 }}
                      >
                        {`₹${Math.round(props.totalPrice*100)/100}`}
                      </Typography>
                    </Grid>
                  </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,

                    color: "black.tertiary",
                  }}
                >
                  Taxes
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "black.tertiary",
                  }}
                >
                  {`₹${Math.round(props.totalTax * 100) / 100}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                  Total Bill
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 400,
                      mx: 1,
                      color: "black.primary",
                      border: "1px solid rgba(0, 153, 80, 0.4)",
                      borderRadius: "2px",
                      letterSpacing: "0.265em",
                      px: 1,
                    }}
                  >
                    PAID
                  </Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    ₹
                    {Math.round((props.totalTax + props.totalPrice) * 100) /
                      100}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <Grid
            container
            spacing={2}
            mt={1}
            sx={{ backgroundColor: "transparent" }}
          >
            <Grid item xs={4}>
              <Button
                onClick={handlOpenRejectOrderModal}
                sx={rejectBtn}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={handlOpenAcceptOrderModal}
                sx={acceptBtn}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Accept Order
              </Button>
            </Grid>
          </Grid>
        </Card>  
      </Grid>

      <ModalForRejectNewOrder
        openRejectOrderModal={openRejectOrderModal}
        handlCloseRejectOrderModal={handlCloseRejectOrderModal}
        id={props.id}
        newOrdersDetails={props.newOrdersDetails}
        pages={props.pages}
      />
      <ModalForAcceptNewOrder
        openAcceptOrderModal={openAcceptOrderModal}
        handlCloseAcceptOrderModal={handlCloseAcceptOrderModal}
        orderId={props.orderId}
        createdTime={createdAt}
        totalTax={props.totalTax}
        price={props.price}
        totalPrice={props.totalPrice}
        orderItems={props.orderItems}
        note={props.note}
        customerName={props.customerName}
        customerNumber={props.customerNumber}
        customerAddress={props.customerAddress}
        id={props.id}
        newOrdersDetails={props.newOrdersDetails}
        pages={props.pages}
      />
    </>
  );
};

export default NewOrderCard;
