import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Grid, Typography, Box } from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";

const AvailableTimeSlot = () => {   
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [timeSlot, setTimeSlot] = useState({});
  const getAvailableTimeSlot = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/time?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setTimeSlot(res.data.timing);
    setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

    }
  };


  const timeBox = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.14)",
    borderRadius: "12px",
    p: 2,
  };
  const day = { fontSize: "20px", fontWeight: 600, color: "green.secondary" };
  const timeOpenClose = {
    fontSize: "16px",
    fontWeight: 600,
    color: "green.secondary",
  };
  const time = {
    fontSize: "16px",
    fontWeight: 600,
    color: "green.secondary",
    border: "1px solid rgba(0, 0, 0, 0.44)",
    borderRadius: "5px",
    px: 2,
    py: 0.5,
  };
  const to = { fontSize: "16px", fontWeight: 400, color: "green.secondary" };

  const filterHours = (hours) => {
    if (hours > 9) {
      if (hours <= 12) {
        return hours;
      } else if ((hours - 12) > 9) {
        return `${hours - 12}`;
      }
      else{
        return `0${hours - 12}`;
      }
    } else {
      return `0${hours}`;
    }
  };
  const filterMinuts = (hours, minute) => {
    if (minute > 9) {
      if (hours < 12) {
        return `${minute} AM`;
      } else {
        return `${minute} PM`;
      }
    } else {
      if (hours <= 12) {
        return `0${minute} AM`;
      } else {
        return `0${minute} PM`;
      }
    }
  };

  useEffect(() => {
    getAvailableTimeSlot();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} mt={1}>
          <Typography
            sx={{ fontSize: "22px", fontWeight: 600, color: "green.secondary" }}
          >
            Available time Slot
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Sunday</Typography>
          <Box sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.sunday
                    ? filterHours(timeSlot.sunday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.sunday
                    ? filterMinuts(
                        timeSlot.sunday.startTime.hours,
                        timeSlot.sunday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.sunday
                    ? filterHours(timeSlot.sunday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.sunday
                    ? filterMinuts(
                        timeSlot.sunday.endTime.hours,
                        timeSlot.sunday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Monday</Typography>
          <Box border={1} sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {" "}
                {`${
                  timeSlot.monday
                    ? filterHours(timeSlot.monday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.monday
                    ? filterMinuts(
                        timeSlot.monday.startTime.hours,
                        timeSlot.monday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.monday
                    ? filterHours(timeSlot.monday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.monday
                    ? filterMinuts(
                        timeSlot.monday.endTime.hours,
                        timeSlot.monday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* ---------------------- */}

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Tuesday</Typography>
          <Box sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {" "}
                {`${
                  timeSlot.tuesday
                    ? filterHours(timeSlot.tuesday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.sunday
                    ? filterMinuts(
                        timeSlot.tuesday.startTime.hours,
                        timeSlot.tuesday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.tuesday
                    ? filterHours(timeSlot.tuesday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.tuesday
                    ? filterMinuts(
                        timeSlot.tuesday.endTime.hours,
                        timeSlot.tuesday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Wednesday</Typography>
          <Box border={1} sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.wednesday
                    ? filterHours(timeSlot.wednesday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.wednesday
                    ? filterMinuts(
                        timeSlot.wednesday.startTime.hours,
                        timeSlot.wednesday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.wednesday
                    ? filterHours(timeSlot.wednesday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.wednesday
                    ? filterMinuts(
                        timeSlot.wednesday.endTime.hours,
                        timeSlot.wednesday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* --------------- */}

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Thursday</Typography>
          <Box sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.thursday
                    ? filterHours(timeSlot.thursday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.thursday
                    ? filterMinuts(
                        timeSlot.thursday.startTime.hours,
                        timeSlot.thursday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.thursday
                    ? filterHours(timeSlot.thursday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.thursday
                    ? filterMinuts(
                        timeSlot.thursday.endTime.hours,
                        timeSlot.thursday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Friday</Typography>
          <Box border={1} sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.friday
                    ? filterHours(timeSlot.friday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.friday
                    ? filterMinuts(
                        timeSlot.friday.startTime.hours,
                        timeSlot.friday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.friday
                    ? filterHours(timeSlot.friday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.friday
                    ? filterMinuts(
                        timeSlot.friday.endTime.hours,
                        timeSlot.friday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* ---------------------- */}

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={12} sm={12} md={6} mt={1} p={2}>
          <Typography sx={day}> Saturday</Typography>
          <Box sx={timeBox}>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Open Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.saturday
                    ? filterHours(timeSlot.saturday.startTime.hours)
                    : "00"
                } : ${
                  timeSlot.saturday
                    ? filterMinuts(
                        timeSlot.saturday.startTime.hours,
                        timeSlot.saturday.startTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>{" "}
            <Typography sx={to}> to </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={timeOpenClose}> Close Time</Typography>

              <Typography sx={time}>
                {`${
                  timeSlot.saturday
                    ? filterHours(timeSlot.saturday.endTime.hours)
                    : "00"
                } : ${
                  timeSlot.saturday
                    ? filterMinuts(
                        timeSlot.saturday.endTime.hours,
                        timeSlot.saturday.endTime.min
                      )
                    : "00"
                }`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AvailableTimeSlot;
