export const findDate = (str) => {
  const date = new Date(str);
  let day = date.getDate();
  // const month = date.toLocaleString("en-US", { month: "short" });
  let month = date.getMonth();

  if (day < 10) {
    day = `0${day}`;
  } else {
    day = `${day}`;
  }

  if (month < 10) {
    month = `0${1 + month}`;
  } else {
    month = `${1 + month}`;
  }
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};
