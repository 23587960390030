import { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import { useMediaQuery } from "@mui/material";


const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const dataTotal = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page H",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page I",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page J",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DeliveredOrderBarChart = () => {
  const matches = useMediaQuery("(min-width:1340px)");
const matchesDown = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState(0);
  const [valueinside, setValueInside] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeInside = (event, newValue) => {
    setValueInside(newValue);
  };

  const mainHeading = {
    fontSize: "22px",
    fontWeight: 500,
    color: "green.secondary",
  };

  return (
    <Box
      sx={{
        height: "fit-content",
        // marginX: "32px",
        marginY: "5px",
        // padding: "10px",
      }}
    >
      <Typography variant="h4" sx={mainHeading}>Delivered orders</Typography>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Delivered order" {...a11yProps(0)} />
          <Tab label="Total Sale" {...a11yProps(1)} />
          <Tab label="Average Order Sale" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box sx={{ overflow: "scroll" }}>
        <TabPanel value={value} index={0} >
          <BarChart
          
            width={matches ? 1000 : matchesDown ? 700 : 300}
            height={matchesDown ? 400 : 300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <defs>
              {data.map((color, index) => (
                <linearGradient
                  id={`colorUv${index}`}
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="0"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor="#FD9826" />

                  <stop offset="1" stopColor="#CA6046" />
                </linearGradient>
              ))}
            </defs>
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" radius={50}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
              ))}
            </Bar>
          </BarChart>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BarChart
            width={matches ? 1000 : matchesDown ? 700 : 300}
            height={matchesDown ? 400 : 300}
            data={dataTotal}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <defs>
              {dataTotal.map((color, index) => (
                <linearGradient
                  id={`colorUv${index}`}
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="0"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor="#FD9826" />

                  <stop offset="1" stopColor="#CA6046" />
                </linearGradient>
              ))}
            </defs>
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" radius={50}>
              {dataTotal.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
              ))}
            </Bar>
          </BarChart>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BarChart
            width={matches ? 1000 : matchesDown ? 700 : 300}
            height={matchesDown ? 400 : 300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <defs>
              {data.map((color, index) => (
                <linearGradient
                  id={`colorUv${index}`}
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="0"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor="#FD9826" />

                  <stop offset="1" stopColor="#CA6046" />
                </linearGradient>
              ))}
            </defs>
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" radius={50}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
              ))}
            </Bar>
          </BarChart>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default DeliveredOrderBarChart;
