import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";

import TicketHistoryCard from "./card/TicketHistoryCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import TabHeading from "../complaintsTabs/TabHeading";

const TicketHistory = () => {
  const [ticketHistory, setTicketHistory] = useState([]);

  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const limit = 10;
  const getAllTicketHistory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/ticket?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setTicketHistory(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllTicketHistory();
  }, []);  

  return (
    <>
    <TabHeading  
    getAllTicketHistory = {getAllTicketHistory}
    />
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {ticketHistory.map((ticketHistory, index) => (
          <TicketHistoryCard key={index}  
           ticketHistory={ticketHistory} 
           />
        ))}

        {ticketHistory.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}

                src="/images/zeroTicket.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default TicketHistory;
