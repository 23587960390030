import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import React, { useContext, useState, useEffect } from "react";

import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "80%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const RatingReview = (props) => {
  const { setIsLoading } = useContext(AdminDataContext);

  const [customerFeedbackList, setCustomerFeedbackList] = useState([]);
  const [rating, setRating] = useState([]);

  const getAllCustomerFeedback = async () => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/restaurant/review?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setCustomerFeedbackList(res.data.data.data);
      setRating(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getAllCustomerFeedback();
  }, []);

  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
  };
  const boxStyling = {
    p: 2,
    height: "70vh",
    overflow: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };
  const ratingBox = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    mt: 1,
  };

  return (
    <>
      <Modal
        open={props.customerNotifications}
        onClose={props.handleCloseCustomerNotificationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent={"space-between"}
              gap={1}
              sx={{
                position: "relative",
                backgroundColor: "grey.midGrey",
                p: 2,
                borderRadius: "16px 16px 0px 0px ",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: "22px",

                  color: "white.main",
                  justifySelf: "start",
                }}
              >
                Ratings & Reviews
              </Typography>

              <IconButton
                onClick={props.handleCloseCustomerNotificationModal}
                aria-label="delete"
                sx={{ color: "white.main" }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Box sx={boxStyling}>
              <Box sx={ratingBox}>
                5 <StarIcon color="green" />{" "}
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    Math.trunc((rating.five * 100) / rating.total)
                      ? Math.trunc((rating.five * 100) / rating.total)
                      : 0
                  }
                />{" "}
                {/* {Math.trunc((rating.five * 100) / rating.total) < 10
                  ? `0${Math.trunc((rating.five * 100) / rating.total)}%`
                  : `${Math.trunc((rating.five * 100) / rating.total)}%`} */}
                {Math.trunc((rating.five * 100) / rating.total)
                  ? Math.trunc((rating.five * 100) / rating.total) < 10
                    ? `0${Math.trunc((rating.five * 100) / rating.total)}%`
                    : `${Math.trunc((rating.five * 100) / rating.total)}%`
                  : "00%"}
              </Box>
              <Box sx={ratingBox}>
                4 <StarIcon color="green" />{" "}
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    Math.trunc((rating.four * 100) / rating.total)
                      ? Math.trunc((rating.four * 100) / rating.total)
                      : 0
                  }
                />{" "}
                {Math.trunc((rating.four * 100) / rating.total)
                  ? Math.trunc((rating.four * 100) / rating.total) < 10
                    ? `0${Math.trunc((rating.four * 100) / rating.total)}%`
                    : `${Math.trunc((rating.four * 100) / rating.total)}%`
                  : "00%"}
              </Box>

              <Box sx={ratingBox}>
                3 <StarIcon color="green" />{" "}
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    Math.trunc((rating.three * 100) / rating.total)
                      ? Math.trunc((rating.three * 100) / rating.total)
                      : 0
                  }
                />{" "}
                {Math.trunc((rating.three * 100) / rating.total)
                  ? Math.trunc((rating.three * 100) / rating.total) < 10
                    ? `0${Math.trunc((rating.three * 100) / rating.total)}%`
                    : `${Math.trunc((rating.three * 100) / rating.total)}%`
                  : "00%"}
              </Box>

              <Box sx={ratingBox}>
                2 <StarIcon color="green" />{" "}
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    Math.trunc((rating.two * 100) / rating.total)
                      ? Math.trunc((rating.two * 100) / rating.total)
                      : 0
                  }
                />
                {Math.trunc((rating.two * 100) / rating.total)
                  ? Math.trunc((rating.two * 100) / rating.total) < 10
                    ? `0${Math.trunc((rating.two * 100) / rating.total)}%`
                    : `${Math.trunc((rating.two * 100) / rating.total)}%`
                  : "00%"}
              </Box>

              <Box sx={ratingBox}>
                1 <StarIcon color="green" />{" "}
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    Math.trunc((rating.one * 100) / rating.total)
                      ? Math.trunc((rating.one * 100) / rating.total)
                      : 0
                  }
                />{" "}
                {Math.trunc((rating.one * 100) / rating.total)
                  ? Math.trunc((rating.one * 100) / rating.total) < 10
                    ? `0${Math.trunc((rating.one * 100) / rating.total)}%`
                    : `${Math.trunc((rating.one * 100) / rating.total)}%`
                  : "00%"}
              </Box>
              <Typography sx={{ mt: 2, fontSize: "18px", fontWeight: 600 }}>
                {" "}
                Reviews{" "}
              </Typography>
              <Grid container spacing={2}>
                {customerFeedbackList.map((feedback, index) => (
                  <Grid item xs={12} key={index}>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemIcon sx={{ marginLeft: -3 }}>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "18px",
                            marginLeft: -3,
                            fontWeight: 500,
                          }}
                          primary={feedback.customer.name}
                        />
                      </ListItem>
                    </List>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                      {feedback.description}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RatingReview;
