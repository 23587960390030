import React, { useContext, useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

import { Button, Grid, Modal, TextField, IconButton, useMediaQuery, } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { Box } from "@mui/system";

const CreateVariationItem = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const [dataItem, setDataItem] = useState({});

  const handleChangeItem = (event) => {
    setDataItem({ ...dataItem, [event.target.name]: event.target.value });
  };

  const createAddOnItemHandler = async (e) => {
    let res = {};
    e.preventDefault();
    try {
      setIsLoading(true);

      if (props.heading === "Sub Category Title") {
        res = await axios.post(
          `/api/v1/owner/sub-category?menuCategoryId=${props.id}`,
          {
            ...dataItem,
          }
        );
        props.getAllMenuCategory();
      } else {
        res = await axios.patch(`/api/v1/owner/addon?addOnId=${props.id}`, {
          ...dataItem,
        });
        props.getAllAddOns();
      }

      snackbar(res.data.message, "success");
      setDataItem({});
      setIsLoading(false);
      props.handleCloseItemModal();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    minWidth: "140px",   
    paddingY: 1,
    paddingX: 2,

    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",

    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
  };

  const textFieldStyle = {
    width: { xs: "100%" },
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  return (
    <>
      <Modal
        open={props.openItemModal}
        onClose={props.handleCloseItemModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "rgba(13, 30, 55, 0.67)",
                  justifySelf: "start",
                }}
              >
                {props.title}
              </Typography>

              <IconButton
                onClick={props.handleCloseItemModal}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Grid>

            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={createAddOnItemHandler}
            >
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  {props.heading === "Sub Category Title"
                    ? "Sub Category Title"
                    : "Name*"}
                </Typography>

                <TextField
                  size="small"
                  name="title"
                  defaultValue={props.subCategoryTitle}
                  value={dataItem.title}
                  required
                  onChange={handleChangeItem}
                  placeholder="Title"
                  sx={textFieldStyle}
                />
              </Grid>

              {props.heading === "Sub Category Title" ||
              props.heading === "Edit Sub Category" ? (
                ""
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 2,
                      display:
                        props.heading === "Sub Category Title"
                          ? "none"
                          : "block",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      fontSize="16px"
                      sx={{ color: "grey.main" }}
                    >
                      Price*
                    </Typography>

                    <TextField
                      size="small"
                      type="number"
                      name="price"
                      value={dataItem.price}
                      required
                      onChange={handleChangeItem}
                      placeholder="Price"
                      sx={textFieldStyle}
                    />
                  </Grid>
                </>
              )}

              <Grid container spacing={2} px={3} mt={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",

                    justifyContent: { xs: "center" },
                    alignItems: "end",
                  }}
                >
                  {props.heading === "Edit Sub Category" ? (
                    <Button variant="outlined" type="submit" sx={active}>
                      Update
                    </Button>
                  ) : (
                    <Button variant="outlined" type="submit" sx={active}>
                      Add
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CreateVariationItem;
