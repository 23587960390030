import React, { createContext, useState } from "react";
export const AdminDataContext = createContext({
  updateAdminData: () => {},
});
const AdminContext = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ownerData, setOwnerData] = useState({});
  const [managerData, setManagerData] = useState({});
  const [userType, setUserType] = useState("");

  // snakbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  //Live order count api
   const [apiCall, setApiCall] = useState(false);

  const updateOwnerData = (data) => {
    setOwnerData(data);
  };

  const snackbar = (messageData, severityData) => {
    setOpenSnackbar(true);
    setMessage(messageData);
    setSeverity(severityData);
  };

  return (
    <AdminDataContext.Provider
      value={{
        updateOwnerData,
        ownerData,
        managerData,
        setManagerData,
        userType,
        setUserType,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
        snackbar,
        openSnackbar,
        setOpenSnackbar,
        message,
        severity,
        apiCall,
        setApiCall
      }}
    >
      {props.children}
    </AdminDataContext.Provider>
  );
};

export default AdminContext;
