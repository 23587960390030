import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Paper, Button } from "@mui/material";

const Heading = () => {
  const location = useLocation();
  const currentTabHeading = location.pathname;
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "whisper.main",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid rgba(0, 0, 0, 0.14)",
          p: 2,
          borderRadius: "12px",
          mt: 2,
        }}
      >
        <Button
          //   onClick={handleChange}

          variant="outlined"
          sx={{
            textTransform: "capitalize",
            color: "green.secondary",
            fontWeight: 600,
            fontSize: "20px",
            border: "none",
            "&:hover": { border: "none" },
          }}
        >
          {currentTabHeading === "/legal"
            ? "Privacy Policy"
            : currentTabHeading === "/legal/terms-&-condition"
            ? "Terms and Conditions"
            : ""}
        </Button>
      </Paper>
    </>
  );
};

export default Heading;
