import React, { useContext, useEffect, useState } from "react";
import { Grid, Container, Box } from "@mui/material";

import Accordion from "@mui/material/Accordion";


import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { questions } from "./FaqQuestions";

const FAQ = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  const headingTextStyle = {
    fontWeight: 600,
    fontSize: "18px",
    color: "white.main",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };



  return (
    <>
      <Container fixed sx={{ my: 1 }}>
        <Grid container spacing={2} mt={3}>
          {questions.map((data, index) => (
            <Grid item xs={12} sx={{ marginX: "10px" }}>
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleAccordionChange(`panel${index}`)}
              >
                <AccordionSummary
                  sx={{
                    background:
                      "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    borderRadius: "2px",
                  }}
                  expandIcon={<ExpandMoreIcon color="white" />}
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                >
                  <Typography sx={headingTextStyle}>
                    {data.questions}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12}>
                      <Typography>{data.answer}</Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}

          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} sm={12} md={5}></Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FAQ;
