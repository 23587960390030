import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Grid, Box, Typography } from "@mui/material";
import TomorrowReservationCard from "./card/TomorrowReservationCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";

const UpcomingReservations = () => {
  const [tomorrowReservation, setTomorrowReservation] = useState([]);

  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toLocaleDateString('en-CA'); 


  const getAllTomorrowReservation = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/reservation/date?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&page=1&limit=10`, {"date": tomorrowDate}
      );
     
      setTomorrowReservation(res.data.data)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

    }
  };

useEffect(() => {
  getAllTomorrowReservation();
}, [])


  return (
    <>
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Grid item xs={12} mt={1}>
        <Typography
          sx={{ fontSize: "20px", fontWeight: 600, color: "green.secondary" }}
        >
          Today 1 December
        </Typography>
      </Grid>

      {tomorrowReservation.map((reservation, index) => (
        <TomorrowReservationCard 
        key={index} 
        reservation = {reservation}
        getAllTomorrowReservation = {getAllTomorrowReservation}

        />
      ))}

      {tomorrowReservation.length < 1 ? (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              marginTop={7}
              sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}
              src="/images/zeroTomorrowReservation.svg"
            ></Box>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  </>

  )
}

export default UpcomingReservations