import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";
import { Box } from "@mui/system";

const Address = (props) => {
  const { snackbar, setIsLoading, setIsAuthenticated } =
    useContext(AdminDataContext);
  const [coordinates, setCoordinates] = React.useState({
    latitude: 0,
    longitude: 0,
  });
  const [address, setAddress] = React.useState({
    completeAddress: "",
    pinCode: 0,
    landmark: "",
    city: "",
    state: "",
  });
  const handleSubmitAddress = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/restaurant/address/?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        { ...address, pinCode: address.pinCode * 1, ...coordinates }
      );

      setIsLoading(false);
      props.getAboutOutLet();
      props.handleEditAddress();
      snackbar(res.data.message, "success");
    } catch (error) {
      setIsLoading(false);
      snackbar(error.data.message, "error");
      console.log(error);
    }
  };
  useEffect(() => {
    getAddress();
  }, []);
  const getAddress = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/address/?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setAddress(res.data.data);
      setCoordinates({
        longitude: res.data.data.location.coordinates[0],
        latitude: res.data.data.location.coordinates[1],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onInputChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  return (
    <div style={{ padding: "32px" }}>
      {" "}
      <form onSubmit={(e) => handleSubmitAddress(e)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Complete Address*
            </Typography>
            <TextField
              multiline={true}
              id="completeAddress"
              name="completeAddress"
              value={address.completeAddress}
              // defaultValue={restaurantDetails.address.completeAddress}

              onChange={(e) => onInputChange(e)}
              size="small"
              required
              sx={{
                width: { xs: "100%", sm: "90%" },
                borderRadius: "10px",
                // background: "rgba(255, 255, 255, 0.31)",
                color: "rgba(255, 255, 255, 0.96)",
                border: "1px solid black",
                "& fieldset": {
                  border: "none",
                  color: "rgba(255, 255, 255, 0.96);",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              Pin Code*
            </Typography>

            <TextField
              id="pinCode"
              name="pinCode"
              value={address.pinCode}
              // defaultValue={restaurantDetails.address.pinCode}
              onChange={(e) => onInputChange(e)}
              size="small"
              required
              sx={{
                width: { xs: "100%", sm: "80%" },

                borderRadius: "10px",
                // background: "rgba(255, 255, 255, 0.31)",
                color: "rgba(255, 255, 255, 0.96)",
                border: "1px solid black",
                "& fieldset": {
                  border: "none",
                  color: "rgba(255, 255, 255, 0.96);",
                },
              }}
            />

            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main", mt: 2 }}
            >
              Landmark*
            </Typography>

            <TextField
              id="landmark"
              name="landmark"
              value={address.landmark}
              onChange={(e) => onInputChange(e)}
              size="small"
              required
              sx={{
                width: { xs: "100%", sm: "80%" },
                borderRadius: "10px",
                // background: "rgba(255, 255, 255, 0.31)",
                color: "rgba(255, 255, 255, 0.96)",
                border: "1px solid black",
                "& fieldset": {
                  border: "none",
                  color: "rgba(255, 255, 255, 0.96);",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main" }}
            >
              City*
            </Typography>

            <TextField
              id="city"
              name="city"
              value={address.city}
              // defaultValue={restaurantDetails.address.city}
              onChange={(e) => onInputChange(e)}
              size="small"
              // sx={{ mt: 2 }}

              required
              sx={{
                width: { xs: "100%", sm: "80%" },
                borderRadius: "10px",
                // background: "rgba(255, 255, 255, 0.31)",
                color: "rgba(255, 255, 255, 0.96)",
                border: "1px solid black",
                "& fieldset": {
                  border: "none",
                  color: "rgba(255, 255, 255, 0.96);",
                },
              }}
            />

            <Typography
              variant="h6"
              fontWeight={500}
              fontSize="16px"
              sx={{ color: "grey.main", mt: 2 }}
            >
              State*
            </Typography>
            <TextField
              id="state"
              name="state"
              value={address.state}
              // defaultValue={restaurantDetails.address.state}

              onChange={(e) => onInputChange(e)}
              size="small"
              required
              sx={{
                width: { xs: "100%", sm: "80%" },
                borderRadius: "10px",
                // background: "rgba(255, 255, 255, 0.31)",
                color: "rgba(255, 255, 255, 0.96)",
                border: "1px solid black",
                "& fieldset": {
                  border: "none",
                  color: "rgba(255, 255, 255, 0.96);",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Latitude*
                </Typography>

                <TextField
                  id="latitude"
                  name="latitude"
                  value={coordinates.latitude}
                  // defaultValue={restaurantDetails.address.city}
                  onChange={(e) =>
                    setCoordinates({
                      ...coordinates,
                      latitude: e.target.value,
                    })
                  }
                  size="small"
                  // sx={{ mt: 2 }}

                  required
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    borderRadius: "10px",
                    // background: "rgba(255, 255, 255, 0.31)",
                    color: "rgba(255, 255, 255, 0.96)",
                    border: "1px solid black",
                    "& fieldset": {
                      border: "none",
                      color: "rgba(255, 255, 255, 0.96);",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Longitude*
                </Typography>
                <TextField
                  id="longitude"
                  name="longitude"
                  value={coordinates.longitude}
                  // defaultValue={restaurantDetails.address.city}
                  onChange={(e) =>
                    setCoordinates({
                      ...coordinates,
                      longitude: e.target.value,
                    })
                  }
                  size="small"
                  // sx={{ mt: 2 }}
                  required
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    borderRadius: "10px",
                    // background: "rgba(255, 255, 255, 0.31)",
                    color: "rgba(255, 255, 255, 0.96)",
                    border: "1px solid black",
                    "& fieldset": {
                      border: "none",
                      color: "rgba(255, 255, 255, 0.96);",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={props.handleEditAddress}
              sx={{
                mt: 4,
                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                color: "white.main",
              mx:2
              }}
            >
              back
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mt: 4,
                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                color: "white.main",
                mx:2
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </div>
  );
};

export default Address;
