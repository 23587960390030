import {
  Grid,
  Box,
  Container,
  Stack,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddMenuItemVariations from "../OldMenuItems/modal/AddMenuVariationsModal";
import CreateVariationItem from "../OldMenuItems/modal/CreateMenuVariationModal";
import EditAddOnCategory from "./modal/EditAddOnCategory";
import EditAddOnSubCategory from "./modal/EditAddOnSubCategory";

const AddOn = () => {
  const { setIsLoading } = useContext(AdminDataContext);

const navigate = useNavigate();

  const [addOns, setAddOns] = useState([]);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [id, setId] = useState("");
  const [itemTitle, setItemTitle] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Edit AddOn
  const [openEditAddOn, setOpenEditAddOn] = useState(false);
  const [addOnTitle, setAddOnTitle] = useState("");
  const [addonId, setAddOnId] = useState("");
  const handleOpenEditAddOnModal = (id, title) => {
    setOpenEditAddOn(true);
    setAddOnTitle(title);
    setAddOnId(id);
    // console.log(addonId);
  };
  const handleCloseEditAddOnModal = () => setOpenEditAddOn(false);

  // Edit AddOn Items
  const [openEditAddOnItem, setOpenEditAddOnItem] = useState(false);
  const [addOnItem, setAddOnItem] = useState({});

  const handleOpenEditAddOnItemModal = (data) => {
    setOpenEditAddOnItem(true);
    setAddOnItem(data);
  };
  const handleCloseEditAddOnItemModal = () => setOpenEditAddOnItem(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenItem = (id, title) => {
    setOpenItemModal(true);
    setId(id);
    setItemTitle(title);
  };
  const handleCloseItemModal = () => setOpenItemModal(false);
  const getAllAddOns = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/addon?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setAddOns(res.data.addOn);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    minWidth: "140px",
    paddingY: 1,
    paddingX: 2,

    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
  };

  const styleBox = {
    // transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",
    //    boxShadow: 24,
    py: 2,
  };

  const headingTextStyle = {
    fontWeight: 600,
    fontSize: "18px",
    color: "white.main",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };
  const subCategoryHeading = {
    fontWeight: 500,
    fontSize: "16px",
    color: "black.main",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };

  useEffect(() => {
    getAllAddOns();
  }, []);

  return (
    <>
      {/* <MenuTabs current="addOn" /> */}

      <Container fixed sx={{ my: 1 }}>
        <Box component="form" noValidate sx={styleBox}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <FormControlLabel
                control={<ArrowBackIosNewIcon onClick={() => navigate(-1)} />}
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "green.secondary",
                      justifySelf: "start",
                    }}
                  >
                    Create add-ons
                  </Typography>
                }
              />

              <Button
                onClick={handleOpenModal}
                sx={active}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Create
              </Button>
            </Stack>

            <Grid
              container
              sx={{
                backgroundColor: "#FF9415",
                py: 2,
                borderRadius: "11px",
                px: 1,
                mt: 5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "center", md: "start" },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    // fontSize: "18px",
                    fontSize: "16px",
                    color: "white.main",
                  }}
                >
                  <Box component={"span"}
                  sx={{
                    background: "rgba(255, 255, 255, 0.23)",
                    padding: "10px",
                    borderRadius: "5px",
                    display:{xs:'none', sm:"inline-block"}
                  }}
                  >
                    {addOns.length}
                  </Box>{" "}
                  Add-ons categories available
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "center", md: "end" },
                  mt: { xs: 3, sm: 0, md: 0 },
                }}
              >
                {/* search text fields */}
                {/* <TextField
                  name="bankName"
                  size="small"
                  type="text"
                  required
                  placeholder="Search Add-ons"
                  InputProps={{
                    style: {
                      background: "rgba( 255, 255, 255, 0.45 )",
                      backdropFilter: "blur( 0px )",
                    },

                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={textFieldStyle}
                /> */}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center", pb: 2 }}
            >
              {addOns.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleAccordionChange(`panel${index + 1}`)}
                    key={item._id}
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid rgba(0, 0, 0, 0.34)",
                      borderRadius: "21px",
                      width: "100%",
                    }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="white" />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      sx={{
                        background:
                          "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                        borderRadius: "2px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleOpenEditAddOnModal(item._id, item.title)
                        }
                        variant="outlined"
                        boxShadow={0}
                        sx={headingTextStyle}
                        endIcon={<EditIcon />}
                      >
                        {item.title}
                      </Button>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.addOnItems.length === 0 ? (
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Grid
                              item
                              xs={10}
                              sm={7}
                              md={6}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "70%",
                                  height: "70%",
                                  borderRadius: "20px",
                                  mt: 3,
                                }}
                                component="img"
                                alt="addOns"
                                src="/images/zeroAddOnItems.svg"
                              ></Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} mt={3}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",

                                justifyContent: { xs: "center", md: "end" },
                                alignItems: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleOpenItem(item._id, item.title)
                                }
                                sx={active}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {item.addOnItems.map((addOnItem) => (
                            <Box
                              key={addOnItem._id}
                              pt={2}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  handleOpenEditAddOnItemModal(addOnItem)
                                }
                                variant="outlined"
                                boxShadow={0}
                                sx={subCategoryHeading}
                                endIcon={<EditIcon />}
                              >
                                {addOnItem.title}
                              </Button>

                              <Typography sx={{ me: 5 }}>
                                {"₹" + addOnItem.price}
                              </Typography>
                            </Box>
                          ))}

                          <Grid container spacing={2} mt={3}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",

                                justifyContent: { xs: "center", md: "end" },
                                alignItems: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleOpenItem(item._id, item.title)
                                }
                                sx={active}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: addOns.length === 0 ? "block" : "none" }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "27px",
                  color: "jackoBean.primary",

                  mt: 1,
                }}
              >
                Note: The above is a list of approved add-on options for your
                outlet. If you want to add more add-on option you can create
                them from add-on.
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  xs={10}
                  sm={7}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "70%",
                      borderRadius: "20px",
                      mt: 3,
                    }}
                    component="img"
                    alt="addOns"
                    src="/images/addOnsImg.svg"
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <AddMenuItemVariations
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          getAllAddOns={getAllAddOns}
          heading="Create Add-on"
        />
        <CreateVariationItem
          openItemModal={openItemModal}
          handleCloseItemModal={handleCloseItemModal}
          getAllAddOns={getAllAddOns}
          id={id}
          title={itemTitle}
          heading="Create Add-on"
        />

        <EditAddOnCategory
          handleCloseEditAddOnModal={handleCloseEditAddOnModal}
          openEditAddOn={openEditAddOn}
          addonId={addonId}
          addOnTitle={addOnTitle}
          getAllAddOns={getAllAddOns}
        />
        <EditAddOnSubCategory
          handleCloseEditAddOnItemModal={handleCloseEditAddOnItemModal}
          openEditAddOnItem={openEditAddOnItem}
          addOnItem={addOnItem}
          setAddOnItem={setAddOnItem}
          getAllAddOns={getAllAddOns}
        />
      </Container>
    </>
  );
};

export default AddOn;
