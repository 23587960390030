import React, { useEffect, useContext } from "react";
import { Container, Paper, Box, Grid, Divider } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Button } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { useNavigate, Route, Routes } from "react-router-dom";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { TextField } from "@mui/material";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";

import { styled } from "@mui/material/styles";
import axios from "axios";
import { AdminDataContext } from "../../useContext/AdminContext";
import { imgUrl } from "../../url";
import SimpleMap from "../../reusableComponent/SimpleMap";
import moment from "moment";
import PendingRestScreen from "./PendingRestScreen";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  backgroundColor: "#F5F5F5",
  borderRadius: "5px",
});

const StyledForm = styled("form")({
  width: "100%",
});

const StyledInput = styled(TextField)({
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "100%",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const weeks = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const CreateRestaurantDetails = (props) => {
  const navigate = useNavigate();
  // harsh state
  const [images, setImages] = useState([null, null, null]);
  const [coordinates, setCoordinates] = React.useState({
    latitude: 0,
    longitude: 0,
  });
  const logout = async () => {
    setIsLoading(true);
    await axios.get("/api/v1/restaurant/logout");
    setIsLoading(false);
    setIsAuthenticated(false);
    localStorage.removeItem("restaurantId");

    return navigate("/login");
  };

  // end
  const [value, setTabValue] = React.useState(0);
  const { snackbar, setIsLoading, setIsAuthenticated } =
    useContext(AdminDataContext);

  const [restaurantDetails, setRestaurantDetails] = useState(null);

  const [bankDetails, setBankDetails] = useState();
  const [address, setAddress] = useState();
  const [isError, setIsError] = useState(false);
  const [gstCertificateFile, setGstFile] = useState("");

  const [fssaiCertificateFile, setFaasiFile] = useState("");
  const [fssaiCertificate, setFssaiCertificate] = useState();

  const [gstCertificate, setGstCertificate] = useState();
  const [pendingScreen, setPendingScreen] = useState(false);
  const [clickBtn, setClickBtn] = useState({
    btn1: false,
    btn2: false,
  });

  const [progress, setProgress] = React.useState(0);

  const [expanded, setExpanded] = React.useState(false);

  const [time, setTime] = useState(null);
  const handleSuccess = (i) => {
    setProgress(progress + i);
  };
  const getRestaurantDetails = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/details?restaurantId=${props.id}`
      );
      setRestaurantDetails(res.data.data);
      const check = () => {
        if (!(res.data.data.images && res.data.data.images.length === 3)) {
          return 0;
        } else if (!res.data.data.address) {
          return 1;
        } else if (!res.data.data.fssaiCertificate) {
          return 2;
        } else if (!res.data.data.gstCertificate) {
          return 3;
        } else if (!res.data.data.bankDetail) {
          return 4;
        } else {
          return 5;
        }
      };
      setTabValue(check());

      setPendingScreen(res.data.data.timing ? true : false);
      setBankDetails(
        res.data.data.bankDetail
          ? res.data.data.bankDetail
          : {
              bankName: "",
              accountHolderName: "",
              accountNumber: null,
              ifscCode: "",
              branch: "",
            }
      );
      setAddress(
        res.data.data.address
          ? { ...res.data.data.address }
          : {
              addressType: "",
              city: "",
              state: "",
              completeAddress: "",
              landmark: "",
              pinCode: null,
            }
      );
      setTime(
        res.data.data.timing
          ? res.data.data.timing
          : {
              sunday: {
                startTime: { hours: 10, min: 10 },
                endTime: { hours: 12, min: 59 },
              },
              monday: {
                startTime: { hours: 9, min: 0 },
                endTime: { hours: 12, min: 59 },
              },
              tuesday: {
                startTime: { hours: 1, min: 12 },
                endTime: { hours: 1, min: 17 },
              },
              wednesday: {
                startTime: { hours: 9, min: 0 },
                endTime: { hours: 12, min: 59 },
              },
              thursday: {
                startTime: { hours: 9, min: 0 },
                endTime: { hours: 12, min: 59 },
              },
              friday: {
                startTime: { hours: 9, min: 0 },
                endTime: { hours: 12, min: 59 },
              },
              saturday: {
                startTime: { hours: 9, min: 0 },
                endTime: { hours: 12, min: 59 },
              },
            }
      );

      setFssaiCertificate(
        res.data.data.fssaiCertificate
          ? {
              certificateNo: res.data.data.fssaiCertificate.certificateNo,
            }
          : {
              certificateNo: "",
            }
      );
      setGstCertificate(
        res.data.data.gstCertificate
          ? {
              gstNumber: res.data.data.gstCertificate.gstNumber,
            }
          : {
              gstNumber: null,
            }
      );

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onBankDetailChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };
  const submitTimeHandler = async (e) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/restaurant/time/?restaurantId=${props.id}`,
        { ...time }
      );

      setIsLoading(false);
      snackbar(res.data.message, "success");
      // console.log(res);
      getRestaurantDetails();
    } catch (error) {
      snackbar(error.response.data.message, "error");

      console.log(error);
    }
  };
  const handleBankDetailSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const accountNumberPattern = /^\d{9,18}$/;
      if (accountNumberPattern.test(bankDetails.accountNumber) && bankDetails.accountNumber > 0) {
        const res = await axios.post(
          `/api/v1/owner/restaurant/bank/?restaurantId=${props.id}`,
          { ...bankDetails, accountNumber: bankDetails.accountNumber * 1 }
        );
        snackbar(res.data.message, "success");
        // snackbar("valid account number", "success");

      } else {
        snackbar("Invalid account number", "error");
        setIsLoading(false);
        return;
      }

      handleSuccess(clickBtn.btn1 ? "" : 20);

      setClickBtn({ ...clickBtn, btn1: true });
      setIsLoading(false);

      getRestaurantDetails();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const createDateValue = (hh, mm) => {
    return moment(`2018-01-01 ${hh}:${mm}`);
  };
  const timeChangeHandler = (newTimeValue, item, slot, otherSlot) => {
    // console.log({ hours: newTimeValue.hours(), min: newTimeValue.minute() });
    setTime({
      ...time,
      [item]: {
        ...otherSlot,
        [slot]: {
          // ...[slot],
          hours: newTimeValue.hours(),
          min: newTimeValue.minute(),
        },
      },
    });
    // console.log({
    //   ...time,
    //   [item]: {
    //     ...otherSlot,
    //     [slot]: {
    //       // ...[slot],
    //       hours: newTimeValue.hours(),
    //       min: newTimeValue.minute(),
    //     },
    //   },
    // });
  };

  const onInputChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmitAddress = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const pinCodeRegex = /^[0-9]{6}$/;
      if (!pinCodeRegex.test(address.pinCode)) {
        setIsError(!pinCodeRegex.test(address.pinCode));
        snackbar("Invalid pin code ", "error");
      } else {
        const res = await axios.post(
          `/api/v1/owner/restaurant/address/?restaurantId=${props.id}`,
          { ...address, pinCode: address.pinCode * 1, ...coordinates }
        );
        snackbar(res.data.message, "success");
        setCoordinates({});
        setIsError(!pinCodeRegex.test(address.pinCode));

        getRestaurantDetails();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
      console.log(error);
    }
  };

  const imageUploadHandler = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      let formData = new FormData();
      for (let index = 0; index < images.length; index++) {
        const element = images[index];

        formData.append("images", element);
      }
      const res = await axios.patch(
        `/api/v1/owner/create/restaurant/?restaurantId=${props.id}`,
        formData
      );
      handleSuccess(20);
      setIsLoading(false);
      snackbar(res.data.message, "success");
      setIsLoading(false);
      getRestaurantDetails();
    } catch (error) {
      if (error.response.status === 413) {
        snackbar("Image is too large", "error");
      } else {
        snackbar(error.response.data.message, "error");
      }

      setIsLoading(false);
      console.log(error);
    }
  };

  const uploadFssaiDocument = (e) => {
    setFaasiFile(e.target.files[0]);
  };
  const uploadGstDocument = (e) => {
    setGstFile(e.target.files[0]);
  };
  const onGstInputChange = (e) => {
    setGstCertificate({ ...gstCertificate, [e.target.name]: e.target.value });
  };
  const onFssaiInputChange = (e) => {
    setFssaiCertificate({
      ...fssaiCertificate,
      [e.target.name]: e.target.value,
    });
  };

  const handleFssaiSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let formData = new FormData();
      formData.append("document", fssaiCertificateFile);
      const res = await axios.post(
        `/api/v1/owner/restaurant/fssai?restaurantId=${props.id}`,
        fssaiCertificate
      );
      // setDocumentFileId(res.data.restaurant.documents);
      await axios.patch(
        `/api/v1/owner/restaurant/document?documentId=${res.data.document._id}`,
        formData
      );
      setIsLoading(false);
      snackbar(res.data.message, "success");
      getRestaurantDetails();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");
    }
  };
  const handleGstSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let formData = new FormData();
      formData.append("document", gstCertificateFile);
      const res = await axios.post(
        `/api/v1/owner/restaurant/gst/?restaurantId=${props.id}`,
        gstCertificate
      );
      await axios.patch(
        `/api/v1/owner/restaurant/document?documentId=${res.data.document._id}`,
        formData
      );
      setIsLoading(false);
      snackbar(res.data.message, "success");
      getRestaurantDetails();
    } catch (error) {
      snackbar(error.response.data.message, "error");
    }
  };

  const onTimeSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getRestaurantDetails();
  }, []);

  const activeCreateBtn = {
    width: "auto",
    backgroundColor: `white.main`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "yellow.secondary",
    paddingY: 0.5,
    paddingX: 2,
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 700,
    // minWidth: { xs: "100px", sm: "140px" },
    textDecoration: "none",
    borderRadius: "6px",
    position: "absolute",
    right: 20,
    "&:hover": {
      backgroundColor: `white.main`,
    },
  };

  const addressTxt = {
    width: { xs: "100%", sm: "80%" },

    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  }
  const inputDocuments = {
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  }

  return (
    <>
      {restaurantDetails ? (
        <Container
          fixed
          sx={{
            alignContent: "center",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              width: "100%",
              bgcolor: "bgColor.main",
              borderRadius: "20px",
              minHeight: "80vh",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              my: 2,
            }}
            elevation={10}
          >
            {pendingScreen ? (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <PendingRestScreen />
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "60px",
                    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    color: "white.main",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/createResLogoImg.svg"
                    alt="Preview"
                    width="200"
                    height="60"
                    style={{
                      filter: "drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.45))",
                    }}
                  />

                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "white.main",
                      fontWeight: 600,
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    Create Restaurant
                  </Typography>

                  <Button
                    sx={activeCreateBtn}
                    variant="contained"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </Box>

                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "black.secondary",
                    fontWeight: 600,
                    textAlign: "center",
                    mt: 1,
                    display: { sm: "none" },
                  }}
                >
                  Create Restaurant
                </Typography>

                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    overflow: "auto",
                    mt: 2,
                  }}
                >
                  <Tabs
                    value={value}
                    // onChange={handleChange}

                    aria-label="basic tabs example"
                  >
                    <Tab label="Image" {...a11yProps(0)} />
                    <Tab label="Address" {...a11yProps(1)} />
                    <Tab label="FSSAI LICENSE" {...a11yProps(3)} />
                    <Tab label="GST Certificate" {...a11yProps(4)} />
                    <Tab label="Bank Details" {...a11yProps(5)} />
                    <Tab label="Timing" {...a11yProps(6)} />
                  </Tabs>

            
                </Box>
                <TabPanel
                  value={value}
                  index={0}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid red",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    spacing={2}
                  >
                    <Grid container spacing={4}>
                      {images.map((item, index) => (
                        <Grid item md={4} xs={12} key={index}>
                          <DragsAndDrop
                            heading="Upload Image"
                            inputName="Image"
                            // sx={{border:'none'}}
                            imgKey={
                              restaurantDetails.images
                                ? restaurantDetails.images[index]
                                : null
                            }
                            aspect={2 / 1}
                            uploadFile={(x) => {
                              const imagesData = images.map((prv, i) => {
                                if (i === index) {
                                  // Increment the clicked counter
                                  return x;
                                } else {
                                  // The rest haven't changed
                                  return prv;
                                }
                              });
                              setImages(imagesData);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>

                    <Button
                      sx={{ mt: { xs: 2, sm: 2, md: 5 } }}
                      variant="contained"
                      onClick={imageUploadHandler}
                      disabled={!(images[0] && images[1] && images[2])}
                    >
                      Upload Images
                    </Button>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <form onSubmit={(e) => handleSubmitAddress(e)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main" }}
                        >
                          Complete Address*
                        </Typography>
                        <TextField
                          multiline={true}
                          id="completeAddress"
                          name="completeAddress"
                          value={address.completeAddress}

                          onChange={(e) => onInputChange(e)}
                          size="small"
                          required
                          sx={{
                            width: { xs: "100%", sm: "90%" },
                            borderRadius: "10px",
                            // background: "rgba(255, 255, 255, 0.31)",
                            color: "rgba(255, 255, 255, 0.96)",
                            border: "1px solid black",
                            "& fieldset": {
                              border: "none",
                              color: "rgba(255, 255, 255, 0.96);",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main" }}
                        >
                          Pin Code*
                        </Typography>

                        <TextField
                          id="pinCode"
                          name="pinCode"
                          type="number"
                          value={address.pinCode ? address.pinCode : ""}
                          // defaultValue={restaurantDetails.address.pinCode}
                          onChange={(e) => onInputChange(e)}
                          size="small"
                          required
                          error={isError}
                          helperText={isError ? "Invalid PIN code" : ""}
                          sx={addressTxt}
                        />

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main", mt: 2 }}
                        >
                          City*
                        </Typography>

                        <TextField
                          id="city"
                          name="city"
                          value={address.city}
                          // defaultValue={restaurantDetails.address.city}
                          onChange={(e) => onInputChange(e)}
                          size="small"
                          // sx={{ mt: 2 }}

                          required
                          sx={addressTxt}

                        />

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main", mt: 2 }}
                        >
                          State*
                        </Typography>
                        <TextField
                          id="state"
                          name="state"
                          value={address.state}
                          // defaultValue={restaurantDetails.address.state}

                          onChange={(e) => onInputChange(e)}
                          size="small"
                          required
                          sx={addressTxt}

                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main" }}
                        >
                          Landmark*
                        </Typography>

                        <TextField
                          id="landmark"
                          name="landmark"
                          value={address.landmark}
                          onChange={(e) => onInputChange(e)}
                          size="small"
                          required
                          sx={addressTxt}

                        />

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main", mt: 2 }}
                        >
                          Latitude*
                        </Typography>

                        <TextField
                          id="latitude"
                          name="latitude"
                          value={
                            coordinates.latitude ? coordinates.latitude : ""
                          }
                          onChange={(e) =>
                            setCoordinates({
                              ...coordinates,
                              latitude: e.target.value,
                            })
                          }
                          size="small"
                          // sx={{ mt: 2 }}

                          required
                          sx={addressTxt}

                        />

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{ color: "grey.main", mt: 2 }}
                        >
                          Longitude*
                        </Typography>
                        <TextField
                          id="longitude"
                          name="longitude"
                          value={
                            coordinates.longitude ? coordinates.longitude : ""
                          }
                          onChange={(e) =>
                            setCoordinates({
                              ...coordinates,
                              longitude: e.target.value,
                            })
                          }
                          size="small"
                          // sx={{ mt: 2 }}
                          required
                          sx={addressTxt}

                        />
                      </Grid>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "end" },
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{
                            mt: 4,
                            background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                            color: "white.main",
                            me: 5,
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <StyledContainer maxWidth="sm">
                    <Box>
                      <StyledForm onSubmit={(e) => handleFssaiSubmit(e)}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "25px",
                                color: "rgba(0, 0, 0, 0.8)",
                              }}
                            >
                              {" "}
                              FSSAI LICENSE*
                            </Typography>
                            <Typography sx={{ color: "grey.main" }}>
                              {" "}
                              License No*
                            </Typography>

                            <StyledInput
                              fullWidth
                              variant="outlined"
                              required
                              size="small"
                              name="certificateNo"
                              value={fssaiCertificate.certificateNo}
                              onChange={(e) => onFssaiInputChange(e)}
                              sx={inputDocuments}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography sx={{ color: "grey.main" }}>
                              {" "}
                              File Upload
                            </Typography>

                            <StyledInput
                              fullWidth
                              variant="outlined"
                              // required={fileData ? false : true}
                              required
                              type="file"
                              size="small"
                              onChange={(e) => uploadFssaiDocument(e)}
                              sx={inputDocuments}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              type="submit"
                              sx={{
                                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                                color: "white.main",
                              }}
                            >
                              Submit
                            </StyledButton>
                          </Grid>
                        </Grid>
                      </StyledForm>
                    </Box>
                  </StyledContainer>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <StyledContainer maxWidth="sm">
                    <Box>
                      <form onSubmit={(e) => handleGstSubmit(e)}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "25px",
                                color: "rgba(0, 0, 0, 0.8)",
                              }}
                            >
                              {" "}
                              GST Certificate*
                            </Typography>
                            <Typography sx={{ color: "grey.main" }}>
                              {" "}
                              GST No*
                            </Typography>

                            <StyledInput
                              fullWidth
                              variant="outlined"
                              required
                              size="small"
                              name="gstNumber"
                              value={gstCertificate.gstNumber}
                              onChange={(e) => onGstInputChange(e)}
                              sx={inputDocuments}

                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography sx={{ color: "grey.main" }}>
                              {" "}
                              File Upload
                            </Typography>

                            <StyledInput
                              fullWidth
                              variant="outlined"
                              // required={fileData ? false : true}
                              required
                              type="file"
                              size="small"
                              onChange={(e) => uploadGstDocument(e)}
                              sx={inputDocuments}

                            />
                          </Grid>

                          <Grid item xs={12}>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              type="submit"
                              sx={{
                                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                                color: "white.main",
                              }}
                            >
                              Submit
                            </StyledButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </StyledContainer>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <form
                      style={{ width: "100%" }}
                      onSubmit={(e) => handleBankDetailSubmit(e)}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="16px"
                            sx={{ color: "grey.main" }}
                          >
                            Bank Name*
                          </Typography>

                          <TextField
                            name="bankName"
                            value={bankDetails.bankName}
                            //  defaultValue={restaurantDetails.bankDetail.bankName}
                            onChange={(e) => onBankDetailChange(e)}
                            size="small"
                            required
                            sx={addressTxt}

                          />

                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="16px"
                            sx={{ color: "grey.main", mt: 2 }}
                          >
                            Account Holder Name*
                          </Typography>

                          <TextField
                            name="accountHolderName"
                            value={bankDetails.accountHolderName}
                            onChange={(e) => onBankDetailChange(e)}
                            size="small"
                            required
                            sx={addressTxt}
                          />

                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="16px"
                            sx={{ color: "grey.main", mt: 2 }}
                          >
                            Branch*
                          </Typography>

                          <TextField
                            name="branch"
                            value={bankDetails.branch}
                            // defaultValue={restaurantDetails.bankDetail.branch}
                            onChange={(e) => onBankDetailChange(e)}
                            size="small"
                            type="text"
                            required
                            sx={addressTxt}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="16px"
                            sx={{ color: "grey.main" }}
                          >
                            IFSC Code*
                          </Typography>

                          <TextField
                            name="ifscCode"
                            value={bankDetails.ifscCode}
                            // defaultValue={restaurantDetails.bankDetail.ifscCode}
                            onChange={(e) => onBankDetailChange(e)}
                            size="small"
                            required
                            sx={addressTxt}
                          />

                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="16px"
                            sx={{ color: "grey.main", mt: 2 }}
                          >
                            Account Number*
                          </Typography>

                          <TextField
                            name="accountNumber"
                            value={
                              bankDetails.accountNumber
                                ? bankDetails.accountNumber
                                : ""
                            }
                            // defaultValue={restaurantDetails.bankDetail.accountNumber}
                            onChange={(e) => onBankDetailChange(e)}
                            size="small"
                            type="number"
                            required
                            sx={addressTxt}
                          />
                        </Grid>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: { xs: "center", sm: "end" },
                          }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                              mt: 4,
                              background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                              color: "white.main",
                              me: 5,
                            }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Grid>
                    </form>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <div>
                    <form onSubmit={(e) => onTimeSubmit(e)}>
                      <Grid container spacing={2} mb={1}>
                        <Grid
                          item
                          xs={10}
                          sm={3}
                          md={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "color: rgba(34, 34, 34, 0.79);",
                              fontWeight: 500,
                              fontSize: "20px",
                            }}
                          >
                            Days
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sm={7}
                          md={5}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "color: rgba(34, 34, 34, 0.79);",
                              fontWeight: 500,
                              fontSize: "20px",
                            }}
                          >
                            Start Time
                          </Typography>

                          <Typography
                            sx={{
                              color: "color: rgba(34, 34, 34, 0.79);",
                              fontWeight: 500,
                              fontSize: "20px",
                            }}
                          >
                            End Time
                          </Typography>
                        </Grid>
                      </Grid>

                      {weeks.map((item, index) => (
                        <>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={2}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                sx={{
                                  color: "#FF8A00",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {item[0].toUpperCase() + item.substring(1)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={7}
                              md={5}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <LocalizationProvider
                                border={2}
                                dateAdapter={AdapterMoment}
                              >
                                <MobileTimePicker
                                  // label="Select Time"
                                  sx={{ width: "15ch" }}
                                  minutesStep={1}
                                  value={createDateValue(
                                    time[item].startTime.hours,
                                    time[item].startTime.min
                                  )}
                                  onChange={(newValue) => {
                                    timeChangeHandler(
                                      newValue,
                                      item,
                                      "startTime",
                                      { endTime: time[item].endTime }
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>

                              <Typography
                                sx={{
                                  color: "rgba(13, 30, 55, 0.63)",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                }}
                              >
                                to
                              </Typography>

                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileTimePicker
                                  // label="Select Time"

                                  sx={{ width: "15ch" }}
                                  minutesStep={1}
                                  value={createDateValue(
                                    time[item].endTime.hours,
                                    time[item].endTime.min
                                  )}
                                  onChange={(newValue) => {
                                    timeChangeHandler(
                                      newValue,
                                      item,
                                      "endTime",
                                      { startTime: time[item].startTime }
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>

                          {item[0].toUpperCase() + item.substring(1) ===
                          "Saturday" ? (
                            <></>
                          ) : (
                            <Divider sx={{ mt: 1, mb: 1 }} />
                          )}
                        </>
                      ))}

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "center", sm: "end" },
                        }}
                      >
                        <Button
                          id="sign-in-button"
                          sx={{
                            mt: 3,
                            background:
                              "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                          }}
                          variant="contained"
                          type="submit"
                          size="large"
                          onClick={submitTimeHandler}
                        >
                          Update Time
                        </Button>
                      </Grid>
                    </form>
                  </div>
                </TabPanel>
              </Box>
            )}
          </Paper>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default CreateRestaurantDetails;
