




import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { Box, Button, Grid } from "@mui/material";
import DragsAndDrop from "../../../../reusableComponent/imageUpload/DragsAndDrop";

const EditRestaurantImages = (props) => {
  //   const [images, setImages] = useState([null, null, null]);
  // const [images, setImages] = useState([props.images[0]], [props.images[1]], [props.images[2]]);
  const { snackbar, setIsLoading } = useContext(AdminDataContext);

  const imageUploadHandler = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      let formData = new FormData();
      for (let index = 0; index < props.images.length; index++) {
        const element = props.images[index];

        formData.append("images", element);
      }
      const res = await axios.patch(
        `/api/v1/owner/create/restaurant/?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        formData
      );
      setIsLoading(false);
      
      props.getAboutOutLet();
      props.handleEditImage();
      snackbar(res.data.message, "success");
      setIsLoading(false);
    } catch (error) {
    
      snackbar("Unable to upload images try again", "error");
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        spacing={2}
      >
        <Grid container spacing={4} mt={1.5}>
          {props.images.map((item, index) => (

            
            <Grid item md={4} xs={12} key={index}>
              <DragsAndDrop
                heading="Upload Image"
                inputName="Image"
                sx={{ border: "none" }}
                imgKey={props.images ? props.images[index] : null}
                aspect={2 / 1}
                uploadFile={(x) => {   
                  const imagesData = props.images.map((prv, i) => {
                    if (i === index) {
                      // Increment the clicked counter
                      console.log(item);
                      return x;
                    } else {
                      // The rest haven't changed
                      return prv;
                    }
                  });
                  props.setImages(imagesData);

                }}

              



              />
            </Grid>




          ))}
        </Grid>

        <Grid container spacing={2}>  
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              sx={{ mt: { xs: 2, sm: 2, md: 5 }, mx: 2 }}
              variant="contained"
              onClick={props.handleEditImage}
              // sx={{
              //   mt: 4,
              //   background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
              //   boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
              //   color: "white.main",
              //   mx: 2,
              // }}
            >
              back
            </Button>

            <Button
              sx={{ mt: { xs: 2, sm: 2, md: 5 } }}
              variant="contained"
              onClick={imageUploadHandler}
              disabled={
                !(props.images[0] && props.images[1] && props.images[2])
              }
            >
              Upload Images
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditRestaurantImages;
