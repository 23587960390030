import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Paper,
  useMediaQuery,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { AdminDataContext } from "../../useContext/AdminContext";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import CreateRestaurantDetails from "./CreateRestaurantDetails";
import { imgUrl } from "../../url";

const SelectRestaurant = () => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const { snackbar, setIsLoading } = useContext(AdminDataContext);

  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(false);
  const [resId, setResId] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [restaurant, setRestaurant] = React.useState(null);

  const [createRestaurant, setCreateRestaurant] = useState({
    name: "",
    avgCost: null,
    restaurantType: "",
    veg: false,
    nonVeg: false,
    longitude: 50,
    latitude: 50,
  });
  const { name, avgCost, restaurantType, veg, nonVeg } = createRestaurant;

  const restaurantBasicDetails = (id) => {
    setIndex(true);
    setResId(id);
  };
  const navigate = useNavigate();
  const onInputChange = (e) => {
    setCreateRestaurant({
      ...createRestaurant,
      [e.target.name]: e.target.value,
    });
  };
  const changeRestaurant = (id) => {
    localStorage.setItem("restaurantId", id);
    navigate("/");
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/owner/create/restaurant`, {
        ...createRestaurant,
        avgCost: avgCost * 1,
      });
      // console.log(res);

      handleClose();
      setIsLoading(false);
      snackbar("Restaurant created successfully", "success");
      getRestaurant();
    } catch (error) {}
  };

  const getRestaurant = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get("/api/v1/restaurant");
      setRestaurant(res.data.owner.restaurant);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "50%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "16px",

    boxShadow: 24,
    p: 4,
  };   
  const textFieldStyle = {
    boxShadow: 2,
    width: matches ? "270px" : "230px",
    borderRadius: "10px",
    backgroundColor: "textFieldColor.main",
    
    border: "1px solid black",
    "& fieldset": {
      border: "none",
 
    },
  };

  useEffect(() => {
    getRestaurant();
  }, []);

  return (
    <>
      <Box display={index === false ? "block" : "none"}>
        <Container
          fixed
          sx={{
            alignContent: "center",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              width: "100%",
              bgcolor: "bgColor.main",
              borderRadius: "20px",
              minHeight: "80vh",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
            elevation={10}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: -125,

                width: "30%",
                height: "30%",
                display: matches ? "block" : "none",
              }}
              component="img"
              alt="logo"
              src="/images/restorentSelectImg1.svg"
            ></Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "200px",
                }}
                component="img"
                alt="logo"
                src="/images/logoCreateRest.svg"
              ></Box>
              <Typography sx={{ color: "grey.tertiary", fontWeight: 600, mt: 1 }}>
                SELECT YOUR RESTAURANT
              </Typography>
            </Box>

            {restaurant &&
              restaurant.map((data, i) => {
                return (
                  <>
                    <Grid
                      container
                      key={data._id}
                      onClick={() =>
                        data.approved === false
                          ? restaurantBasicDetails(data._id)
                          : changeRestaurant(data._id)
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 4,
                        borderRadius: "100px",
                      }}
                    >
                      <Grid item xs={8} sm={3} md={1}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            borderRadius: 2,
                          }}
                          component="img"
                          alt="logo"
                          src={
                            data.images[1]
                              ? `${imgUrl}/${data.images[1]}`
                              : "/images/defaultRes.svg"
                          }
                        ></Box>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={6}
                        md={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          p: 1,
                          backgroundColor: "whisper.main",
                          mt: { xs: 2, sm: 0 },
                          borderRadius: 2,
                        }}
                      >
                        <Typography
                          sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                        >
                          {data.name}
                        </Typography>
                        <Typography
                          sx={{ color: "grey.main", fontWeight: 600, mt: 1 }}
                        >
                          {data.address ? data.address.completeAddress : ""}
                        </Typography>

                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="15px"
                          lineHeight="20px"
                          sx={{ color: "rgba(13, 30, 55, 0.67)", mt: 1 }}
                        >
                          {data.latitude} {data.longitude}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })}

            <Button
              id="sign-in-button"
              onClick={handleOpen}
              sx={{
                m: 1,
                width: "auto",
                marginTop: "50px",
                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                color: "white.main",

                // p: 1,
                textTransform: "capitalize",
                fontSize: "20px",
                fontWeight: 600,
                px:3
              }}
              variant="contained"
              size="large"
            >
              Create New Restaurant
            </Button>

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: -85,

                width: "30%",
                height: "30%",
                display: matches ? "block" : "none",
                borderRadius: "20px",
              }}
              component="img"
              alt="logo"
              // sx={{ width: "50px" }}
              src="/images/selectRestaurantSelect2.svg"
            ></Box>
          </Paper>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box
                component="form"
                // noValidate
                onSubmit={(e) => onSubmit(e)}
                sx={style}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    id="transition-modal-title"
                    variant="h4"
                    component="h2"
                    sx={{
                      display: "inline-block",
                      background: `#fd9459`,
                      color: "white.main",
                      fontWeight: 600,
                      fontSize: { xs: "22px", md: "30px" },
                      px: 4,
                      py: 1,
                      borderRadius: "10px",
                    }}
                  >
                    ADD YOUR RESTAURANT
                  </Typography>
                </Box>

                <Box>
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Box marginTop={3}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{}}
                        >
                          Name
                        </Typography>

                        <TextField
                         
                          id="outlined-basic"
                          size="small"
                          type="text"
                          name="name"
                          required
                          value={name}
                          onChange={(e) => onInputChange(e)}
                          placeholder="Enter name"
                          sx={textFieldStyle}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Box marginTop={3}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{}}
                        >
                          Average Cost
                        </Typography>

                        <TextField
                          id="outlined-basic"
                          size="small"
                          type="number"
                          name="avgCost"
                          value={avgCost}
                          onChange={(e) => onInputChange(e)}
                          placeholder="Enter Cost"
                          required
                          sx={textFieldStyle}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {/* ------------ */}
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Box marginTop={3}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          fontSize="16px"
                          sx={{}}
                        >
                          Restaurant Type
                        </Typography>

                        <FormControl size="small">
                          {/* <InputLabel id="demo-select-small">Select </InputLabel> */}
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            name="restaurantType"
                            value={restaurantType}
                            onChange={(e) => onInputChange(e)}
                            required
                            sx={textFieldStyle}
                          >
                            {/* <MenuItem value={10}>Select Type</MenuItem> */}
                            <MenuItem value="QSR" >
                              Quick Service Restaurant
                            </MenuItem>
                            <MenuItem value="DSR">
                              Dining Service Restaurant
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Box marginTop={3}>
                        <FormControl
                          sx={{ width: matches ? "270px" : "230px" }}
                        >
                          <Typography
                            variant="h6"
                            fontWeight={500}
                            fontSize="16px"
                            sx={{}}
                          >
                            Food Type
                          </Typography>

                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              name="veg"
                              value="true"
                              onChange={(e) => onInputChange(e)}
                              control={<Checkbox />}
                              label="Veg"
                              labelPlacement="top"
                            />
                            <FormControlLabel
                              name="nonVeg"
                              value="true"
                              onChange={(e) => onInputChange(e)}
                              control={<Checkbox />}
                              label="Non Veg"
                              labelPlacement="top"
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  {/* ------------ */}
                  <Grid
                    container
                    spacing={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{ mt: 5, display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        id="create-restaurant"
                        type="submit"
                        sx={{
                          width: "auto",

                          background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                          color: "white.main",

                          p: 1,
                          textTransform: "capitalize",
                          fontSize: "20px",
                          fontWeight: 600,
                          mt: 1,
                          px: 5,
                        }}
                        variant="contained"
                        size="large"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Container>
      </Box>

      <Box display={index === true ? "block" : "none"}>
        {resId && index ? <CreateRestaurantDetails id={resId} /> : <></>}
      </Box>
    </>
  );
};

export default SelectRestaurant;
