import React, { useContext, useState } from "react";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RejectNewReservationModal from "../modal/RejectNewReservationModal";
import { findDate } from "../../ExtractDate";
import { time } from "../../ExtractTime";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";

const NewReservationCard = (props) => {
  const [rejectNewReservationModal, setRejectNewReservationModal] =
    useState(false);
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const handleOpenRejectNewReservationModal = () =>
    setRejectNewReservationModal(true);
  const handleCloseRejectNewReservationModal = () =>
    setRejectNewReservationModal(false);

  const date = findDate(props.reservation.time);
  const getTime = time(props.reservation.time);

  const handleAcceptReservation = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
     
      const res = await axios.post(
        `/api/v1/owner/reservation/confirm?reservationId=${props.reservation._id}`
      );

      props.getAllReservation();
      snackbar(res.data.message, "success");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);

    }
  };

  const rejectBtn = {
    backgroundColor: "pink.brinkPink",
    color: "white.main",
    width: "100%",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",

    "&:hover": {
      backgroundColor: "pink.brinkPink",
    },
  };
  const acceptBtn = {
    backgroundColor: "green.midGreen",
    color: "white.main",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    // border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    width: "100%",

    "&:hover": {
      backgroundColor: "green.midGreen",
    },
  };
  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        p={3}
        mt={3}
        component={"form"}
        onSubmit={handleAcceptReservation}
      >
        <Card elevation={0}>
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
            }}
          >
            <Grid
              container
              spacing={2}
              mb={2}
              sx={{
                background:
                  "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
              }}
            >
              <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 2,
                    color: "red.tertiary",
                  }}
                >
                  YBITES DELIVERY
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: 1,
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                  {/* ID : 56459629 */}
                  {`ID : ${props.reservation.reservationId}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black.primary",
                  }}
                >
                  {props.reservation.customer.name}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Number of Guests
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  {props.reservation.people}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid container pt={1} spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Date
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon sx={{ fontSize: "18px" }} />

                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "black.primary",
                      //   ms:1    
                    }}
                  >
                    {/* 30-12-2022 */}
                    {date}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />

            <Grid container pt={1} spacing={2}>
              <Grid item xs={5}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Time slot
                </Typography>
              </Grid>
              <Grid item xs={7} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  {/* 11am to 11:30am */}
                  {getTime}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </CardContent>

          <Grid
            container
            spacing={2}
            mt={1}
            sx={{ backgroundColor: "transparent" }}
          >
            <Grid item xs={4}>
              <Button
                sx={rejectBtn}
                onClick={handleOpenRejectNewReservationModal}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={acceptBtn}
                type="submit"
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Accept Requests
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <RejectNewReservationModal
        handleCloseRejectNewReservationModal={
          handleCloseRejectNewReservationModal
        }
        rejectNewReservationModal={rejectNewReservationModal}
        getAllReservation={props.getAllReservation}
        reservationId={props.reservation._id}
      />
    </>
  );
};

export default NewReservationCard;
