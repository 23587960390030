import {
    Grid,
    Box,
    Stack,
    Typography,
    Modal,
    IconButton,
    useMediaQuery,
    Button,
  } from "@mui/material";
  
  import CancelIcon from "@mui/icons-material/Cancel";
  
  import React, {  } from "react";
  

const WelcomeModal = (props) => {
    const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
  };
  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",

    paddingY: 1,
    paddingX: {xs:0.5, sm:2},

    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
    my:2,
    // mb:2
  };
  return (
    <>
    <Modal
      open={props.welcomeModal}
      onClose={props.handleCloseWelcomeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box noValidate sx={style}>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                fontSize: "22px",

                color: "rgba(13, 30, 55, 0.67)",
                justifySelf: "start",
                visibility: "hidden",
              }}
            >
              Help Center
            </Typography>

            <IconButton
              onClick={props.handleCloseWelcomeModal}
              aria-label="delete"
            >
              <CancelIcon />
            </IconButton>
          </Stack>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
            >
              <Box component={"img"} src="/images/helpCenterImg.svg"></Box>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2, textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: {xs:"20px", sm:"24px"},
                  fontWeight: 500,
                  color: "black.helpCenter",
                  fontWeight:600,
                }}
              >
               Welcome to Ybites Restaurant Partner
              </Typography>
             
              <Button onClick={props.handleCloseWelcomeModal} variant="outlined" sx={active}>
              GET STARTED
        </Button>

              
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  </>
  )
}

export default WelcomeModal