import React, {  useContext } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Button,
 
  Grid,

  IconButton,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";
const ModalForPreprationTime = (props) => {
  const matches = useMediaQuery("(min-width:450px)");
  const navigate = useNavigate();
  const { setIsLoading, snackbar, setApiCall } = useContext(AdminDataContext);

  let data = { orderId: props.id, preparationTime: props.preparationTime };
 
  const setTime = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/owner/order/accept`, {
        ...data,
      });
      snackbar(res.data.message, "success");
      setIsLoading(false);
      setApiCall(true)
      props.handlCloseModalForPreprationTime();
      props.handlCloseAcceptOrderModal();
      props.newOrdersDetails(props.page);

      // navigate("live-orders/preparing");
    } catch (error) {
      console.log(error);
      snackbar("Some Error ocurred", "error");

      setIsLoading(false);
    }
  };

  const mainHeading = {
    fontWeight: 500,
    fontSize: "14px",
    backgroundColor: "red.primary",
    py: 0.5,
    px: 2,
    color: "bgWhite.tertiary",
    width: "max-content",
    borderRadius: "8px",
  };

  const btn = {
    textTransform: "capitalize",
    color: "white.main",
    fontWeight: 600,
    fontSize: "18px",
    backgroundColor: "blue.primary",
    "&:hover": {
      backgroundColor: "blue.primary",
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "400px" : "300px",

    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openModalForPreprationTime}
        onClose={props.handlCloseModalForPreprationTime}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openModalForPreprationTime}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{ position: "relative" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "rgba(13, 30, 55, 0.67)",
                    justifySelf: "start",
                  }}
                >
                  Set Preparation Time
                </Typography>
                <IconButton
                  onClick={props.handlCloseModalForPreprationTime}
                  aria-label="delete"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>

              <Grid
                container
                component={"form"}
                onSubmit={setTime}
                sx={{
                  borderRadius: "11px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={mainHeading}>{props.counter}</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box
                      component="img"
                      alt="smilySetPreprationTime"
                      src={
                        props.preparationTime <= 20
                          ? "/images/smilySetPreprationTime.svg"
                          : props.preparationTime >= 20 &&
                            props.preparationTime <= 40
                          ? "/images/sadSetPreprationTime.svg"
                          : "/images/moreSadSetPreprationTime.svg "
                      }
                    ></Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={props.decreaseTime}
                      aria-label="decreaseTime"
                      style={{ color: "red", fontSize: "40px" }}
                    >
                      <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton>

                    <Typography sx={{ mx: 4, color: "black.tertiary" }}>
                      {" "}
                      <span
                        style={{
                          backgroundColor: "#0369FF",
                          color: "white",
                          padding: "10px",
                          borderRadius: "100%",
                        }}
                      >
                        {props.preparationTime}
                      </span>{" "}
                      min
                    </Typography>

                    <IconButton
                      onClick={props.increaseTime}
                      aria-label="increaseTime"
                      style={{ color: "#0369FF", fontSize: "40px" }}
                    >
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      type="submit"
                      //  onClick={setTime}
                      variant="outlined"
                      sx={btn}
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalForPreprationTime;
