import React, { useContext, useState } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Grid,
  IconButton,
  Button,
  TextField,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { AdminDataContext } from "../../../../../useContext/AdminContext";

const EditManagerModal = (props) => {
  const matches = useMediaQuery("(min-width:450px)");

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;

  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  // const [data, setData] = useState({});

  const onChangeHandler = (e) => {
    props.setData({ ...props.data, [e.target.name]: e.target.value });
  };
  const handleRaiseATicketSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const res = await axios.post(
        `/api/v1/owner/restaurant/contact?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        {
          ...props.data,
          contactPersonPhone: `${props.data.contactPersonPhone}`,
        }
      );

      props.setData({});
      snackbar(res.data.message, "success");

      props.getAboutOutLet();

      props.handleCloseEditManager();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const mainHeading = {
    fontWeight: 400,
    fontSize: "16px",
    color: "black.primary",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "400px" : "300px",

    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
  };

  const textFields = {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    borderRadius: "5px",
    background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openEditManagerModal}
        onClose={props.handleCloseEditManager}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openEditManagerModal}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{
                  position: "relative",
                  backgroundColor: "pink.dawnPink",
                  px: 2,
                  py: 1,
                  borderRadius: "6px ",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "black.primary",
                    justifySelf: "start",
                  }}
                >
                  Edit Manager Details
                </Typography>
                <IconButton
                  onClick={props.handleCloseEditManager}
                  aria-label="cancel"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>

              <Grid
                container
                sx={{
                  borderRadius: "11px",
                }}
              >
                <Grid
                  container
                  component={"form"}
                  onSubmit={handleRaiseATicketSubmit}
                  spacing={2}
                >
                  <Grid item xs={12} mx={2} mt={1}>
                    <Typography sx={mainHeading}>Name*</Typography>

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      required
                      type="text"
                      name="contactPersonName"
                      onChange={onChangeHandler}
                      value={props.data.contactPersonName}
                      placeholder="Name"
                      sx={textFields}
                    />
                  </Grid>
                  <Grid item xs={12} mx={2}>
                    <Typography sx={mainHeading}>Number*</Typography>

                    <TextField
                      fullWidth
                      id="outlined-basic"
                      size="small"
                      type="phone"
                      required
                      name="contactPersonPhone"
                      value={props.data.contactPersonPhone ? props.data.contactPersonPhone.slice(-10) : ""}
                      onChange={onChangeHandler}
                      placeholder="Number"
                      sx={textFields}
                      inputProps={{
                        pattern: "[0-9]{10}",
                        maxLength: 10,
                        autoComplete: "tel",
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    mx={2}
                    mb={2}
                    display="flex"
                    justifyContent={"center"}
                  >
                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{
                        textTransform: "capitalize",
                        color: "white.main",
                        fontWeight: 500,
                        fontSize: "18px",
                        background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                        border: "none",
                        width: "100%",
                        mx: 5,
                        "&:hover": {
                          border: "none",
                        },
                      }}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EditManagerModal;
