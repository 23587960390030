import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Button,
  Grid,
  Divider,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import { useNavigate } from "react-router-dom";
import { AdminDataContext } from "../../../../../useContext/AdminContext";

import { time } from "../../ExtractTime";
import axios from "axios";

const PreparingItemDetailsModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:450px)");
  let createdAt = "";
  createdAt = time(props.preparingItem.createdAt);

  const navigate = useNavigate();

  const { setIsLoading, snackbar, setApiCall } = useContext(AdminDataContext);

  let data = { orderId: props.preparingItem._id, preparationTime: 0 };

  const handlePreparedOrder = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/owner/order/ready`, {
        ...data,
      });
      snackbar(res.data.message, "success");
      props.handlCloseAcceptOrderModal();
      setIsLoading(false);
      setApiCall(true);
      props.getAllPreparingItems();
      // navigate("/live-orders/ready");
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    // left: "50%",
    left: matches ? "59%" : "50%",
    transform: "translate(-50%, -50%)",
    // width: matches ? "400px" : "300px",
    width: matches ? "50%" : matchesDown ? "80%" : "95%",
    // bgcolor: "whisper.main",
    borderRadius: "5px",
    boxShadow: 24,
    overflow: "auto",
    maxHeight: "90vh",
  };

  const acceptBtn = {
    backgroundColor: "yellow.tertiary",
    color: "white.main",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    // border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    width: "100%",

    "&:hover": {
      backgroundColor: "yellow.tertiary",
    },
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openAcceptOrderModal}
        onClose={props.handlCloseAcceptOrderModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openAcceptOrderModal}>
          <Box
            component="form"
            onSubmit={handlePreparedOrder}
            noValidate
            sx={style}
          >
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardContent
                  elevation={10}
                  sx={{
                    backgroundColor: "whisper.main",
                    minWidth: "100%",
                    boxShadow: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      sx={{
                        backgroundColor: "red.beanRed",
                        borderRadius: "5px 0px 5px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white.main",
                          fontSize: "20px",
                          fontWeight: 600,
                          mb: 2,
                        }}
                      >
                        Preparing
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={8}
                      md={9}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px" },
                          fontWeight: 600,
                          mb: 1,
                          mx: 1,
                        }}
                      >
                        ID : {props.preparingItem.orderId}
                      </Typography>
                      <IconButton
                        onClick={props.handlCloseAcceptOrderModal}
                        aria-label="close"
                        sx={{ position: "absolute", top: -8, right: -25 }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    mt={0}
                    mb={2}
                    sx={{
                      background:
                        "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", md: "18px" },
                          fontWeight: 600,
                          mb: 2,
                          letterSpacing: "0.42em",
                          color: "red.tertiary",
                        }}
                      >
                        YBITES DELIVERY
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "15px" },
                          fontWeight: 300,
                          mb: 2,

                          color: "black.primary",
                        }}
                      >
                        Placed : {createdAt}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", sm: "18px" },
                          fontWeight: 600,
                        }}
                      >
                        {props.preparingItem.customer.name}
                      </Typography>

                      {/* <Button
                        variant="outlined"
                        startIcon={<PhoneForwardedIcon />}
                      >
                        Call
                      </Button> */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 300,
                          color: "black.tertiary",
                        }}
                      >
                        {/* 1st order Lokmanya nagar : Thane West (3 kms, 15 min
                        away) */}
                        {`${props.preparingItem.customerAddress.completeAddress}, 
                        ${props.preparingItem.customerAddress.landmark}, 
                        ${props.preparingItem.customerAddress.city}, 
                        ${props.preparingItem.customerAddress.state}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  {props.preparingItem.orderItems.map((item, index) => (
                    <>
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={8}>
                          <Box sx={{ display: "flex" }}>
                            <img
                              src="/images/orderItemImg.svg"
                              alt="orderItemImg"
                            />
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                            >
                              {item.quantity} X {item.menuItemTitle}
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            {item.customizationItemTitles.map(
                              (customizationItemTitles, index) =>
                                `${customizationItemTitles}, `
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            Add Ons -
                            {item.addOnItemTitles.map(
                              (addOnItemTitles, index) =>
                                ` ${addOnItemTitles}, `
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            {item.optionItemTitles.map(
                              (optionItemTitles, index) =>
                                `${optionItemTitles}, `
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Typography
                            sx={{ fontSize: "15px", fontWeight: 700 }}
                          >
                            ₹{Math.round(item.totalPrice * 100) / 100}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}

                  <Grid container spacing={2} mt={0}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 500,
                          mb: 1,

                          color: "black.tertiary",
                        }}
                      >
                        {/* Instr- We want 2 different parcel of veg and non-veg */}
                        Instr - {props.preparingItem.note}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    mt={0}
                    sx={{ backgroundColor: "purple.tertiary" }}
                  >
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 500,

                          color: "black.tertiary",
                          mt: -1.5,
                        }}
                      >
                        Total Price
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "black.tertiary",
                          mt: -1.5,
                        }}
                      >
                        ₹{Math.round(props.preparingItem.price * 100) / 100}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,

                          color: "black.tertiary",
                        }}
                      >
                        Taxes
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {`₹${
                          Math.round(props.preparingItem.totalTax * 100) / 100
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 700,
                          color: "black.primary",
                        }}
                      >
                        Total Bill
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            mx: 1,
                            color: "black.primary",
                            border: "1px solid rgba(0, 153, 80, 0.4)",
                            borderRadius: "2px",
                            letterSpacing: "0.265em",
                            px: 1,
                          }}
                        >
                          PAID
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "black.primary",
                          }}
                        >
                          ₹
                          {Math.round(
                            (props.preparingItem.totalTax +
                              props.preparingItem.totalPrice) *
                              100
                          ) / 100}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  {props.preparingItem.deliveryPartner ? (
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={12}>
                        <Box border={0.2} mt={1} p={2} borderRadius={2}>
                          <FormControlLabel
                            value="1"
                            control={
                              <Box
                                component="img"
                                src="/images/deliveryBoy.svg"
                              ></Box>
                            }
                            label={
                              <div>
                                <Typography
                                  sx={{ fontSize: "15px", fontWeight: 700 }}
                                >
                                  {props.preparingItem.deliveryPartner
                                    ? props.preparingItem.deliveryPartner.name
                                    : ""}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "15px", fontWeight: 700 }}
                                >
                                  {props.preparingItem.deliveryPartner
                                    ? props.preparingItem.deliveryPartner.phone
                                    : ""}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: 400,
                                    color: "black.primary",
                                  }}
                                >
                                  On the way
                                </Typography>
                              </div>
                            }
                          />

                          {/* <Button
                                          sx={{ padding: 0.3 }}
                                          variant="outlined"
                                          startIcon={<PhoneForwardedIcon />}
                                        >
                                          Call
                                        </Button> */}
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid
                    container
                    spacing={2}
                    sx={{ backgroundColor: "whisper.main", mt: 1, mb: -1 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        type="submit"
                        sx={acceptBtn}
                        id="sign-in-button"
                        variant="contained"
                        size="large"
                      >
                        Order Ready
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PreparingItemDetailsModal;
