import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Paper, Button } from "@mui/material";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import RaiseATicketModal from "../ticketHistory/modal/RaiseATicketModal";

const TabHeading = (props) => {
  const location = useLocation();
  const currentTabHeading = location.pathname;
  const [openRaiseModal, setOpenRaiseModal] = useState(false);
  const handleOpenRaiseModal = () => setOpenRaiseModal(true);
  const handleCloseRaiseModal = () => setOpenRaiseModal(false);
   
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "whisper.main",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid rgba(0, 0, 0, 0.14)",
          p: {xs:1, sm:2},
          borderRadius: "12px",
          mt: 2,
        }}
      >
        <Button
          //   onClick={handleChange}

          variant="outlined"
          sx={{
            textTransform: "capitalize",
            color: "green.secondary",
            fontWeight: 600,
            fontSize: "20px",
            p:{xs:0.5, sm:1},
            border: "none",
            "&:hover": { border: "none" },
          }}
          // endIcon={<AutorenewIcon />}
        >
          {currentTabHeading === "/complaints"
            ? "New Complaints"
            : currentTabHeading === "/complaints/pending"
            ? "Pending "
            : currentTabHeading === "/complaints/refunded"
            ? "Resolved"
            : currentTabHeading === "/complaints/ticket-history"
            ? "Ticket History"
            : ""}
        </Button>

        {currentTabHeading === "/complaints/ticket-history" ? (
          <Button
            variant="outlined"
            onClick={handleOpenRaiseModal}
            sx={{
              textTransform: "capitalize",
              color: "white.main",
              fontWeight: 500,
              fontSize: "18px",
              background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
              border: "none",
              p:{xs:0.7, sm:1.3},
              "&:hover": {
                border: "none",
              },
            }}
          >
            Raise a Ticket
          </Button>
        ) : (
          <></>
        )}
      </Paper>

      <RaiseATicketModal
        openRaiseModal={openRaiseModal}
        handleCloseRaiseModal={handleCloseRaiseModal}
        getAllTicketHistory = {props.getAllTicketHistory}
      />
    </>
  );
};

export default TabHeading;
