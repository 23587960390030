import React, { useState, useEffect, useContext } from "react";
import { Grid, Box } from "@mui/material";
import RefundedCard from "./card/RefundedCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
const RefundedComplaints = () => {
  const [refunded, setRefunded] = useState([]);

  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const limit = 10;
  const getAllRefundedItems = async () => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `/api/v1/owner/order/complain?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&page=${1}&limit=${limit}`
      );
      setRefunded(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // console.log("refunded ", refunded);
  useEffect(() => {
    getAllRefundedItems();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {refunded.map((refunded, index) => (
          <RefundedCard />
        ))}

        {refunded.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}

                src="/images/zeroRefunded.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default RefundedComplaints;
