import {
  Grid,
  Box,
  Container,
  Stack,
  FormControlLabel,
  Typography,
  useMediaQuery,
  Button,
  TextField,
} from "@mui/material";
import { Modal, IconButton } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import React, { useState, useContext, useEffect } from "react";

import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CreateOptionsModal = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState({});
  const [itemData, setItemData] = useState({});
  const [optionId, setOptionId] = useState(null);

  const [allOptions, setAllOptions] = useState([]);
  const [optionsModal, setOptionsModal] = React.useState(false);
  const handleOptionsModal = () => setOptionsModal(true);
  const handleCloseOptionsModal = () => setOptionsModal(false);
  const [optionsItemModal, setOptionsItemModal] = useState(false);
  const handleOptionsItemModal = (id) => {
    setOptionId(id);
    setOptionsItemModal(true);
  };
  const handleCloseOptionsItemModal = () => {
    setOptionsItemModal(false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleOptionsChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    // console.log(data);
  };
  const handleOptionsItemChange = async (event) => {
    setItemData({ ...itemData, [event.target.name]: event.target.value });
  };

  const getAllOptions = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/option?menuItemId=${props.singleMenuItemId}`
      );
      setAllOptions(res.data.option);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleSubmitCreateOption = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // console.log("optin data is");
      const menuItemId = props.singleMenuItemId;
      // console.log(data);

      const res = await axios.post(`/api/v1/owner/option`, {
        ...data,
        menuItemId,
      });
      setData({});
      getAllOptions();
      props.getSingleMenuItem();
      handleCloseOptionsModal();
      snackbar(res.data.message, "success");
    } catch (error) {
      snackbar(error.response.data.message, "error");
    }
  };

  const createOptionItemHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `/api/v1/owner/option?optionId=${optionId}`,
        {
          ...itemData,
        }
      );

      snackbar(res.data.message, "success");
      setItemData({});
      getAllOptions();
      props.getSingleMenuItem();
      handleCloseOptionsItemModal();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllOptions();
  }, []);

  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    minWidth: "140px",
    paddingY: 1,
    paddingX: 2,

    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
  };

  const createBtn = {
    backgroundColor: "bgWhite.main",
    color: "primary.main",
    width: "max-content",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  };

  const styleBox = {
    bgcolor: "background.paper",
    p: 2,
  };
  const addBtn = {
    textTransform: "capitalize",
    color: "white.main",
    fontWeight: 600,
    fontSize: "18px",
    backgroundColor: "blue.primary",
    px: 5,
    "&:hover": {
      backgroundColor: "blue.primary",
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  const textFieldStyle = {
    width: { xs: "100%" },
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  return (
    <>
      <Modal
        open={props.openCreateOptionModal}
        onClose={props.handleCloseCreateOptionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "auto" }}
      >
        <Box>
          <Container fixed sx={{ my: 1 }}>
            <Box component="form" noValidate sx={styleBox}>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  gap={1}
                  sx={{ position: "relative" }}
                >
                  <FormControlLabel
                    control={<ArrowBackIosNewIcon />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 700,
                          fontSize: "18px",
                          lineHeight: "27px",
                          color: "rgba(13, 30, 55, 0.67)",
                          justifySelf: "start",
                        }}
                      >
                        Options
                      </Typography>
                    }
                  />

                  <Button
                    onClick={handleOptionsModal}
                    sx={active}
                    id="sign-in-button"
                    variant="contained"
                    size="large"
                  >
                    Create
                  </Button>

                  <IconButton
                    onClick={props.handleCloseCreateOptionModal}
                    aria-label="delete"
                  >
                    <CancelIcon />
                  </IconButton>
                </Stack>

                <Grid
                  container
                  sx={{
                    backgroundColor: "#FF9415",
                    py: 2,
                    borderRadius: "11px",
                    px: 2,
                    mt: 5,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "start",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        // fontSize: "18px",
                        fontSize: "18px",
                        color: "white.main",
                      }}
                    >
                      <span
                        style={{
                          background: "rgba(255, 255, 255, 0.23)",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {allOptions.length}
                      </span>{" "}
                      Customized items available
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "center", pb: 2 }}
                >
                  {allOptions.map((item, index) => (
                    <Grid
                      item
                      xs={10}
                      sm={10}
                      md={10}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Accordion
                        expanded={expanded === `panel${index + 1}`}
                        onChange={handleAccordionChange(`panel${index + 1}`)}
                        key={item._id}
                        sx={{
                          backgroundColor: "transparent",
                          border: "1px solid rgba(0, 0, 0, 0.34)",
                          borderRadius: "21px",
                          width: "100%",
                        }}
                        elevation={0}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index + 1}a-content`}
                          id={`panel${index + 1}a-header`}
                          sx={{
                            background:
                              "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                            borderRadius: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "18px",
                              color: "white.main",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {item.optionItems.length === 0 ? (
                            <>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid
                                  item
                                  xs={10}
                                  sm={7}
                                  md={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "70%",
                                      height: "70%",
                                      borderRadius: "20px",
                                      mt: 3,
                                    }}
                                    component="img"
                                    alt="addOns"
                                    src="/images/zeroAddOnItems.svg"
                                  ></Box>
                                </Grid>
                              </Grid>

                              <Grid container spacing={2} mt={3}>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",

                                    justifyContent: { xs: "center", md: "end" },
                                    alignItems: "end",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleOptionsItemModal(item._id)
                                    }
                                    sx={active}
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              {item.optionItems.map((optionItems) => (
                                <Box
                                  key={optionItems._id}
                                  pt={2}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ ms: 5 }}>
                                    {optionItems.title}
                                  </Typography>
                                </Box>
                              ))}

                              <Grid container spacing={2} mt={3}>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",

                                    justifyContent: { xs: "center", md: "end" },
                                    alignItems: "end",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleOptionsItemModal(item._id)
                                    }
                                    sx={active}
                                  >
                                    Add
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))}
                </Grid>

                <Box
                  sx={{ display: allOptions.length === 0 ? "block" : "none" }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      item
                      xs={10}
                      sm={7}
                      md={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "70%",
                          borderRadius: "20px",
                          mt: 3,
                        }}
                        component="img"
                        alt="addOns"
                        src="/images/zeroOptionItems.svg"
                      ></Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Modal>

      <Modal
        open={optionsModal}
        onClose={handleCloseOptionsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <FormControlLabel
                control={<ArrowBackIosNewIcon />}
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "rgba(13, 30, 55, 0.67)",
                      justifySelf: "start",
                    }}
                  >
                    Create Options
                  </Typography>
                }
              />

              <IconButton onClick={handleCloseOptionsModal} aria-label="delete">
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={handleSubmitCreateOption}
            >
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Option Title
                </Typography>

                <TextField
                  size="small"
                  name="title"
                  value={data.title}
                  required
                  onChange={handleOptionsChange}
                  placeholder="Title"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Maximum Select
                </Typography>

                <TextField
                  size="small"
                  type="number"
                  name="maximumSelect"
                  value={data.maximumSelect}
                  required
                  onChange={handleOptionsChange}
                  placeholder="Maximum Select"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid container spacing={2} px={3} mt={3}>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",

                      justifyContent: { xs: "center" },
                      alignItems: "end",
                    }}
                  >
                    <Button variant="outlined" type="submit" sx={active}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={optionsItemModal}
        onClose={handleCloseOptionsItemModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <FormControlLabel
                control={<ArrowBackIosNewIcon />}
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "rgba(13, 30, 55, 0.67)",
                      justifySelf: "start",
                    }}
                  >
                    Create Options Item
                  </Typography>
                }
              />

              <IconButton
                onClick={handleCloseOptionsItemModal}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={createOptionItemHandler}
            >
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Options Item Title
                </Typography>

                <TextField
                  size="small"
                  name="title"
                  value={itemData.title}
                  required
                  onChange={handleOptionsItemChange}
                  placeholder="Title"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid container spacing={2} px={3} mt={3}>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",

                      justifyContent: { xs: "center" },
                      alignItems: "end",
                    }}
                  >
                    <Button variant="outlined" type="submit" sx={active}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreateOptionsModal;
