import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Button,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import ModalForPreprationTime from "./ModalForPreprationTime";
import ModalForRejectNewOrder from "./ModalForRejectNewOrder";

const ModalForAcceptNewOrder = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:450px)");
  const [preparationTime, setPreparationTime] = useState(10);

  const increaseTime = () => {
    if (preparationTime < 60) {
      setPreparationTime(preparationTime + 10);
    }
  };
  const decreaseTime = () => {
    if (preparationTime > 10) {
      setPreparationTime(preparationTime - 10);
    }
  };

  const [counter, setCounter] = useState(50);
  const [start, setStart] = useState(false);

  useEffect(() => {
    let interval;

    if (start && counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      handlCloseModalForPreprationTime();
      props.handlCloseAcceptOrderModal();
    }

    return () => clearInterval(interval);
  }, [start, counter]);

  const [openModalForPreprationTime, setOpenModalForPreprationTime] =
    useState(false);
  const handlOpenModalForPreprationTime = () =>
    setOpenModalForPreprationTime(true);
  const handlCloseModalForPreprationTime = () =>
    setOpenModalForPreprationTime(false);

  const [openRejectOrderModal, setOpenRejectOrderModal] = useState(false);
  const handlOpenRejectOrderModal = () => setOpenRejectOrderModal(true);
  const handlCloseRejectOrderModal = () => setOpenRejectOrderModal(false);

  const acceptOrder = () => {
    handlOpenModalForPreprationTime();
    setStart(true);
    setCounter(50);
  };
  // console.log("props.customerAddress.city", props.customerAddress);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: matches ? "400px" : "300px",
    width: matches ? "50%" : matchesDown ? "80%" : "95%",
    // bgcolor: "whisper.main",
    borderRadius: "5px",
    boxShadow: 24,
  };

  const rejectBtn = {
    backgroundColor: "bgColor.secondary",
    color: "black.primary",
    width: "100%",
    // border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "bgColor.secondary",
    },
  };
  const acceptBtn = {
    backgroundColor: "yellow.tertiary",
    color: "white.main",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    // border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    width: "100%",

    "&:hover": {
      backgroundColor: "yellow.tertiary",
    },
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openAcceptOrderModal}
        onClose={props.handlCloseAcceptOrderModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openAcceptOrderModal}>
          <Box component="form" noValidate sx={style}>
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardContent
                  elevation={10}
                  sx={{
                    backgroundColor: "whisper.main",
                    minWidth: "100%",
                    boxShadow: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      sx={{
                        backgroundColor: "blue.secondary",
                        borderRadius: "5px 0px 5px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white.main",
                          fontSize: "20px",
                          fontWeight: 600,
                          mb: 2,
                        }}
                      >
                        New Orders
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={8}
                      md={9}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "18px" },
                          fontWeight: 600,
                          mb: 1,
                          mx: 1,
                        }}
                      >
                        ID : {props.orderId}
                      </Typography>
                      <IconButton
                        onClick={props.handlCloseAcceptOrderModal}
                        aria-label="close"
                        sx={{ position: "absolute", top: -8, right: -25 }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    mt={0}
                    mb={2}
                    sx={{
                      background:
                        "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
                    }}
                  >
                    <Grid
                      item
                      xs={8}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", md: "18px" },
                          fontWeight: 600,
                          mb: 2,
                          letterSpacing: "0.42em",
                          color: "red.tertiary",
                        }}
                      >
                        YBITES DELIVERY
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: 400, mb: 1 }}
                      >
                        {props.createdTime}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", sm: "18px" },
                          fontWeight: 600,
                        }}
                      >
                        {props.customerName}
                        {/* {props.customerNumber} */}
                      </Typography>

                      {/* <Button
                        variant="outlined"
                        startIcon={<PhoneForwardedIcon />}
                      >
                        Call
                      </Button> */}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 300,
                          color: "black.tertiary",
                        }}
                      >
                        Placed : {props.createdTime}
                      </Typography>
                    </Grid>
                  </Grid>

                


                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 300,
                          color: "black.tertiary",
                        }}
                      >
                        {/* 1st order Lokmanya nagar : Thane West (3 kms, 15 min
                        away) */}
                        {`${props.customerAddress.completeAddress}, 
                        ${props.customerAddress.landmark}, 
                        ${props.customerAddress.city}, 
                        ${props.customerAddress.state}`}
                      </Typography>
                    </Grid>
                  </Grid>



                  {props.orderItems.map((item, index) => (
                    <>
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={8}>
                          <Box sx={{ display: "flex" }}>
                            <img
                              src="/images/orderItemImg.svg"
                              alt="orderItemImg"
                            />
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                            >
                              {item.menuItemTitle}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            {item.customizationItemTitles.map(
                              (customizationItemTitles, index) =>
                                `${customizationItemTitles}, `
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            Add Ons -
                            {item.addOnItemTitles.map(
                              (addOnItemTitles, index) =>
                                ` ${addOnItemTitles}, `
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "black.tertiary",
                            }}
                          >
                            {item.optionItemTitles.map(
                              (optionItemTitles, index) =>
                                `${optionItemTitles}, `
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Typography
                            sx={{ fontSize: "15px", fontWeight: 700 }}
                          >
                            ₹{Math.round(item.totalPrice * 100) / 100}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}

                  <Grid container spacing={2} mt={0}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,

                          color: "black.tertiary",
                          letterSpacing: "0.23em",
                          widh: "100%",
                        }}
                      >
                        {/* Instr- We want 2 different parcel of veg and non-veg */}
                        Instr - {props.note}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,

                          color: "black.primary",
                        }}
                      >
                        Total Price
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        {`₹${Math.round(props.totalPrice * 100) / 100}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={10}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,

                          color: "black.tertiary",
                        }}
                      >
                        Taxes
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {`₹${Math.round(props.totalTax * 100) / 100}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                        Total Bill
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            mx: 1,
                            color: "black.primary",
                            border: "1px solid rgba(0, 153, 80, 0.4)",
                            borderRadius: "2px",
                            letterSpacing: "0.265em",
                            px: 1,
                          }}
                        >
                          PAID
                        </Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                          ₹
                          {Math.round(
                            (props.totalTax + props.totalPrice) * 100
                          ) / 100}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ backgroundColor: "whisper.main", mt: 1, mb: -1 }}
                  >
                    <Grid item xs={4}>
                      <Button
                        onClick={handlOpenRejectOrderModal}
                        sx={rejectBtn}
                        id="sign-in-button"
                        variant="contained"
                        size="large"
                      >
                        Reject
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        onClick={acceptOrder}
                        sx={acceptBtn}
                        id="sign-in-button"
                        variant="contained"
                        size="large"
                      >
                        Accept Order
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <ModalForPreprationTime
        openModalForPreprationTime={openModalForPreprationTime}
        handlCloseModalForPreprationTime={handlCloseModalForPreprationTime}
        handlCloseAcceptOrderModal={props.handlCloseAcceptOrderModal}
        increaseTime={increaseTime}
        decreaseTime={decreaseTime}
        counter={counter}
        preparationTime={preparationTime}
        orderId={props.orderId}
        newOrdersDetails={props.newOrdersDetails}
        page={props.page}
        id={props.id}
      />

      <ModalForRejectNewOrder
        openRejectOrderModal={openRejectOrderModal}
        handlCloseRejectOrderModal={handlCloseRejectOrderModal}
        id={props.id}
        newOrdersDetails={props.newOrdersDetails}
        page={props.page}
      />
    </>
  );
};

export default ModalForAcceptNewOrder;
