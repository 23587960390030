import { Gradient } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";

const palette = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#F75B28",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // secondary: {
    //   // light: "#0066ff",
    //   // dark: will be calculated from palette.secondary.main,
    //   // contrastText: "#ffcc00",
    // },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    // custom: {
    //   light: "#ffa726",
    //   main: "#f57c00",
    //   dark: "#ef6c00",
    //   contrastText: "rgba(0, 0, 0, 0.87)",
    // },
    drawerColor: {
      main: "#0D1E37",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main:'#000000',
      secondary: "#484848",
      primary:'rgba(0, 0, 0, 0.76)',
      tertiary:'rgba(0, 0, 0, 0.46)',
      helpCenter:'(2, 2, 2, 0.86)',
    },
    bgColor: {
      main: "#FFF6F6",
      secondary:'#FFCACA'
    },
    bgWhite: {
      main: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.23)",
      tertiary:'rgba(255, 255, 255, 0.53)',
      subAccordian:'rgba(255, 155, 37, 0.42)',
    },
    blue: {
      main: "#0085FF",
      primary: "#397DFF",
      secondary:'rgba(57, 125, 255, 0.8)',
      secondaryTimeLineBtn:'rgba(57, 125, 255, 0.8)',
      tertiary: "#0369FF",
      light:'#1145FF',
    },
    yellow: {
      main: "#A19B00",
      secondary: "#FF9415",
      tertiary:'#0ED60E',
    },
    purple: {
      main: "#5D59FA",
      secondary:'rgba(164, 164, 164, 0.23)',
      tertiary:'rgba(255, 203, 192, 0.76)',
    },
    green: {
      main: "#00710B",
      primary:'#00C62B',
      secondary: "rgba(13, 30, 55, 0.67)",
      tertiary: "#B4B4B4",
      light:'#D9D9D9',
      mintGreen:'#0DC18B',
      midGreen:'#45B145',
      kellyGreen:'#26BF00',
      treeGreen:"#038400",
    },
    grey: {
      main: "#7B7B7B",
      primary: "#8B8B8B",
      secondary: "#F5F5F5",
      tertiary: "#393939",
      light:'rgba(97, 97, 97, 0.11)',
      light2:'rgba(97, 97, 97, 0.29)',
      cloudyGrey:'#686868',
      midGrey:'#616161',
      lightGrey:'#DFDFDF',
    },
pink:{
  brinkPink: '#F26C6C',
  darkCarnationPink:'#D400A6',
  dawnPink:'#EAEAEA',
},
    whisper: {
      main: "#ECECEC",
    },
    red: {
      secondary: "#FFD89D",
      tertiary:"rgba(189, 50, 20, 0.76)",
      primary:'#008916',
      light:'rgba(255, 0, 0, 0.45)',
      beanRed:'#FD5656',
      light2:'#FF9B25',
      rustRedLight:'rgba(183, 33, 0, 0.76)',
    },

    jackoBean: {
      primary: "rgba(62, 54, 45, 0.63)",
    },
    maroon: {
      secondary: "#670000",
    },
    rosyBrown: {
      main: "#B3AF8F",
      primary: "#E0806B",
      secondary: "#BF9393",
      tertiary: "#6EBD49",
    },
    textFieldColor: {
      main: "rgba(131, 131, 131, 0.21)",
    },
    addMenuItemColor: {
      light: "rgba(217, 217, 217, 0.44)",
      light2: "rgba(210, 210, 210, 0.33)",
      light3:'rgba(248, 215, 177, 0.89)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default palette;
