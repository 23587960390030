import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Box,
  Button,
  Autocomplete,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreateOptionsModal from "./modal/CreateOptionsModal";
import AddItemCategory from "./AddItemCategory";
import { useNavigate } from "react-router-dom";
import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";
import DragsAndDrop from "../../../reusableComponent/imageUpload/DragsAndDrop";
import Customization from "./customizationPage/Customization";

import Options from "./optionsPage/Options";

const AddMenuItems = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  // const [changeTab, setChangeTab] = useState(true);

  // const onBtnClick = () => {
  //   setChangeTab(!changeTab);
  // };

  const [customization, setCustomization] = useState([]);
  const [options, setOptions] = useState([]);
  const [openCreateOptionModal, setOpenCreateOptionModal] = useState(false);
  const handleOpenCreateOptionModal = () => setOpenCreateOptionModal(true);
  const handleCloseCreateOptionModal = () => setOpenCreateOptionModal(false);

  const [addOns, setAddOns] = useState([]);
  const [addOnIds, setAddOnIds] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuSubCategories, setSubMenuCategories] = useState([]);

  const [categories, setCategories] = useState([]);
  const [cuisine, setCuisine] = useState([]);
  const [image, setImage] = useState("");
  const [menuItemData, setMenuItemData] = useState({
    discount: null,
    unitPrice: null,
    price: null,
  });
  // /category

  const getAllMenuCategory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/menu-category?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMenuCategories(res.data.menuCategory);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllCategory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/owner/category`);
      setCategories(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllCuisine = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/api/v1/owner/cuisine`);
      setCuisine(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllAddOns = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/addon?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setAddOns(res.data.addOn);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCuisine();
    getAllCategory();
    getAllAddOns();
    getAllMenuCategory();
  }, []);

  const onChangeHandlerMenuCategories = (event) => {
    setMenuItemData({
      ...menuItemData,
      [event.target.name]: event.target.value._id,
      menuSubCategory: null,
    });

    setSubMenuCategories(event.target.value.menuSubCategories);
  };
  const onChangeHandler = (event) => {
    if (event.target.name === "discount") {
      let price =
        menuItemData.unitPrice -
        (event.target.value * menuItemData.unitPrice) / 100;
      setMenuItemData({
        ...menuItemData,
        [event.target.name]: event.target.value,
        price: Math.floor(price),
      });
    } else if (event.target.name === "unitPrice") {
      let price =
        event.target.value - (menuItemData.discount * event.target.value) / 100;
      setMenuItemData({
        ...menuItemData,
        [event.target.name]: event.target.value,
        price: Math.floor(price),
      });
    } else {
      setMenuItemData({
        ...menuItemData,
        [event.target.name]: event.target.value,
      });
    }
    // console.log(menuItemData);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (menuItemData.price === 0) {
        snackbar("Price can't zero", "error");
        setIsLoading(false);
        return;
      }

      if (!menuItemData.hasOwnProperty("menuItemType")) {
        snackbar("Please select Food Type", "error");
        setIsLoading(false);
        return;
      } else if (!menuItemData.hasOwnProperty("menuCategory")) {
        snackbar("Please select Menu Category", "error");
        setIsLoading(false);
        return;
      }

      const res = await axios.post(
        `/api/v1/owner/menuItem?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        {
          ...menuItemData,
          addOnIds,
          customization,
          options,
        }
      );
      if (image) {
        let formData = new FormData();
        formData.append("image", image);
        const res2 = await axios.patch(
          `/api/v1/owner/menuItem?menuItemId=${res.data.menuItem._id}`,
          formData
        );
      }
      snackbar(res.data.message, "success");
      setIsLoading(false);
      setMenuItemData({});
      // getAllMenuCategory();
      getAllMenuCategory();

      navigate("/menu");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const textFieldStyle = {
    width: { xs: "100%", sm: "80%" },
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  const VariationsBtnStyle = {
    textTransform: "capitalize",
    color: "blue.primary",
    borderColor: "blue.primary",
    fontWeight: 600,
    fontSize: "18px",
    // backgroundColor: "blue.primary",
    "&:hover": {
      // backgroundColor: "blue.primary",
    },
  };

  const active = {
    width: "auto",
    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    paddingY: 1,
    paddingX: 2,
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,
    textDecoration: "none",
    borderRadius: "6px",
    minWidth: "150px",
  };

  return (
    <>
      <Grid container sx={{ backgroundColor: "white.main" }}>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          p={2}
          sx={{ backgroundColor: "addMenuItemColor.light", width: "100%" }}
        >
          <ArrowBackIosNewIcon onClick={() => navigate(-1)} sx={{cursor:"pointer"}} />
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "27px",
              color: "rgba(13, 30, 55, 0.67)",
            }}
          >
            Add Item
          </Typography>
        </Stack>

        <Box component="form" onSubmit={submitHandler} sx={{ width: "100%" }}>
          <Grid
            container
            sx={{
              backgroundColor: "yellow.secondary",
              py: 2,
              borderRadius: "11px",
              px: 2,
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, fontSize: "16px", color: "white.main" }}
            >
              Basic Details
            </Typography>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
              px: 3,
            }}
          >
            <Grid
              item
              xs={8}
              sm={6}
              md={5}
              sx={{ mt: 2 }}
              //  onClick={handleOpen}
            >
              <Box>
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  aspect={2 / 1}
                  uploadFile={(x) => {
                    setImage(x);
                  }}
                />
              </Box>
            </Grid>

            {/* <AddItemImgModal open={open} handleClose={handleClose} /> */}
          </Grid>

          <Grid container spacing={2} px={3}>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Name of the Item*
              </Typography>

              <TextField
                name="title"
                size="small"
                required
                placeholder="Name"
                value={menuItemData.title}
                onChange={onChangeHandler}
                sx={textFieldStyle}
              />

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Food Type*
              </Typography>

              <FormControl size="small" required sx={textFieldStyle}>
                <Select
                  labelId="menuItemType"
                  id="menuItemType"
                  required
                  name="menuItemType"
                  onChange={onChangeHandler}
                  defaultValue="choose"
                >
                  <MenuItem disabled value="choose">
                    Choose Option
                  </MenuItem>
                  <MenuItem value="Veg">
                    <Box
                      sx={{ mx: 2 }}
                      component="img"
                      src="/images/veg.svg"
                    ></Box>{" "}
                    Veg
                  </MenuItem>
                  <MenuItem value="Non-Veg">
                    {" "}
                    <Box
                      sx={{ mx: 2 }}
                      component="img"
                      src="/images/nonVeg.svg"
                    ></Box>{" "}
                    Non-Veg
                  </MenuItem>
                  <MenuItem value="Jain">
                    {" "}
                    <Box
                      sx={{ mx: 2 }}
                      component="img"
                      src="/images/egg.svg"
                    ></Box>{" "}
                    Jain
                  </MenuItem>
                </Select>
              </FormControl>

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Cuisine
              </Typography>

              <FormControl size="small" sx={textFieldStyle}>
                <Select
                  labelId="select-label"
                  id="cuisine"
                  name="cuisine"
                  onChange={onChangeHandler}
                  defaultValue="null"
                >
                  <MenuItem value={null}>Choose Option</MenuItem>
                  {cuisine.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main", mt: 2 }}
                  >
                    Menu Category
                  </Typography>

                  <FormControl size="small" required sx={textFieldStyle}>
                    <Select
                      labelId="select-label"
                      required
                      id="menuCategory"
                      name="menuCategory"
                      onChange={onChangeHandlerMenuCategories}
                      defaultValue="choose"
                    >
                      <MenuItem disabled value="choose">
                        Choose Option
                      </MenuItem>
                      {menuCategories.map((item) => (
                        <MenuItem key={item._id} value={item}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main", mt: 2 }}
                  >
                    Food Category
                  </Typography>

                  <FormControl size="small" sx={textFieldStyle}>
                    <Select
                      labelId="select-label"
                      name="category"
                      onChange={onChangeHandler}
                      defaultValue="null"
                    >
                      <MenuItem value={null}>Choose Option</MenuItem>
                      {categories.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main", mt: 2 }}
                  >
                    Sub Category of the Item
                  </Typography>

                  <FormControl size="small" required sx={textFieldStyle}>
                    <Select
                      labelId="select-label"
                      id="menuSubCategory"
                      name="menuSubCategory"
                      onChange={onChangeHandler}
                      defaultValue="null"
                    >
                      <MenuItem value={null}>Choose Option</MenuItem>
                      {menuSubCategories.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main", mt: 2 }}
                  >
                    Preparation Time*
                  </Typography>

                  <TextField
                    size="small"
                    name="preparationTime"
                    type="number"
                    required
                    inputProps={{ min: 1 }}
                    value={menuItemData.preparationTime}
                    onChange={onChangeHandler}
                    placeholder="Preparation Time"
                    sx={textFieldStyle}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main", mt: 2 }}
              >
                Item Description
              </Typography>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                multiline
                rows={3}
                variant="outlined"
                name="description"
                // required
                value={menuItemData.description}
                onChange={onChangeHandler}
                placeholder="Aalo bharit + 2Roti + Curd + Picked  A cream pasta prepared in while sauce "
                sx={{
                  // width: { xs: "100%", sm: "80%" },
                  borderRadius: "10px",
                  background: "rgba(255, 255, 255, 0.31)",
                  color: "rgba(255, 255, 255, 0.96)",
                  border: "1px solid black",
                  "& fieldset": {
                    border: "none",
                    color: "rgba(255, 255, 255, 0.96);",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              backgroundColor: "yellow.secondary",
              py: 2,
              borderRadius: "11px",
              px: 2,
              mt: 5,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, fontSize: "16px", color: "white.main" }}
            >
              Pricing
            </Typography>
          </Grid>

          <Grid container spacing={2} px={3}>
            <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Unit Price*
              </Typography>

              <TextField
                size="small"
                placeholder="Unit Price"
                name="unitPrice"
                type="number"
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ min: 0 }}
                value={menuItemData.unitPrice}
                onChange={onChangeHandler}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main", mt: 2 }}
                  >
                    Discount
                  </Typography>

                  <TextField
                    size="small"
                    placeholder="Discount"
                    name="discount"
                    type="number"
                    variant="outlined"
                    max="100"
                    required
                    value={menuItemData.discount}
                    onChange={onChangeHandler}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          %{/* <CurrencyRupeeIcon sx={{ fontSize: 15 }} /> */}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 0 }}
                    sx={textFieldStyle}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main", mt: 2 }}
                  >
                    Final Price
                  </Typography>

                  <TextField
                    size="small"
                    placeholder="Final Price"
                    name="price"
                    type="number"
                    disabled
                    variant="outlined"
                    value={menuItemData.price}
                    // onChange={onChangeHandler}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={textFieldStyle}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={2} px={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "rgba(30, 30, 30, 0.94)", mt: 2 }}
              >
                Final Price
              </Typography>
              <Typography
                variant="h6"
                fontWeight={600}
                fontSize="40px"
                sx={{ color: "#555555" }}
              >
                ₹{menuItemData.price}
              </Typography>

              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Price + Packaging + ybites serviceCharge
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              sx={{ backgroundColor: "#F8E8D0", mt: 2 }}
            >
              <Typography
                variant="h6"
                fontWeight={400}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Please ensure the item price matches the price in your menu to
                avoid rejection of changes
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              backgroundColor: "yellow.secondary",
              py: 2,
              borderRadius: "11px",
              px: 2,
              mt: 5,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, fontSize: "16px", color: "white.main" }}
            >
              Variations
            </Typography>
          </Grid>

          <Grid container spacing={2} px={3}>
            <Grid item xs={12} mt={3}>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  py: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 700, fontSize: "16px", color: "" }}
                >
                  Add-ons
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/menu/addOn")}
                  sx={active}
                >
                  Add
                </Button>
              </Grid>
              <Grid
                //
                item
                xs={12}
                sx={{
                  display: "flex",

                  justifyContent: "center",
                }}
              >
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  sx={{ width: "100%", backgroundColor: "red.secondary" }}
                  options={addOns}
                  getOptionLabel={(option) => `${option.title}`}
                  onChange={(event, value) => {
                    // console.log(value);
                    const array = [];
                    for (let index = 0; index < value.length; index++) {
                      const element = value[index];
                      array.push(element._id);
                    }
                    setAddOnIds(array);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Add-ons" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <Customization
                customization={customization}
                setCustomization={setCustomization}
              />
            </Grid>
          </Grid>
   
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Options options={options} setOptions={setOptions} />
            </Grid>

            {/* <CreateOptionsModal
              openCreateOptionModal={openCreateOptionModal}
              handleCloseCreateOptionModal={handleCloseCreateOptionModal}
            /> */}
          </Grid>

          <Grid container spacing={2} px={3} mt={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                mb: 2,
                justifyContent: { xs: "center", md: "end" },
                alignItems: "end",
              }}
            >
              <Button variant="outlined" type="submit" sx={active}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default AddMenuItems;
