import React, { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { imgUrl } from "../../../../url";
import StarIcon from "@mui/icons-material/Star";
import { useContext } from "react";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { useEffect } from "react";
import RatingReview from "./modal/RatingReview";

const ImageSlider = (props) => {
  const matches = useMediaQuery("(min-width:1340px)");
  const matchesDown = useMediaQuery("(min-width:900px)");

  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [customerFeedbackList, setCustomerFeedbackList] = useState([]);
  const [customerFeedback, setCustomerFeedback] = useState("");

  const [customerNotifications, setCustomerNotifications] = useState(false);

  const handleOpenCustomerNotificationModal = () =>
    setCustomerNotifications(true);
  const handleCloseCustomerNotificationModal = () =>
    setCustomerNotifications(false);

  const [imageNum, setImageNum] = useState(1);

  const sliderImages = [
    {
      url: props.images
        ? `${imgUrl}/${props.images[0]}`
        : "/images/outletImages.png",
    },
    {
      url: props.images
        ? `${imgUrl}/${props.images[1]}`
        : "/images/outletImages.png",
    },

    {
      url: props.images
        ? `${imgUrl}/${props.images[2]}`
        : "/images/outletImages.png",
    },
  ];

  // console.log("images is ", props.images);
  return (
    <>
      <div>
        <Typography
          sx={{
            color: "green.secondary",
            fontSize: "18px",
            fontWeight: 600,
            mt: 2,
            position: "relative",
          }}
        >
          Profile
        </Typography>

        <Box
          onClick={() => {
            handleOpenCustomerNotificationModal();
          }}
          sx={{
            backgroundColor: "green.treeGreen",
            p: 1,
            width: "max-content",
            mb: 1,
            borderRadius: "8px",
            color: "white.main",
            display: "flex",
            cursor: "pointer",
          }}
        >
          <StarIcon color="yellow" /> {Math.round(props.avgRating * 100) / 100}
        </Box>

        <SimpleImageSlider
          width={matches ? 500 : matchesDown ? 590 : 300}
          height={matches ? 350 : matchesDown ? 440 : 200}
          showBullets={true}
          autoPlay={true}
          onStartSlide={(index, length) => {
            setImageNum(index);
          }}
          images={sliderImages}
          autoPlayDelay={3}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} display={'flex'} justifyContent={"center"}>
            <Button
              onClick={() => props.handleEditImage()}
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                color: "white.main",
                
              }}
            >
              Change Restaurant Images
            </Button>
          </Grid>
        </Grid>
      </div>

      <RatingReview
        customerNotifications={customerNotifications}
        handleCloseCustomerNotificationModal={
          handleCloseCustomerNotificationModal
        }
      />
    </>
  );
};

export default ImageSlider;
