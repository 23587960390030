import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Heading from "./legalTabAndHeading/Heading";
import Tabs from "./legalTabAndHeading/Tabs";



const Legal = () => {
  return (
    <>
    <Tabs />
    <Container fixed mx={3}>
      <Heading />
      
      <Outlet />
    </Container>
  </>
  )
}

export default Legal