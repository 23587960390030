import React, { useContext, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Divider, Grid, Modal, TextField } from "@mui/material";

import { AdminDataContext } from "./../../useContext/AdminContext";
import axios from "axios";
import { Box } from "@mui/system";
const MenuCategory = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const handleChangeMenuCategory = (event) => {
    setMenuCategoryData({
      ...menuCategoryData,
      [event.target.name]: event.target.value,
    }); 
  };
  //
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuCategoryData, setMenuCategoryData] = useState({});
  const [createMenuCategory, setCreateMenuCategory] = useState(false);
  const handleOpen = () => setCreateMenuCategory(true);
  const handleClose = () => setCreateMenuCategory(false);
  const [menuSubCategoryData, setMenuSubCategoryData] = useState({});

  const [menuCategoryId, setMenuCategoryId] = useState("");
  const [createMenuSubCategory, setCreateMenuSubCategory] = useState(false);
  const handleOpenItem = (id) => {
    setCreateMenuSubCategory(true);
    setMenuCategoryId(id);
  };
  const handleCloseItem = () => setCreateMenuSubCategory(false);
  const handleChangeMenuSubCategoryItem = (event) => {
    setMenuSubCategoryData({
      ...menuSubCategoryData,
      [event.target.name]: event.target.value,
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,

    bgcolor: "background.paper",  
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const createMenuCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/menu-category?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        {
          ...menuCategoryData,
        }
      );
      setMenuCategoryData({});

      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllMenuCategory();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const createMenuSubCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/sub-category?menuCategoryId=${menuCategoryId}`,
        {
          ...menuSubCategoryData,
        }
      );
      setMenuSubCategoryData({});
      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleCloseItem();
      getAllMenuCategory();
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const getAllMenuCategory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/menu-category?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMenuCategories(res.data.menuCategory);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllMenuCategory();
  }, []);

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Add Category
      </Button>
      {menuCategories.map((item, index) => (
        <Accordion key={item._id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}a-content`}
            id={`panel${index + 1}a-header`}
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.menuSubCategories.length === 0 ? (
              <Button
                onClick={() => handleOpenItem(item._id)}
                variant="contained"
              >
                Create Sub Categories
              </Button>
            ) : (
              <>
                {item.menuSubCategories.map((menuSubCategories) => (
                  <Box key={menuSubCategories._id}>
                    <Typography>{menuSubCategories.title}</Typography>
                    <Divider />
                  </Box>
                ))}
                <Button
                  onClick={() => handleOpenItem(item._id)}
                  variant="contained"
                >
                  Create Sub Categories
                </Button>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}

      <Modal
        open={createMenuCategory}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              sx={12}
              component="form"
              onSubmit={createMenuCategoryHandler}
            >
              <TextField
                id="outlined-basic"
                fullWidth
                label="Title"
                name="title"
                value={menuCategoryData.title}
                required
                variant="outlined"
                onChange={handleChangeMenuCategory}
              />

              <Button variant="contained" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={createMenuSubCategory}
        onClose={handleCloseItem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              sx={12}
              component="form"
              onSubmit={createMenuSubCategoryHandler}
            >
              <TextField
                id="outlined-basic"
                fullWidth
                label="Title"
                name="title"
                value={menuSubCategoryData.title}
                required
                variant="outlined"
                onChange={handleChangeMenuSubCategoryItem}
              />

              <Button variant="contained" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default MenuCategory;
