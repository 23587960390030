import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
  Button,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import React from "react";
import { useNavigate } from "react-router-dom";

const LogoutModalHandler = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const navigate = useNavigate();

  const logoutHandler = () => {
    props.handleCloseLogoutModal();
    navigate("/logout");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
  };
  const cancelBtn = {
    width: "auto",

    backgroundColor: "grey.light2",

    color: "green.secondary",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: 1,
    textDecoration: "none",
    borderRadius: "11px",
    px: 5,
    "&:hover": {
      backgroundColor: "grey.light2",
      color: "green.secondary",
    },
  };

  const logoutBtn = {
    width: "auto",
    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: 1,
    textDecoration: "none",
    borderRadius: "11px",
    px: 5,
  };
  return (
    <>
      <Modal
        open={props.logoutModal}
        onClose={props.handleCloseLogoutModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "22px",

                    color: "green.secondary",
                    justifySelf: "start",
                  }}
                >
                  Are you sure you want to logout ?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",

                    color: "green.secondary",
                    justifySelf: "start",
                  }}
                >
                  For login out YBITES partner
                </Typography>
              </Box>

              <IconButton
                onClick={props.handleCloseLogoutModal}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  onClick={props.handleCloseLogoutModal}
                  sx={cancelBtn}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={logoutHandler}
                  sx={logoutBtn}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LogoutModalHandler;
