import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import Tabs from "./tabsHeadings/Tabs";
import TabHeading from "./tabsHeadings/TabHeading";

const ManageOutlet = () => {
  return (
    <>
    <Tabs />
    <Container fixed mx={3}>
      <TabHeading />
      
      <Outlet />
    </Container>
  </>
  )
}   

export default ManageOutlet