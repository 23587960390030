import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import TicketCreationDetailModal from "../modal/TicketCreationDetailModal";
import ReviewModal from "../modal/ReviewModal";

const TicketHistoryCard = (props) => {
  const [openTicketCreationDetailModal, setOpenTicketCreationDetailModal] =
    useState(false);
  const [data, setData] = useState({});
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [ticketId, setTicketId] = useState("");
const handleOpenReviewModal = (id) => {
  setOpenReviewModal(true);
  setTicketId(id);
}
const handleCloseReviewModal = () => {
  setOpenReviewModal(false);
  
}


  const handleOpenTicketCreationDetailModal = (
    title,
    description,
    solution
  ) => {
    setOpenTicketCreationDetailModal(true);
    setData({
      title: title,
      description: description,
      solution: solution,
    });
  };

  const handleCloseTicketCreationDetailModal = () =>
    setOpenTicketCreationDetailModal(false);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} p={3} mt={3}>
        <Card
          elevation={0}
          sx={{
            borderRadius: "10px",
          }}
        >
          <CardContent
            elevation={10}
            sx={{
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={2} sx={{ cursor: "pointer" }}>
              <Grid
                item
                onClick={() =>
                  handleOpenTicketCreationDetailModal(
                    props.ticketHistory.title,
                    props.ticketHistory.description,
                    props.ticketHistory.solution
                  )
                }
                xs={4}
                sx={{
                  backgroundColor: "green.light",
                  borderRadius: "10px 0px 0px 10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black.primary",
                    mb: 4,
                    mt: 3,
                  }}
                >
                  {props.ticketHistory.ticketId}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  backgroundColor: "grey.light",
                  borderRadius: "0px 10px 10px 0px",
                  pb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "black.primary",
                  }}
                >
                  {props.ticketHistory.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    color: "black.primary",
                  }}
                >
                  {props.ticketHistory.description}
                </Typography>

                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      px:2,
                      pb:1,
                      mt:2
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenReviewModal(props.ticketHistory._id)}
                    
                      sx={{
                        textTransform: "capitalize",
                        color: "white.main",
                        fontWeight: 500,
                        fontSize: "18px",
                        background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                        border: "none",
                       
                        "&:hover": {
                          border: "none",
                        },
                      }}
                    >
                      Review
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <TicketCreationDetailModal
        openTicketCreationDetailModal={openTicketCreationDetailModal}
        handleCloseTicketCreationDetailModal={
          handleCloseTicketCreationDetailModal
        }
        data={data}
      />

      <ReviewModal 
      handleCloseReviewModal = {handleCloseReviewModal}
      openReviewModal = {openReviewModal}
      ticketId = {ticketId}
      />
    </>
  );
};

export default TicketHistoryCard;
