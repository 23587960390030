import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ManagerList from "./ManagerList";
import Divider from "@mui/material/Divider";
import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";

const AddManagers = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    gender: "",
    phone: "",
  });
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [managerList, setMangaerList] = useState([]);
  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
   };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      if (data.gender === "") {
        snackbar("Please select gender", "success");
      } else {
        const res = await axios.post(`/api/v1/owner/create/manager`, {
          ...data,
          phone: `+91${data.phone}`,
          restaurant: localStorage.getItem("restaurantId"),  
        });
        setData({
          name: "",
          email: "",
          gender: "",
          phone: "",
        });
        snackbar(res.data.message, "success");
      }
      getAllManager();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  const getAllManager = async () => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/manager?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMangaerList(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {  
    getAllManager();
  }, []);

  const textFieldStyle = {
    width: "90%",
    borderRadius: "10px",
    backgroundColor: "addMenuItemColor.light",
    color: "rgba(255, 255, 255, 0.96)",

    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  const textFieldBox = {
    width: "100%",
    mt: 2,
  };

  const textHeading = {
    fontWeight: 600,
    fontSize: "18px",

    color: "black.primary",
    justifySelf: "start",
  };

  const submitBtn = {
    width: "auto",
    // height: "50px",
    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white",

    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: { xs: 2, md: 5 },
    textDecoration: "none",
    borderRadius: "11px",
  };

  return (
    <Container fixed sx={{ my: 1 }}>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "grey.midGrey",
          p: 2,
          borderRadius: "16px",
          mt: 3,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            fontSize: "22px",
            color: "white.main",
            justifySelf: "start",
          }}
        >
          Add Managers
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        component={"form"}
        onSubmit={onSubmitHandler}
        mt={1}
      >
<Grid item xs={12} sm={12} md={6} 
order={{xs:2, md:1}}
>
          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Name
            </Typography>

            <TextField
              size="small"
              name="name"
              type="text"
              required
              placeholder="Name"
              sx={textFieldStyle}
              onChange={onChangeHandler}
              value={data.name}
            />
          </Box>

          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Mobile no.
            </Typography>

            <TextField
              size="small"
              name="phone"
              type="text"
              required
              placeholder="Mobile Number"
              sx={textFieldStyle}
              onChange={onChangeHandler}
              value={data.phone}
              inputProps={{
                pattern: "[0-9]{10}",
                maxLength: 10,
                autoComplete: "tel",
              }}
            />
          </Box>

          <Box sx={textFieldBox}>
            <Typography variant="body1" sx={textHeading}>
              Email ID
            </Typography>

            <TextField
              size="small"
              name="email"
              type="email"
              required
              placeholder="Email"
              sx={textFieldStyle}
              onChange={onChangeHandler}
              value={data.email}
            />
          </Box>

          <Box sx={textFieldBox}>
            <FormControl required>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={textHeading}
              >
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                onChange={onChangeHandler}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button type="submit" variant="contained" sx={submitBtn}>
              Add Manager
            </Button>
          </Box>  
        </Grid>
        {/* <Divider orientation="vertical" flexItem sx={{ my: 2 }}/> */}
        <Grid item xs={12} sm={12} md={1} order={{md:2}}>
    <Divider orientation="vertical" flexItem sx={{ height: '100%',  }} />
  </Grid>
        <Grid item xs={12} sm={12} md={5}
         order={{xs:1, md:3}}
         >
          <ManagerList getAllManager = {getAllManager} managerList = {managerList} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddManagers;
