import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import { time } from "../../ExtractTime";

const RejectedItemsCard = (props) => {
  let orderTime = "";
  orderTime = time(props.item.createdAt);
  const findDate = (str) => {
    const date = new Date(str);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };
const date = findDate(props.item.createdAt);

 const handOverTime = (createdAt, updatedAt) => {

const createdDate = new Date(createdAt);
const updatedDate = new Date(updatedAt);
const timeDifferenceInMilliseconds = updatedDate - createdDate;

const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));

return timeDifferenceInMinutes; 

 }
 const handOverTIme = handOverTime(props.item.createdAt, props.item.updatedAt)
  return (
    <>
      <Grid item xs={12} sm={6} md={4} p={3} mt={3} >
        <Card elevation={0} sx={{ boxShadow: 5,   borderRadius: "15px", }}>
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
              minHeight:200,
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sx={{
                  background: 'linear-gradient(90deg, #FF8181 28.15%, rgba(255, 107, 107, 0) 94.07%)',

                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "white.main",
                  }}
                >
                  Rejected
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 1,
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                  ID : {props.item.orderId}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={0}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: 400,
                    color: "black.primary",
                  }}
                >
                  {orderTime} | {date}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "black.primary",
                    }}
                  >
                    {props.item.customer.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={0}>
              <Grid item xs={9}>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: 400,
                    color: "black.primary",
                  }}
                >
                  {props.item.orderItems.map((item, index) => (
                    <>{`${item.quantity} X ${item.menuItemTitle}, `}</>
                  ))}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "black.primary",
                    }}
                  >
                  
                    
                    ₹{Math.round((props.item.totalTax + props.item.totalPrice)*100)/100}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default RejectedItemsCard