import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import ReservationTabs from './reservationTabs/ReservationTabs'
import ReservationTabsHeadings from './reservationTabs/ReservationTabsHeadings'

const Reservation = () => {
  return (
    <>

<ReservationTabs />
<Container fixed mx={3}>
<ReservationTabsHeadings />
<Outlet />
</Container>

        
    </>
  )
}

export default Reservation