import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EditIcon from "@mui/icons-material/Edit";
import EditManagerModal from "./modal/EditManagerModal";
import ManagerDeleteConfirmation from "./modal/ManagerDeleteConfirmation";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
const ContactDetails = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [allManagers, setAllManagers] = useState([11, 2, 3, 4, 5, 6]);
  const [openEditManagerModal, setOpenEditManagerModal] = useState(false);
  const [managerId, setManagerId] = useState("");
  const [ownerDetails, setOwnerDetails] = useState({});
  const [aboutOutlet, setAboutOutlet] = useState({});
  const [data, setData] = useState({});
  const [managerList, setMangaerList] = useState([]);

  const [openDeleteManagerConfirmation, setOpenDeleteManagerConfirmation] =
    useState(false);

  const handleOpenDeleteManagerConfirmation = (id) => {
    setManagerId(id);
    setOpenDeleteManagerConfirmation(true);
  };
  const handleCloseDeleteManagerConfirmation = () => {
    setOpenDeleteManagerConfirmation(false);
  };

  const handleOpenEditManager = (id) => {
    setManagerId(id);
    setOpenEditManagerModal(true);
  };
  const handleCloseEditManager = () => {
    setOpenEditManagerModal(false);
  };

  const getOwnerDetails = async () => {
    try {
      setIsLoading(true);

      const res1 = await axios.get(`/api/v1/owner`);

      setOwnerDetails(res1.data.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
 
  const getAboutOutLet = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/details?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );

      setAboutOutlet(res.data.data);
      setData({
        ...data,
        contactPersonName: res.data.data.contactPersonName,
        contactPersonPhone: res.data.data.contactPersonPhone,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllManager = async () => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/manager?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMangaerList(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAboutOutLet();
    getOwnerDetails();
    getAllManager();
  }, []);

  const active = {
    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    padding: 2,
    textTransform: "capitalize",
    // fontSize: { xs: "13px", sm: "15px", md: "20px" },
    fontSize: "20px",
    fontWeight: 600,
    mt: 1,
    textDecoration: "none",
    borderRadius: "5px",
    overflow: "hidden",
    mb: 2,
  };

  const boxStyling = {
    p: 2,
    height: "60vh",
    overflow: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };

  return (
    <Grid
      container
      spacing={2}
      mt={1}
      sx={{ display: "flex", justifyContent: "space-evenly" }}
    >
      <Grid item xs={12} sm={12} md={5}>
        <Typography sx={active}>Owner</Typography>
        <Grid container spacing={2} pt={3}>
          <Grid
            item
            xs={12}
            mt={-4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <List sx={{ minWidth: "80%" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PermIdentityIcon sx={{ fontSize: "30px" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={ownerDetails.name}
                  primaryTypographyProps={{
                    color: "black.primary",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  secondary={
                    <>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        {ownerDetails.phone}
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        {ownerDetails.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Grid>

          {/* ))} */}
        </Grid>

        <Typography sx={active}>Contact Person</Typography>
        <Grid container spacing={2} pt={3}>
          <Grid
            item
            xs={12}
            mt={-4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <List sx={{ minWidth: "80%" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PermIdentityIcon sx={{ fontSize: "30px" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  // primary={aboutOutlet.contactPersonName}

                  primary={
                    <Typography onClick={() => handleOpenEditManager()}>
                      {aboutOutlet.contactPersonName} <EditIcon />{" "}
                    </Typography>
                  }
                  primaryTypographyProps={{
                    color: "black.primary",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  secondary={
                    <>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        {aboutOutlet.contactPersonPhone}
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        {aboutOutlet.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Grid>

          {/* ))} */}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <Typography sx={active}>Manager</Typography>

        <Grid container spacing={2} sx={boxStyling}>
          {managerList.map((manager, index) => (
            <Grid
              item
              xs={12}
              key={index}
              mt={index > 0 ? -22 : -4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <List sx={{ minWidth: "80%" }}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      sx={{ color: "red.light2" }}
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        handleOpenDeleteManagerConfirmation(manager._id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      {/* <PermIdentityIcon sx={{ fontSize: "30px" }} /> */}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography>{manager.name}</Typography>}
                    primaryTypographyProps={{
                      color: "black.primary",
                      fontSize: "18px",
                      fontWeight: 500,
                    }}
                    secondary={
                      <>
                        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                          {manager.phone}
                        </Typography>
                        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                          {manager.email}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <EditManagerModal
        handleCloseEditManager={handleCloseEditManager}
        openEditManagerModal={openEditManagerModal}
        getAboutOutLet={getAboutOutLet}
        data={data}
        // contactPersonPhone = {data.contactPersonPhone.slice(-10)}
        setData={setData}
      />

      <ManagerDeleteConfirmation
        handleCloseDeleteManagerConfirmation={
          handleCloseDeleteManagerConfirmation
        }
        openDeleteManagerConfirmation={openDeleteManagerConfirmation}
        managerId={managerId}
        getAllManager={getAllManager}
      />
    </Grid>
  );
};

export default ContactDetails;
