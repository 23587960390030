import React, { useContext, useState, useEffect } from "react";
import ReadyItemCard from "./card/ReadyItemCard";
import { Grid, Box } from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import { Pagination } from "@mui/material";
import axios from "axios";

const Ready = () => {
  const { setIsLoading, snackbar, setApiCall } = useContext(AdminDataContext);
  const [readyItems, setReadyItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pages, setPages] = useState(1);
  setApiCall(false)
  const limit = 12;
  const handlePageClick = async (event, pageNo) => {
    try {
      setPages((p) => {
        return pageNo;
      });
      getAllReadyItems(pageNo);
    } catch (error) {}
  };

  const getAllReadyItems = async (page) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/orders?page=${page}&limit=${limit}&restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&status=Ready`
      );

      setReadyItems(res.data.data);
      setTotalItems(res.data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllReadyItems(1);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getAllReadyItems(1);
    }, 30000);
    return () => clearInterval(interval);
  }, []);


 

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "start" }}
      >
        {readyItems.map((readyItem, index) => (
          <ReadyItemCard key={index} readyItem={readyItem} />
        ))}

        {readyItems.length < 1 ? (   
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}
                src="/images/noReadyOrderAvailable.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={"flex"}
          sx={{ justifyContent: { xs: "center", md: "end" }, my: 3 }}
        >
          <Pagination
            count={
              Math.trunc(totalItems / limit) +
              (totalItems % limit === 0 ? +0 : +1)
            }
            color="secondary"
            onChange={handlePageClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Ready;
