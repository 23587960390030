import {
  Grid,
  Box,
  Container,
  Stack,
  Typography,
  Button,
  Modal,
  FormControlLabel,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";

import React, { useState, useContext, useEffect } from "react";

import { AdminDataContext } from "../../../../useContext/AdminContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

const Options = ({ options, setOptions }) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState({});
  const [itemData, setItemData] = useState({});
  const [currentItem, setCurrentItem] = useState({});

  const [optionsModal, setOptionsModal] = React.useState(false);
  const handleOptionsModal = () => setOptionsModal(true);
  const handleCloseOptionsModal = () => setOptionsModal(false);

  const [optionsItemModal, setOptionsItemModal] = useState(false);
  const handleOptionsItemModal = (item) => {
    setOptionsItemModal(true);
    setCurrentItem(item);
  };
  const handleCloseOptionsItemModal = () => {
    setOptionsItemModal(false);
    setCurrentItem({});
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",

    paddingY: 1,
    paddingX: 2,
    minWidth: "150px",
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
  };
  // const createBtn = {
  //   backgroundColor: "bgWhite.main",
  //   color: "primary.main",
  //   width: "max-content",
  //   border: "2px solid #F75B28",
  //   borderRadius: "5px",
  //   textTransform: "capitalize",

  //   "&:hover": {
  //     backgroundColor: "#FFF",
  //   },
  // };
  const textFieldStyle = {
    width: { xs: "100%" },
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  const deleteBtn = {
    backgroundColor: "yellow.main",
    color: "white.main",
    width: "max-content",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",

    "&:hover": {
      backgroundColor: "yellow.main",
    },
  };

  const styleBox = {
    bgcolor: "background.paper",

    py: 2,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  const activeCreateBtn = {
    width: "auto",
    backgroundColor: `white.main`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "yellow.secondary",
    paddingY: 1,
    paddingX: 2,
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 700,
    minWidth: { xs: "100px", sm: "140px" },
    textDecoration: "none",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: `white.main`,
    },
  };
  const handleOptionsChange = async (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleOptionsItemChange = async (event) => {
    setItemData({ ...itemData, [event.target.name]: event.target.value });
  };
  const deleteOptionsHandler = (dd) => {
    // console.log(index);
    setOptions(options.filter((a) => a !== dd));
  };
  const deleteOptionsItemHandler = (optionsItems, item) => {
    const newData = options.map((item2) => {
      if (item2 === item) {
        const newItem = {
          ...item2,
          optionsItems: item2.optionsItems.filter((a) => a !== optionsItems),
        };
        return newItem;
      } else {
        return item2;
      }
    });
    setOptions(newData);
  };
  const createOptionsHandler = () => {
    setOptions([...options, { ...data, optionsItems: [] }]);
    setData({});
    handleCloseOptionsModal();
  };
  const createOptionsItemHandler = () => {
    const newData = options.map((item) => {
      if (item === currentItem) {
        const newItem = {
          ...item,
          optionsItems: [...item.optionsItems, { title: itemData.title }],
        };
        return newItem;
      } else {
        return item;
      }
    });
    setOptions(newData);

    setItemData({});
    handleCloseOptionsItemModal();
  };
  return (
    <>
      <Container fixed sx={{ my: 1 }}>
        <Box component="form" noValidate sx={styleBox}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "rgba(13, 30, 55, 0.67)",
                  justifySelf: "start",
                }}
              >
                Options
              </Typography>

              {/* <Button
                onClick={handleOptionsModal}
                sx={active}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Create
              </Button> */}
            </Stack>

            <Grid
              container
              sx={{
                backgroundColor: "#FF9415",
                py: 1,
                borderRadius: "11px",
                px: 1,
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {
                    // xs: "center",
                    // sm: "center",
                    // md: "start",
                    xs: "space-between",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 700,
                    // fontSize: "18px",
                    fontSize: "18px",
                    color: "white.main",
                  }}
                >
                  <Box
                    component={"span"}
                    sx={{
                      background: "rgba(255, 255, 255, 0.23)",
                      padding: "10px",
                      borderRadius: "5px",
                      display: { xs: "none", sm: "inline-block" },
                    }}
                  >
                    {options.length}
                  </Box>{" "}
                  Option items
                </Typography>
                <Button
                  onClick={handleOptionsModal}
                  sx={activeCreateBtn}
                  id="sign-in-button"
                  variant="contained"
                  size="large"
                >
                  Create
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center", pb: 2 }}
            >
              {options.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleAccordionChange(`panel${index + 1}`)}
                    key={item._id}
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid rgba(0, 0, 0, 0.34)",
                      borderRadius: "21px",
                      width: "100%",
                      m: 3,
                      my: 0,
                    }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="white" />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      sx={{
                        background:
                          "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                        borderRadius: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "18px",
                          color: "white.main",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "18px",
                        }}
                      >
                        Maximum Select : {item.maximumSelect}
                      </Typography>
                      {item.optionsItems.length === 0 ? (
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Grid
                              item
                              xs={10}
                              sm={7}
                              md={6}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "70%",
                                  height: "70%",
                                  borderRadius: "20px",
                                  mt: 3,
                                }}
                                component="img"
                                alt="addOns"
                                src="/images/zeroOptionItems.svg"
                              ></Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} mt={3}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",

                                justifyContent: { xs: "center", md: "end" },
                                alignItems: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() => handleOptionsItemModal(item)}
                                sx={active}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {item.optionsItems.map((optionsItems, index2) => (
                            <Box
                              key={index2}
                              pt={2}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ ms: 5 }}>
                                {optionsItems.title}
                              </Typography>

                              {/* <Typography sx={{ me: 5 }}>
                                {"₹" + optionsItems.price}
                              </Typography> */}
                              <DeleteIcon
                                onClick={() =>
                                  deleteOptionsItemHandler(optionsItems, item)
                                }
                              />
                            </Box>
                          ))}

                          <Grid container spacing={2} mt={3}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",

                                justifyContent: { xs: "center", md: "end" },
                                alignItems: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() => handleOptionsItemModal(item)}
                                sx={active}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  {/* <Button
                    sx={active}
                    id=" Create"
                    onClick={() => deleteOptionsHandler(item)}
                    variant="contained"
                    color="warning"
                    size="large"
                    m={2}
                  >
                    Delete
                  </Button> */}
                  <DeleteIcon
                    color="primary"
                    sx={{ fontSize: "30px", mt: 1 }}
                    onClick={() => deleteOptionsHandler(item)}
                  />
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: options.length === 0 ? "block" : "none" }}>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  xs={10}
                  sm={7}
                  md={8}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "70%",
                      borderRadius: "20px",
                      mt: 3,
                    }}
                    component="img"
                    alt="addOns"
                    src="/images/zeroOptionItems.svg"
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
      <Modal
        open={optionsModal}
        onClose={handleCloseOptionsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <FormControlLabel
                control={<ArrowBackIosNewIcon />}
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "rgba(13, 30, 55, 0.67)",
                      justifySelf: "start",
                    }}
                  >
                    Create Options
                  </Typography>
                }
              />

              <IconButton onClick={handleCloseOptionsModal} aria-label="delete">
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Option Title
                </Typography>

                <TextField
                  size="small"
                  name="title"
                  value={data.title}
                  required
                  onChange={handleOptionsChange}
                  placeholder="Title"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Maximum Select
                </Typography>

                <TextField
                  size="small"
                  type="number"
                  name="maximumSelect"
                  value={data.maximumSelect}
                  required
                  onChange={handleOptionsChange}
                  placeholder="Maximum Select"
                  sx={textFieldStyle}
                />
              </Grid>

              <Grid container spacing={2} px={3} mt={3}>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",

                      justifyContent: { xs: "center" },
                      alignItems: "end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      // type="submit"
                      sx={active}
                      onClick={createOptionsHandler}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={optionsItemModal}
        onClose={handleCloseOptionsItemModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <FormControlLabel
                control={<ArrowBackIosNewIcon />}
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "rgba(13, 30, 55, 0.67)",
                      justifySelf: "start",
                    }}
                  >
                    Create Options Item
                  </Typography>
                }
              />

              <IconButton
                onClick={handleCloseOptionsItemModal}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Options Item Title
                </Typography>

                <TextField
                  size="small"
                  name="title"
                  value={itemData.title}
                  required
                  onChange={handleOptionsItemChange}
                  placeholder="Title"
                  sx={textFieldStyle}
                />
              </Grid>

              {/* <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Price
                </Typography>

                <TextField
                  size="small"
                  type="number"
                  name="price"
                  value={itemData.price}
                  required
                  onChange={handleOptionsItemChange}
                  placeholder="Price"
                  sx={textFieldStyle}
                />
              </Grid> */}

              <Grid container spacing={2} px={3} mt={3}>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",

                      justifyContent: { xs: "center" },
                      alignItems: "end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      // type="submit"
                      sx={active}
                      onClick={createOptionsItemHandler}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Options;
