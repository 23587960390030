import DashboardIcon from "@mui/icons-material/Dashboard";

import FastfoodIcon from "@mui/icons-material/Fastfood";
import LogoutIcon from "@mui/icons-material/Logout";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TableBarIcon from "@mui/icons-material/TableBar";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AdminDataContext } from "../../../useContext/AdminContext";
import { useState } from "react";

const useContent = () => {
  const { adminData } = useContext(AdminDataContext);

  const location = useLocation();
  const currentUrl = location.pathname;

  // const [click, setClick] = useState(false)
  // const liveOrderClick = () => {
  //   setClick(true);
  // }

  const content = [
    // {
    //   icon: DashboardIcon,
    //   text: "Dashboard",
    //   slug: "",
    // },
  ];



  content.push({
    icon: RestaurantMenuIcon,
    text: "Live Orders",
    slug: currentUrl != "/" ? "live-orders" : "" ,
    // slug: "",
  });



  content.push({
    icon: FastfoodIcon,
    text: "Menu",
    slug: "menu",
  });

  // content.push({
  //   icon: RestaurantIcon,
  //   text: "Menu",
  //   slug: "menu",
  //   items: [
  //     {
  //       text: "Category",
  //       slug: "category",
  //     },
  //     {
  //       text: "menu",
  //       slug: "menu",
  //     },
  //     {
  //       text: "Create",
  //       slug: "create",
  //     },
  //   ],
  // });
  // content.push({
  //   icon: AddBoxIcon,
  //   text: "Add On",
  //   slug: "addOns",
  // });

  content.push({
    icon: TableBarIcon,
    text: "Reservation",
    slug: "reservation",
  });

  content.push({
    icon: GroupAddIcon,
    text: "Add Managers",
    slug: "add-managers",
  });

  content.push({
    icon: AutoGraphIcon,
    text: "Reports",
    slug: "reports",
  });



  // content.push({
  //   icon: EngineeringIcon,
  //   text: "Manage Outlett",
  //   slug: "manage-outlett",
  // });

  content.push({
    icon: FoodBankIcon,
    text: "Manage Outlet",
    slug: "manage-outlet",
  });

  content.push({
    icon: ErrorOutlineIcon,
    text: "Complaints",
    slug: "complaints",
  });

  // content.push({
  //   icon: ErrorOutlineIcon,
  //   text: "Legal",
  //   slug: "legal",
  // });

  content.push({
    icon: HelpOutlineIcon,
    text: "FAQ",
    slug: "faq",   
  });

  content.push({
    icon: LogoutIcon,
    text: "Logout",
    slug: "logout",
  });
  return content;
};
export default useContent;
