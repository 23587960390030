import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import OrderTabs from "./orderTabs/OrderTabs";
import TabHeadings from "./tabHeading/TabHeadings";

const LiveOrder = () => {
  return (
    <>
      <OrderTabs />
      <Container fixed mx={3}>
        <TabHeadings />
        
        <Outlet />
      </Container>
    </>
  );
};

export default LiveOrder;
