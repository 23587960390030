import React from 'react'
import { Paper, Box, Grid, Container, Button, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";


const MenuEditHistoryTopBar = () => {
  return (
<>
<Container fixed sx={{ my: 1 }}>
        <Box>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "whisper.main",
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid rgba(0, 0, 0, 0.14)",
              p: 2,
              borderRadius: "12px",
            }}
          >
            <Button
              //   onClick={handleChange}
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                color: "#397DFF",
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              Menu Edit History
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                color: "#397DFF",
                fontWeight: 600,
                fontSize: "13px",
              }}
              startIcon={<HelpOutlineIcon />}
            >
              Help Centre
            </Button>
          </Paper>
        </Box>
      </Container>
</>
  )
}

export default MenuEditHistoryTopBar