import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Button,
} from "@mui/material";
import React from "react";
import ImageSlider from "./ImageSlider";
import EditIcon from "@mui/icons-material/Edit";

import { useState, useContext, useEffect } from "react";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import Switch from "@mui/material/Switch";
import Address from "../Address";
import EditRestaurantImages from "./EditRestaurantImages";
const AboutOutlet = () => {
  const matches = useMediaQuery("(min-width:1340px)");
  const matchesDown = useMediaQuery("(min-width:450px)");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [images, setImages] = useState([null, null, null]);
  const [aboutOutlet, setAboutOutlet] = useState({});
  const [editAddress, setEditAddress] = useState(false);
  const [editRestaurantImage, setEditRestaurantImage] = useState(false);

  const handleEditImage = () => {
    setEditRestaurantImage(!editRestaurantImage);
  };

  const handleEditAddress = () => {
    setEditAddress(!editAddress);
  };

  const getAboutOutLet = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/details?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setImages(res.data.data.images.length === 3 ? res.data.data.images : [null, null, null]);
      // setImages([null, null, null])
      setAboutOutlet(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  const restaurantDiningStatus = async (status) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/reservation/switch?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        { dining: status }
      );
      snackbar(res.data.message, "success");
      setIsLoading(false);
      getAboutOutLet();
    } catch (error) {
      snackbar(error.response.data.message, "success");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAboutOutLet();
  }, []);
  const paraText = {
    color: "green.secondary",
    fontSize: "18px",
    fontWeight: 400,
    // lineHeight: "20px",
  };
  const paraHeading = {
    color: "green.secondary",
    fontSize: "18px",
    fontWeight: 700,
  };

  const paraHeading1 = {
    color: "green.secondary",
    fontSize: "18px",
    fontWeight: 700,
    marginStart: 5,
  };
  return (
    <>
      {editRestaurantImage ? (
        <EditRestaurantImages handleEditImage = {handleEditImage} images = {images} setImages = {setImages} getAboutOutLet = {getAboutOutLet}  />
      ) : (
        <>
          {editAddress ? (
            <Address getAboutOutLet = {getAboutOutLet} handleEditAddress={handleEditAddress} />
          ) : (
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                md={matches ? 6 : 12}
                sx={{
                  display: "flex",
                  justifyContent: matches ? "" : "center",
                }}
              >
                <ImageSlider
                  images={aboutOutlet.images}
                  avgRating={aboutOutlet.avgRating}
                  handleEditImage={handleEditImage}
                />
              </Grid>
              <Grid item xs={12} md={matches ? 6 : 12}>
                <Typography
                  sx={{
                    color: "drawerColor.main",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: 4,
                    visibility: "hidden",
                  }}
                >
                  Balaji Food Studio
                </Typography>
                <Typography
                  sx={{
                    color: "drawerColor.main",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: matches ? 2 : 0,
                  }}
                >
                  <Button
                    variant="outlined"
                    endIcon={<EditIcon />}
                    onClick={handleEditAddress}
                    sx={{
                      color: "drawerColor.main",
                      fontSize: "20px",
                      fontWeight: 600,
                      // mt: matches ? 3 : 0,
                      border: "none",
                      "&:hover": { border: "none" },
                    }}
                  >
                    {aboutOutlet ? aboutOutlet.name : ""}
                  </Button>

                  {/* {aboutOutlet ? aboutOutlet.name : ""} */}
                  {/* <EditIcon  /> */}
                </Typography>
                {/* <Typography mt={1} sx={paraText}>
        Naupada, Thane West
      </Typography>

      <Typography sx={paraText}>Maharashtrian, Street Food</Typography> */}

                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ display: "flex", mt: 2 }}>
                    {/* <PinDropIcon /> */}
                    <Typography sx={paraHeading}>
                      Full Address:{" "}
                      <span style={paraText}>
                        {" "}
                        {aboutOutlet.address
                          ? aboutOutlet.address.completeAddress
                          : ""}
                        , {aboutOutlet.address ? aboutOutlet.address.city : ""},{" "}
                        {aboutOutlet.address ? aboutOutlet.address.state : ""}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box sx={{ visibility: "hidden" }}>
                    {/* <PinDropIcon /> */}
                  </Box>

                  <Typography sx={paraHeading1}>
                    Restaurant Type:{" "}
                    <span style={paraText}>
                      {" "}
                      {aboutOutlet ? aboutOutlet.restaurantType : ""}{" "}
                    </span>
                  </Typography>
                </Box>
                <Typography sx={paraHeading1}>
                  Restaurant Dining Status :{" "}
                  <FormControlLabel
                    sx={{ m: 0, p: 0 }}
                    onChange={(e) => restaurantDiningStatus(e.target.checked)}
                    control={<Switch checked={aboutOutlet.dining} />}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default AboutOutlet;
