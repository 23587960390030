import React from "react";
import { useLocation } from "react-router-dom";
import { Paper, Button } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HelpCenterModal from "./HelpCenterModal";


const TabHeadings = () => {
  const location = useLocation();
  const currentTabHeading = location.pathname;
  const [helpCenterModal, setHelpCenterModal] = React.useState(false);
  const handleOpenHelpCenterModal = () => setHelpCenterModal(true);
  const handleCloseHelpCenterModal = () => setHelpCenterModal(false);
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "whisper.main",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid rgba(0, 0, 0, 0.14)",
          p: {xs:1, sm:2},
          borderRadius: "12px",
          mt: 2,
        }}
      >
        <Button
          //   onClick={handleChange}

          variant="outlined"
          sx={{
            textTransform: "capitalize",
            color: "green.secondary",
            fontWeight: 600,
            fontSize: "20px",
            border: "none",
            p:{xs:0.5, sm:1},
            "&:hover": {
              border: "none",
            },
          }}
          // endIcon={<AutorenewIcon />}
        >
          {currentTabHeading === "/" || currentTabHeading === "/live-orders"
            ? "New Orders"
            : currentTabHeading === "/live-orders/preparing"
            ? "Preparing "
            : currentTabHeading === "/live-orders/ready"
            ? "Ready"
            : currentTabHeading === "/live-orders/picked-up"
            ? "Picked Up"
            : currentTabHeading === "/live-orders/past-order"
            ? "Past Order"
            : currentTabHeading === "/live-orders/rejected-order"
            ? "Rejected"
            : ""}
        </Button>






        <Button
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            color: "#397DFF",
            fontWeight: 600,
            fontSize: "13px",
            border: "1px solid #397DFF",
            p:{xs:0.5, sm:1.3},
            "&:hover": {   
              border: "1px solid #397DFF",
            },
          }}
          startIcon={<HelpOutlineIcon />}
          onClick={handleOpenHelpCenterModal}
        >
          Help Centre
        </Button>
      </Paper>
      <HelpCenterModal
        helpCenterModal={helpCenterModal}
        handleCloseHelpCenterModal={handleCloseHelpCenterModal}
      />
    </>
  );
};

export default TabHeadings;
