import {
  Grid,
  Box,
  Stack,
  Typography,  
  Modal,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { TextField, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Rating from "@mui/material/Rating";
import React, { useContext, useState } from "react";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";

const Feedback = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [rating, setRating] = useState(0);

  const [description, setDescription] = useState("");

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      rating: rating,
      description: description,
    };

    try {
     
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/owner/restaurant/app/review?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`,
        data
      );
      setDescription("");
      setRating(0);
      snackbar(res.data.message, "success");
      
      props.handleCloseNotificationModal();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setDescription("");
      setRating(0);
      setIsLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
  };
  const textFieldStyle = {
    width: { xs: "100%", sm: "100%" },
    borderRadius: "10px",
    backgroundColor: "addMenuItemColor.light",
    color: "rgba(255, 255, 255, 0.96)",
    // border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  const btn = {
    width: "auto",
    height: "50px",
    backgroundColor: "blue.primary",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: 2,
    textDecoration: "none",
  
    px: 5,

    "&:hover": {
      backgroundColor: "blue.primary",
      color: "white.main",
    },
  };
  return (
    <>
      <Modal
        open={props.notifications}
        onClose={props.handleCloseNotificationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent={"space-between"}
              gap={1}
              sx={{
                position: "relative",
                backgroundColor: "grey.midGrey",
                p: 2,
                borderRadius: "16px 16px 0px 0px ",
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "22px",

                    color: "white.main",
                    justifySelf: "start",
                  }}
                >
                  Feedback
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 300,
                    fontSize: "15px",

                    color: "bgWhite.tertiary",
                    justifySelf: "start",
                  }}
                >
                  please share us your experience on YBites
                </Typography>
              </Box>

              <IconButton
                onClick={props.handleCloseNotificationModal}
                aria-label="delete"
                sx={{ color: "white.main" }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Box component={"form"} onSubmit={onSubmitHandler} sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    component={"img"}
                    width="80px"
                    src="/images/feedbackImg.svg"
                  ></Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="simple-controlled"
                    value={rating}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    
                    }}
                    size="large"
                  />
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        fontSize: "18px",

                        color: "black.primary",
                        justifySelf: "start",
                      }}
                    >
                      Add your opinion
                    </Typography>

                    <TextField
                      size="small"
                      name="description"
                      type="text"
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 200 }}
                      required
                      placeholder="Message"
                      onChange={(event) => {
                        setDescription(event.target.value);
                        
                      }}
                      sx={textFieldStyle}
                    />
                  </Box>
                  <Button
                    type="submit"
                    disabled={rating > 0 ? false : true}
                    variant="contained"
                    sx={btn}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Feedback;
