import React, { useContext, useEffect, useState } from "react";

import { Grid, Box } from "@mui/material";
import PickedUpItemCard from "./card/PickedUpItemCard";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { Pagination } from "@mui/material";
const PickedUp = () => {
  const { setIsLoading, snackbar, setApiCall } = useContext(AdminDataContext);
  const [pickedUpItems, setPickedUpItems] = useState([]);

  setApiCall(false)
  const [totalItems, setTotalItems] = useState(0);
  const [pages, setPages] = useState(1);

  const limit = 12;
  const handlePageClick = async (event, pageNo) => {
    try {
      setPages((p) => {
        return pageNo;
      });
      getAllPickedUpItems(pageNo);
    } catch (error) {}
  };
  


  
  const getAllPickedUpItems = async (page) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/orders?page=${page}&limit=${limit}&restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&status=Picked-up`
      );

      setPickedUpItems(res.data.data);
      setTotalItems(res.data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPickedUpItems(1);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      getAllPickedUpItems(1);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

 
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "start" }}
      >

{pickedUpItems.map((pickedUpItem, index) => (
  <PickedUpItemCard
  key={index}
  pickedUpItem={pickedUpItem}
   />
) ) }

  

{pickedUpItems.length < 1 ? (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              marginTop={7}
              sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}
              src="/images/noPickedUpOrderAva.svg"
            ></Box>
          </Grid>
        </>
      ) : (
        <></>
      )}

      </Grid>
      <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display={"flex"}
            sx={{ justifyContent: { xs: "center", md: "end" }, my: 3 }}
          >
            <Pagination
              count={
                Math.trunc(totalItems / limit) +
                (totalItems % limit === 0 ? +0 : +1)
              }
              color="secondary"
              onChange={handlePageClick}
            />
          </Grid>
        </Grid>

    </>
  );
};

export default PickedUp;
