import { Container } from "@mui/material";
import React, { useState } from "react";

import { Outlet } from "react-router-dom";

import MenuTabs from "./MenuTabs";

const MenuPage = () => {
  const [addMenuItems, setAddMenuItems] = useState(false);

  const handleChange = () => {
    setAddMenuItems(!addMenuItems);
  };
  return (
    <>
      <MenuTabs current="menuItem" handleChange={handleChange} />

      <Container fixed sx={{ my: 1 }}>
        <Outlet />
      </Container>
    </>
  );
};

export default MenuPage;
