import React, { useContext } from "react";
import LeftDrawer from "./LeftDrawer";
import { Route, Routes, useNavigate } from "react-router-dom";

import Dashboard from "../dashboard/Dashboard";
import Logout from "../logout";

import { AdminDataContext } from "../../useContext/AdminContext";

import SelectRestaurant from "../restaurant/SelectRestaurant";
import Menu from "../restaurant/menuItems/Menu";
import MenuPage from "../restaurant/OldMenuItems/Menu";
import AddOns from "../restaurant/AddOns";
import MenuCategory from "../restaurant/MenuCategory";
import CreateMenuItem from "../restaurant/menuItems/CreateMenuItem";
import AddOn from "../restaurant/addOn/AddOn";
import MenuEditHistory from "../restaurant/menuEditHistory/MenuEditHistory";
import EditMenuItems from "../restaurant/OldMenuItems/editMenuItem/EditMenuItems";
import LiveOrder from "../restaurant/liveOrders/LiveOrder";
import NewOrder from "../restaurant/liveOrders/newOrders/NewOrder";
import Preparing from "../restaurant/liveOrders/preparingPage/Preparing";
import Ready from "../restaurant/liveOrders/readyPage/Ready";
import AllMenuItem from "../restaurant/OldMenuItems/allMenuItems/AllMenuItem";
import AddMenuItems from "../restaurant/OldMenuItems/AddMenuItems";
import AddItemCategory from "../restaurant/OldMenuItems/AddItemCategory";
import PickedUp from "../restaurant/liveOrders/pickedUpPage/PickedUp";
import PastOrders from "../restaurant/liveOrders/pastOrdersPage/PastOrders";
import RejectedOrders from "../restaurant/liveOrders/rejectedOrders/RejectedOrders";

import AddManagers from "../restaurant/addManagers/AddManagers";
import Reservation from "../restaurant/reservation/Reservation";
import NewReservation from "../restaurant/reservation/newReservation/NewReservation";
import TodaysReservation from "../restaurant/reservation/todaysReservation/TodaysReservation";
import UpcomingReservations from "../restaurant/reservation/upcomingReservation/UpcomingReservations";
import AvailableTimeSlot from "../restaurant/reservation/availableTimeSlot/AvailableTimeSlot";
import Complaints from "../restaurant/complaints/Complaints";
import PendingComplaints from "../restaurant/complaints/pending/PendingComplaints";
import NewComplaints from "../restaurant/complaints/newComplaints/NewComplaints";
import RefundedComplaints from "../restaurant/complaints/refunded/RefundedComplaints";
import TicketHistory from "../restaurant/complaints/ticketHistory/TicketHistory";
import Address from "../restaurant/manageOutlet/Address";
import ManageOutlet from "../restaurant/manageOutlet/ManageOutlet";
import AboutOutlet from "../restaurant/manageOutlet/aboutOutlet/AboutOutlet";
import ContactDetails from "../restaurant/manageOutlet/contactDetails/ContactDetails";
import OutletTiming from "../restaurant/manageOutlet/outletTiming/OutletTiming";
import YourAccount from "../restaurant/manageOutlet/yourAccount/YourAccount";
import Documents from "../restaurant/manageOutlet/documents/Documents";
import Reports from "../restaurant/reports/Reports";
import { useLocation } from "react-router-dom";
import Legal from "../restaurant/legal/Legal";
import TermsAndCondition from "../restaurant/legal/TermsAndCondition";

import PrivacyPolicy from "../restaurant/legal/PrivacyPolicy";
import FAQ from "../restaurant/faq/FAQ";
// import Customization from "../restaurant/customization/Customization";
// import Options from "../restaurant/options/Options";

// import Text from "../form/Test";

const ComponentRoute = () => {
  const { userType } = useContext(AdminDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  // console.log("routing", currentUrl);

  const restaurantId = localStorage.getItem("restaurantId");
  return (
    <>
      {userType === "owner" ? (
        restaurantId ? (
          <LeftDrawer>
            <Routes>
              {/* <Route path="/" element={<Dashboard />} /> */}
              {/* <Route path="/live-orders/" element={<LiveOrder />}> */}
              {/* <Route path={currentUrl != "/" ? "/live-orders/" : "/" } element={<LiveOrder />}> */}
              <Route path="/" element={<LiveOrder />}>
                <Route path="" element={<NewOrder />} />
                <Route path="/live-orders" element={<NewOrder />} />
                <Route path="/live-orders/preparing" element={<Preparing />} />

                <Route
                  path="/live-orders/live-orders/preparing"
                  element={<Preparing />}
                />
                <Route path="/live-orders/ready" element={<Ready />} />
                <Route path="/live-orders/picked-up" element={<PickedUp />} />
                {/* <Route path="/live-orders/picked-up" element={<PickedUp />} /> */}
                
                <Route
                  path="/live-orders/past-order"
                  element={<PastOrders />}
                />
                  <Route
                  path="/live-orders/past-order"
                  element={<PastOrders />}
                />
                <Route
                  path="/live-orders/rejected-order"
                  element={<RejectedOrders />}
                />
              </Route>

              <Route path="/menu/menu" element={<Menu />} />
              <Route path="/manage-outlett" element={<Address />} />

              <Route path="/menu/" element={<MenuPage />}>
                <Route path="" element={<AllMenuItem />} />
                <Route path="add-menu-item" element={<AddMenuItems />} />
                <Route path="addOn" element={<AddOn />} />

                <Route
                  path="menu-category-details"
                  element={<AddItemCategory />}
                />
                <Route path="menu-edit-history" element={<MenuEditHistory />} />
                <Route
                  path="edit-menu-item/:singleMenuItemId"
                  element={<EditMenuItems />}
                />
              </Route>

              <Route path="/add-managers" element={<AddManagers />} />
              <Route path="/reports" element={<Reports />} />

              <Route path="/reservation/" element={<Reservation />}>
                <Route path="" element={<NewReservation />} />
                <Route
                  path="today-reservation"
                  element={<TodaysReservation />}
                />
                <Route
                  path="tomorrow-reservation"
                  element={<UpcomingReservations />}
                />
                <Route
                  path="available-time-slot"
                  element={<AvailableTimeSlot />}
                />
              </Route>

              <Route path="/complaints/" element={<Complaints />}>
                <Route path="" element={<NewComplaints />} />
                <Route path="pending" element={<PendingComplaints />} />
                <Route path="refunded" element={<RefundedComplaints />} />
                <Route path="ticket-history" element={<TicketHistory />} />
              </Route>

              <Route path="/manage-outlet/" element={<ManageOutlet />}>
                <Route path="" element={<AboutOutlet />} />
                <Route path="contact-details" element={<ContactDetails />} />
                <Route path="outlet-timing" element={<OutletTiming />} />
                <Route path="your-account" element={<YourAccount />} />
                <Route path="documents" element={<Documents />} />
              </Route>
              <Route path="/faq" element={<FAQ />} />

              <Route path="/legal/" element={<Legal />}>

              <Route
                  path=""
                  element={<PrivacyPolicy />}
                />

                <Route
                  path="terms-&-condition"
                  element={<TermsAndCondition />}
                />
              
              </Route>

              <Route path="/change-restaurant" element={<SelectRestaurant />} />
              <Route path="/menu/create" element={<CreateMenuItem />} />
              <Route path="/menu/category" element={<MenuCategory />} />
              <Route path="/addOns" element={<AddOns />} />

              <Route path="/logout" element={<Logout />} />
            </Routes>
          </LeftDrawer>
        ) : (
          <>
            <SelectRestaurant />
          </>
        )
      ) : (
        <>
          <LeftDrawer>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              {/* <Route
                path="/restaurant/verified"
                element={<VerifiedRestaurant />}
              />
              <Route
                path="/restaurant/unverified"
                element={<UnVerifiedRestaurant />}
              /> */}
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </LeftDrawer>
        </>
      )}
    </>
  );
};

export default ComponentRoute;
