import React from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { Data } from "@react-google-maps/api";

const TicketCreationDetailModal = (props) => {
  const matches = useMediaQuery("(min-width:450px)");

  const mainHeading = {
    fontWeight: 500,
    fontSize: "18px",
    color: "black.primary",
  };

  const text = {
    fontWeight: 400,
    fontSize: "16px",
    color: "black.primary",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "400px" : "300px",

    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openTicketCreationDetailModal}
        onClose={props.handleCloseTicketCreationDetailModal}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openTicketCreationDetailModal}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{
                  position: "relative",
                  backgroundColor: "grey.lightGrey",
                  px: 2,
                  py: 1,
                  borderRadius: "6px ",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "black.primary",
                    justifySelf: "start",
                  }}
                >
                  {props.data.title}
                </Typography>
                <IconButton
                  onClick={props.handleCloseTicketCreationDetailModal}
                  aria-label="cancel"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>

              <Grid
                container
                sx={{
                  borderRadius: "11px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} mx={2} mt={2}>
                    <Typography sx={mainHeading}>Ticket Description</Typography>
                    <Typography sx={text}>  {props.data.description}</Typography>
                  </Grid>

                  <Grid item xs={12} mx={2} mb={2}>
                    <Typography sx={mainHeading}>Solution</Typography>
                    <Typography sx={text}>  {props.data.solution}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default TicketCreationDetailModal;
