import React, { useState, useContext, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import NewComplaintsCard from "./card/NewComplaintsCard";

import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { Pagination } from "@mui/material";



const NewComplaints = () => {

  const { setIsLoading } = useContext(AdminDataContext);
  const [newComplaints, setNewComplaints] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pages, setPages] = useState(1);






  const limit = 12;
  const handlePageClick = async (event, pageNo) => {
    try {
      setPages((p) => {
        return pageNo;
      });
      getAllNewComplaints(pageNo);
    } catch (error) {}
  };
 
  const getAllNewComplaints = async (page) => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/order/complain?page=${page}&limit=${limit}&restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setNewComplaints(res.data.data);
      setTotalItems(res.data.data.length);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllNewComplaints(1);
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {newComplaints.map((complaint, index) => (
          <NewComplaintsCard
          key={index}
          complaint = {complaint}
          getAllNewComplaints = {getAllNewComplaints}
          />
        ))}

        {newComplaints.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width:{xs:"95%", sm:'60%', md:"30%"}}}

                src="/images/zeroComplaints.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={"flex"}
          sx={{ justifyContent: { xs: "center", md: "end" }, my: 3 }}
        >
          <Pagination
            count={
              Math.trunc(totalItems / limit) +
              (totalItems % limit === 0 ? +0 : +1)
            }
            color="secondary"
            onChange={handlePageClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewComplaints;
