import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import React, { useContext, useState, useEffect } from "react";   

import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { AdminDataContext } from "../../../../useContext/AdminContext";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const CustomersFeedbacks = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [customerFeedbackList, setCustomerFeedbackList] = useState([]);
  const [customerFeedback, setCustomerFeedback] = useState("");

  const getAllCustomerFeedback = async () => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/restaurant/review?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setCustomerFeedbackList(res.data.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getAllCustomerFeedback();
  }, []);

  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",   
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
  };
  const boxStyling = {
   
      p: 2, height:'80vh',
      overflow: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "0.4em",
        
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
  
  }

  return (
    <>
      <Modal
        open={props.customerNotifications}
        onClose={props.handleCloseCustomerNotificationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent={"space-between"}
              gap={1}
              sx={{
                position: "relative",
                backgroundColor: "grey.midGrey",
                p: 2,
                borderRadius: "16px 16px 0px 0px ",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: "22px",

                  color: "white.main",
                  justifySelf: "start",
                }}
              >
                Customer’s feedback
              </Typography>

              <IconButton
                onClick={props.handleCloseCustomerNotificationModal}
                aria-label="delete"
                sx={{ color: "white.main" }}
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Box sx={boxStyling}>
              <Grid container spacing={2}>
                {customerFeedbackList.map((feedback, index) => (
                  <Grid
                    item
                    xs={12}
                    // component={"form"} onSubmit={onSubmitHandler}
                  >
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemIcon sx={{ marginLeft: -3 }}>
                          <FiberManualRecordIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "18px",
                            marginLeft: -3,
                            fontWeight: 500,
                          }}
                          primary={feedback.customer.name}
                        />
                      </ListItem>
                    </List>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                 {feedback.description}
                    </Typography>

                    {/* <Box sx={{ display: "flex", mt: 1 }}>
                    <TextField
                      size="small"
                      name="reply"
                      type="text"
                      required
                      placeholder="Reply"
                      sx={textFieldStyle}
                    
                      onChange={(event) => {
                        setCustomerFeedback(event.target.value);
                        console.log(customerFeedback);
                      }}

                    />

                    <IconButton aria-label="send" type="submit" size="small">
                      <Avatar>
                        <SendIcon />
                      </Avatar>
                    </IconButton>
                  </Box> */}
                  </Grid>
                ))}

             
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CustomersFeedbacks;
