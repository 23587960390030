import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Grid } from "@mui/material";
const Sales = ({ status, today, week, month }) => {
  let todayDate = new Date();
  let dd = String(todayDate.getDate()).padStart(2, "0");
  let mm = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = todayDate.getFullYear();
  todayDate = dd + "/" + mm + "/" + yyyy;

  let wDate = new Date();
  let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
  let first = wDate.getDate() - dDay + 1;
  let firstDayWeek = new Date(wDate.setDate(first));
  let lastDayWeek = new Date(wDate.setDate(firstDayWeek.getDate() + 6));
 
  const mainHeading = {
    fontSize: "21px",
    fontWeight: 700,
    color: "green.secondary",
  }

  const timePeriodHeading = {
    fontSize: "18px",
    fontWeight: 600,
    color: "green.secondary",
  }

  const timePeriodHeadingValue = {
    fontSize: "18px",
    fontWeight: 400,
    color: "green.secondary",
  }

  const priceStyle = {
    fontSize: "22px",
    fontWeight: 600,
    color: "green.secondary",
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        height: "fit-content",
        marginX: "32px",
        marginY: "5px",
        padding: "10px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" sx={mainHeading}>Total {status} Order Sales</Typography>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <FileDownloadOutlinedIcon sx={{ fontSize: "50px" }} />
          <Typography variant="caption" fontSize="8px">
            Download Report
          </Typography>
        </Stack>
      </Stack>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        sx={{ marginTop: "20px" }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Card
            raised
            sx={{
              background: status === "Delivered" ? "#D6E4F1" : "#ffc5c5",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              paddingTop: "0.8rem",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                paddingLeft: "1rem",
                paddingRight: "0.5rem",
                paddingBottom: "0.5rem",
              }}
            >
              <Typography sx={timePeriodHeading}>Today</Typography>
              <Typography sx={timePeriodHeadingValue}>{todayDate}</Typography>
            </Stack>
            <Card
              raised
              sx={{
                background:
                  status == "Delivered"
                    ? "linear-gradient(180deg, #A6D4FF 0%, #E5F1FD 100%)"
                    : "linear-gradient(180deg, red 0%, #E5F1FD 100%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "14px",
                paddingY: "1rem",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ paddingLeft: "1rem", paddingRight: "0.5rem" }}
              >
                <Typography sx={priceStyle}>₹{today ? today.price : "00"}</Typography>
                <Typography sx={timePeriodHeadingValue}>{today ? today.count : "0"} Orders</Typography>
              </Stack>
            </Card>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card
            raised
            sx={{
              background: status === "Delivered" ? "#D6E4F1" : "#ffc5c5",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              paddingTop: "0.8rem",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                paddingLeft: "1rem",
                paddingRight: "0.5rem",
                paddingBottom: "0.5rem",
              }}
            >
              <Typography sx={timePeriodHeading}>This Week</Typography>
              <Typography sx={timePeriodHeadingValue}>
                {/* {firstDayWeek.toLocaleDateString()} to {lastDayWeek.toLocaleDateString()}  */}
                Monday to Sunday
              </Typography>
            </Stack>
            <Card
              raised
              sx={{
                background:
                  status == "Delivered"
                    ? "linear-gradient(180deg, #A6D4FF 0%, #E5F1FD 100%)"
                    : "linear-gradient(180deg, red 0%, #E5F1FD 100%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "14px",
                paddingY: "1rem",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ paddingLeft: "1rem", paddingRight: "0.5rem" }}
              >
                <Typography sx={priceStyle}>₹{week ? week.price : "00"}</Typography>
                <Typography sx={timePeriodHeadingValue}>{week ? week.count : "0"}  Orders</Typography>
              </Stack>
            </Card>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card
            raised
            sx={{
              background: status === "Delivered" ? "#D6E4F1" : "#ffc5c5",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              paddingTop: "0.8rem",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                paddingLeft: "1rem",
                paddingRight: "0.5rem",
                paddingBottom: "0.5rem",
              }}
            >
              <Typography sx={timePeriodHeading}>This Month</Typography>
              <Typography sx={timePeriodHeadingValue}>
                {new Date().toLocaleString("en-us", {
                  month: "long",
                  year: "numeric",
                })}
              </Typography>
            </Stack>
            <Card
              raised
              sx={{
                background:
                  status == "Delivered"
                    ? "linear-gradient(180deg, #A6D4FF 0%, #E5F1FD 100%)"
                    : "linear-gradient(180deg, red 0%, #E5F1FD 100%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "14px",
                paddingY: "1rem",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ paddingLeft: "1rem", paddingRight: "0.5rem" }}
              >
                <Typography sx={priceStyle}>₹{month ? month.price : '00'}</Typography>
                <Typography sx={timePeriodHeadingValue}>{month ? month.count : '0'} Orders</Typography>
              </Stack>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Sales;
