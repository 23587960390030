import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import React, {  } from "react";

const HelpCenterModal = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "40%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
  };
  return (
    <>
      <Modal
        open={props.helpCenterModal}
        onClose={props.handleCloseHelpCenterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: "22px",

                  color: "rgba(13, 30, 55, 0.67)",
                  justifySelf: "start",
                }}
              >
                Help Center
              </Typography>

              <IconButton
                onClick={props.handleCloseHelpCenterModal}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", justifyContent: "center" }}
              >
                <Box component={"img"} src="/images/helpCenterImg.svg"></Box>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2, textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: 500,
                    color: "black.helpCenter",
                  }}
                >
                  You can contact us through our{" "}
                  <span style={{ fontWeight: 700 }}>
                    {" "}
                    YBITES FOOD DELIVERY{" "}
                  </span>
                  customer support, which is available 24/7
                </Typography>
                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: 500,
                    color: "black.helpCenter",
                  }}
                >
                  Email :{" "}
                  <span style={{ fontWeight: 700 }}>
                    {" "}
                    partnersupport@ybites.com{" "}
                  </span>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: 500,
                    color: "black.helpCenter",
                  }}
                >
                  Contact No :{" "}
                  <span style={{ fontWeight: 700 }}>8069060361</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default HelpCenterModal;
