import { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { PieChart, Pie, Cell } from "recharts";

const data = [
  { name: "Veg Mini Thali", value: 8 },
  { name: "Mutton Thali", value: 7 },
  { name: "Egg thali", value: 3 },
  { name: "Fish thali", value: 1 },
  { name: "Chicken thali", value: 8 },
];
const COLORS = ["#F08888", "#6BCF81", "#E04B9B", "#504DC6", "#575757"];

function getSum(total, num) {
  return total + Math.round(num.value);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Category = ({ status }) => {
  const [value, setValue] = useState(0);
  const [dish, setDish] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ flex: "1" }}>
      <Box>
        <Typography variant="h4"> Category Distribution </Typography>
      </Box>
      <Box>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Delivered Order" {...a11yProps(0)} />
            <Tab label="Total Sale" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box
            sx={{
              backgroundColor:
                status === "Delivered" ? "rgba(157, 208, 255, 0.27)" : "red",
              borderRadius: "8px",
              padding: "10px",
            }}
          >
            <Stack direction="row">
              <Box sx={{ flex: "1 0 45%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Dish</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dish}
                    label="Age"
                    onChange={(e) => setDish(e.target.value)}
                  >
                    <MenuItem value="Thali">Thali</MenuItem>
                    <MenuItem value="Burger">Burger</MenuItem>
                    <MenuItem value="Curd Rice">Curd Rice</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ marginTop: "20px" }}>
                  <Typography variant="subtitle1">
                    {data.reduce(getSum, 0)} Order Delivered
                  </Typography>
                  {COLORS.map((background, index) => {
                    return (
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ marginTop: "10px" }}
                      >
                        <Paper
                          sx={{
                            width: "24px",
                            height: "12px",
                            background: `${background}`,
                            borderRadius: "3px",
                          }}
                        />
                        <Typography variant="caption">
                          {(
                            (data[index].value / data.reduce(getSum, 0)) *
                            100
                          ).toFixed(1)}
                          {"% "}
                          {data[index].name} ({data[index].value})
                        </Typography>
                      </Stack>
                    );
                  })}
                </Box>
              </Box>
              <Box sx={{ flex: "1 0 55%" }}>
                <Box>
                  <PieChart width={250} height={320}>
                    <Pie
                      data={data}
                      cx={120}
                      cy={200}
                      innerRadius={80}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </Box>
              </Box>
            </Stack>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Category;
