import React, { useEffect, useContext, useState } from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";
import ManagerDeleteConfirmation from "../manageOutlet/contactDetails/modal/ManagerDeleteConfirmation";
const ManagerList = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [managerId, setManagerId] = useState("");
  // const [managerList, setMangaerList] = useState([]);

  const [openDeleteManagerConfirmation, setOpenDeleteManagerConfirmation] =
  useState(false);  

const handleOpenDeleteManagerConfirmation = (id) => {
  setManagerId(id);
  setOpenDeleteManagerConfirmation(true);
};
const handleCloseDeleteManagerConfirmation = () => {
  setOpenDeleteManagerConfirmation(false);
};


  return (
    <>
      <Grid container spacing={2} pt={3}>
        {props.managerList.map((mangaer, index) => (
          <Grid
            item
            xs={12}
            key={index}
            mt={-4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <List sx={{ minWidth: "80%" }}>
              <ListItem
                secondaryAction={
                  <IconButton
                    sx={{ color: "red.light2" }}
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      handleOpenDeleteManagerConfirmation(mangaer._id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <PermIdentityIcon sx={{ fontSize: "30px" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={mangaer.name}
                  primaryTypographyProps={{
                    color: "black.primary",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                  secondary={
                    <>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        {mangaer.phone}
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                        {mangaer.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        ))}
      </Grid>

      <ManagerDeleteConfirmation
        handleCloseDeleteManagerConfirmation={
          handleCloseDeleteManagerConfirmation
        }
        openDeleteManagerConfirmation={openDeleteManagerConfirmation}
        managerId={managerId}
        getAllManager={props.getAllManager}
      />



    </>
  );
};

export default ManagerList;
