import React, { useContext, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";

import NewOrderCard from "./card/NewOrderCard";
import { Pagination } from "@mui/material";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";

const NewOrder = () => {
  const { setIsLoading, setApiCall } = useContext(AdminDataContext);
  const [newOrders, setNewOrder] = useState([]);

  const [totalItems, setTotalItems] = useState(0);

  setApiCall(true);
  const [pages, setPages] = useState(1);

  const limit = 12;
  const handlePageClick = async (event, pageNo) => {
    try {
      setPages((p) => {
        return pageNo;
      });
      newOrdersDetails(pageNo);
    } catch (error) {}
  };

  setApiCall(false);

  const newOrdersDetails = async (page) => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/owner/orders?page=${page}&limit=${limit}&restaurantId=${localStorage.getItem(
          "restaurantId"
        )}&status=Pending`
      );
      setNewOrder(res.data.data);
      setTotalItems(res.data.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    newOrdersDetails(1);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      newOrdersDetails(1);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "start" }}
      >
        {newOrders.map((orders, index) => (
          <NewOrderCard
            key={index}
            orderId={orders.orderId}
            createdTime={orders.createdAt}
            totalTax={orders.totalTax}
            price={orders.price}
            totalPrice={orders.totalPrice}
            orderItems={orders.orderItems}
            note={orders.note}
            customerName={orders.customer.name}
            customerNumber={orders.customer.phone}
            customerAddress={orders.customerAddress}
            id={orders._id}
            newOrdersDetails={newOrdersDetails}
            pages={pages}
          />
        ))}

        {newOrders.length < 1 ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                marginTop={7}
                sx={{ width: { xs: "95%", sm: "60%", md: "30%" } }}
                src="/images/noOrdersAvailale.svg"
              ></Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={"flex"}
          sx={{ justifyContent: { xs: "center", md: "end" }, my: 3 }}
        >
          <Pagination
            count={
              Math.trunc(totalItems / limit) +
              (totalItems % limit === 0 ? +0 : +1)
            }
            color="secondary"
            onChange={handlePageClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewOrder;
