import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefundDetailsModal from "../refundDetailModal/RefundDetailsModal";
import { imgUrl } from "../../../../../url";
import { time } from "../../../liveOrders/ExtractTime";
import { findDate } from "../../filterDate";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RefundConfirmationModal from "../refundDetailModal/RefundConfirmationModal";
import RefundCancelConfirmation from "../refundDetailModal/RefundCancelConfirmation";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";

// import RejectNewReservationModal from "../modal/RejectNewReservationModal";

const NewComplaintsCard = (props) => {
  // const [refundDetailModal, setRefundDetailModal] = useState(false);

  // const handleOpenRefundDetailModal = () => setRefundDetailModal(true);
  // const handleCloseRefundDetailModal = () => setRefundDetailModal(false);
  const [complaintId, setComplaintId] = useState("");

  const [refundConfirmationModal, setRefundConfirmationModal] = useState(false);

  const handleOpenRefundConfirmationModal = (id) => {
    setRefundConfirmationModal(true);
    setComplaintId(id);
  };
  const handleCloseRefundConfirmationModal = () =>
    setRefundConfirmationModal(false);

  // Cancel Confirmation

  const [refundCancelConfirmationModal, setRefundCancelConfirmationModal] =
    useState(false);

  const handleOpenRefundCancelConfirmationModal = (id) => {
    setRefundCancelConfirmationModal(true);
    setComplaintId(id);
  };
  const handleCloseRefundCancelConfirmationModal = () =>
    setRefundCancelConfirmationModal(false);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let orderItemLength = props.complaint.order.orderItems.length;
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return (
      <Box
        sx={{
          color: "blue.main",
          fontSize: "14px",
          display: orderItemLength <= 1 ? "none" : "block",
        }}
      >
        See More  <IconButton color="blue" {...other} />
      </Box>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const filterTime = time(props.complaint.createdAt);
  const filterDates = findDate(props.complaint.createdAt);
  const rejectBtn = {
    backgroundColor: "pink.brinkPink",
    color: "white.main",
    width: "100%",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",

    "&:hover": {
      backgroundColor: "pink.brinkPink",
    },
  };
  const acceptBtn = {
    backgroundColor: "green.midGreen",
    color: "white.main",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    // border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    width: "100%",

    "&:hover": {
      backgroundColor: "green.midGreen",
    },
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4} p={3} mt={3}>
        <Card elevation={0}>
          <CardContent
            elevation={10}
            sx={{
              backgroundColor: "whisper.main",
              minWidth: "100%",
              boxShadow: 2,
              borderRadius: "10px",
              minHeight:350
            }}
          >
            <Grid
              container
              spacing={2}
              mb={2}
              sx={{
                background:
                  "linear-gradient(90.05deg, #FFB55E 6.79%, rgba(255, 181, 94, 0) 94.36%)",
              }}
            >
              <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 2,
                    color: "red.tertiary",
                  }}
                >
                  YBITES DELIVERY
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: 1,
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                  {/* ID : 56459629 */}
                  {`ID:${props.complaint.order.orderId}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "black.primary",
                  }}
                >
                  {props.complaint.order.customer.name}
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Button variant="outlined" startIcon={<PhoneForwardedIcon />}>
                  Call
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={0}>
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Placed : {filterTime}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "black.primary",
                }}
              >
                {filterDates}
              </Grid>
            </Grid>

            {props.complaint.order.orderItems.map((item, index) =>
              index !== 0 ? (
                <></>
              ) : (
                <>
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={8}>
                      <Box sx={{ display: "flex" }}>
                        <img
                          src="/images/orderItemImg.svg"
                          alt="orderItemImg"
                        />
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                        >
                          {item.quantity} X {item.menuItemTitle}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {item.customizationItemTitles.map(
                          (customizationItemTitles, index) =>
                            `${customizationItemTitles}, `
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        Add Ons -
                        {item.addOnItemTitles.map(
                          (addOnItemTitles, index) => ` ${addOnItemTitles}, `
                        )}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "black.tertiary",
                        }}
                      >
                        {item.optionItemTitles.map(
                          (optionItemTitles, index) => `${optionItemTitles}, `
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                        ₹{Math.round(item.totalPrice * 100) / 100}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )
            )}

            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {props.complaint.order.orderItems.map((item, index) =>
                index === 0 ? (
                  <></>
                ) : (
                  <>
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={8}>
                        <Box sx={{ display: "flex" }}>
                          <img
                            src="/images/orderItemImg.svg"
                            alt="orderItemImg"
                          />
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: 600, mx: 1 }}
                          >
                            {item.quantity} X {item.menuItemTitle}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          {item.customizationItemTitles.map(
                            (customizationItemTitles, index) =>
                              `${customizationItemTitles}, `
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          Add Ons -
                          {item.addOnItemTitles.map(
                            (addOnItemTitles, index) => ` ${addOnItemTitles}, `
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "black.tertiary",
                          }}
                        >
                          {item.optionItemTitles.map(
                            (optionItemTitles, index) => `${optionItemTitles}, `
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", justifyContent: "end" }}
                      >
                        <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                          ₹{Math.round(item.totalPrice * 100) / 100}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )
              )}
            </Collapse>

            <Grid container pt={1} spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  Total Bill
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "black.primary",
                  }}
                >
                  ₹ {props.complaint.order.price}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container pt={1.5} spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "red.light2",
                  }}
                >
                  <ErrorOutlineIcon sx={{ fontSize: "18px" }} />

                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                  >
                    Complaint
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "red.rustRedLight",
                  }}
                >
                  {props.complaint.customerTitle}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "black.tertiary",
                  }}
                >
                  {props.complaint.customerDescription}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "blue.primary",
                      }}
                    >
                      See Image
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      component={"img"}
                      src={`${imgUrl}/${props.complaint.image}`}
                      width="100%"
                      maxHeight="200px"
                      mt={1}
                    ></Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "green.kellyGreen",
                  }}
                >
                  See full Image
                </Typography> */}
              </Grid>
            </Grid>
          </CardContent>

          <Grid
            container
            spacing={2}
            mt={1}
            sx={{ backgroundColor: "transparent" }}
          >
            <Grid item xs={4}>
              <Button
                sx={rejectBtn}
                onClick={() =>
                  handleOpenRefundCancelConfirmationModal(props.complaint._id)
                }
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={acceptBtn}
                onClick={() =>
                  handleOpenRefundConfirmationModal(props.complaint._id)
                }
                id="Refund"
                variant="contained"
                size="large"
              >
                Refund
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <RefundConfirmationModal
        refundConfirmationModal={refundConfirmationModal}
        handleCloseRefundConfirmationModal={handleCloseRefundConfirmationModal}
        complaintId={complaintId}
        getAllNewComplaints={props.getAllNewComplaints}
      />

      <RefundCancelConfirmation
        refundCancelConfirmationModal={refundCancelConfirmationModal}
        handleCloseRefundCancelConfirmationModal={
          handleCloseRefundCancelConfirmationModal
        }
        complaintId={complaintId}
        getAllNewComplaints={props.getAllNewComplaints}
      />

      {/* <RefundDetailsModal
        refundDetailModal={refundDetailModal}
        handleCloseRefundDetailModal={handleCloseRefundDetailModal}
        complaint={props.complaint}
      /> */}
    </>
  );
};

export default NewComplaintsCard;
