import React, { useState } from "react";
import {
  Paper,
  Box,
  Grid,
  Container,
  Button,
  Typography,
  FormControlLabel,
  Stack,
  InputAdornment,
  TextField,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MenuTabs from "../OldMenuItems/MenuTabs";
import MenuEditHistoryTopBar from "./MenuEditHistoryTopBar";

const MenuEditHistory = () => {
  const [btnBgColor, setBtnBgColor] = useState({
    approvedBtn: false,
    pendingBtn: false,
    cancelledBtn: false,
  });

  const approvedClick = () => {
    setBtnBgColor({
      ...btnBgColor,
      approvedBtn: true,
      pendingBtn: false,
      cancelledBtn: false,
    });
  };
  const pendingClick = () => {
    setBtnBgColor({
      ...btnBgColor,
      approvedBtn: false,
      pendingBtn: true,
      cancelledBtn: false,
    });
  };
  const cancelledClick = () => {
    setBtnBgColor({
      ...btnBgColor,
      approvedBtn: false,
      pendingBtn: false,
      cancelledBtn: true,
    });
  };

  const createBtn = {
    backgroundColor: "bgWhite.main",
    color: "primary.main",
    width: "max-content",
    height: "max-content",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    mx: 2,
    mt: 1,
    "&:hover": {
      backgroundColor: "bgWhite.main",
    },
  };
  const activeApprovedBtn = {
    backgroundColor: "rosyBrown.tertiary",
    color: "white.main",
    width: "max-content",
    height: "max-content",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    mx: 2,
    mt: 1,
    "&:hover": {
      backgroundColor: "rosyBrown.tertiary",
    },
  };
  const activePendingBtn = {
    backgroundColor: "rosyBrown.main",
    color: "white.main",
    width: "max-content",
    height: "max-content",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    mx: 2,
    mt: 1,
    "&:hover": {
      backgroundColor: "rosyBrown.main",
    },
  };
  const activeCancelledBtn = {
    backgroundColor: "rosyBrown.primary",
    color: "white.main",
    width: "max-content",
    height: "max-content",
    border: "2px solid #F75B28",
    borderRadius: "5px",
    textTransform: "capitalize",
    mx: 2,
    mt: 1,
    "&:hover": {
      backgroundColor: "rosyBrown.primary",
    },
  };
  const textFieldStyle = {
    width: { xs: "100%", sm: "80%", md: "100%" },
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: "1px solid black",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
  return (
    <>
      
      <MenuEditHistoryTopBar />
      <Container fixed sx={{ my: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          gap={1}
          sx={{ position: "relative" }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center", md: "start" },
              }}
            >
              <Box>
                <FormControlLabel
                  control={<ArrowBackIosNewIcon />}
                  label={
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 700,
                          fontSize: { xs: "18px", sm: "22px" },
                          lineHeight: "27px",
                          color: "green.secondary",
                          justifySelf: "start",
                        }}
                      >
                        All History menu edits
                      </Typography>
                    </>
                  }
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "green.secondary",
                    ps: 4,
                  }}
                >
                  From 30-12-2022 To 30-12-2022
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "center", md: "end" },
              }}
            >
              <Button
                onClick={approvedClick}
                sx={btnBgColor.approvedBtn ? activeApprovedBtn : createBtn}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Approved
              </Button>
              <Button
                onClick={pendingClick}
                sx={btnBgColor.pendingBtn ? activePendingBtn : createBtn}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Pending
              </Button>
              <Button
                onClick={cancelledClick}
                sx={btnBgColor.cancelledBtn ? activeCancelledBtn : createBtn}
                id="sign-in-button"
                variant="contained"
                size="large"
              >
                Cancelled
              </Button>
            </Grid>
          </Grid>
        </Stack>

        <Grid container mt={5} spacing={2}>
          <Grid item xs={4} sm={3} md={3}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: "15px", md: "20px" },

                color: "green.secondary",
                justifySelf: "start",
              }}
            >
              20 December
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} md={9}>
            <Grid
              container
              spacing={2}
              sx={{ border: "1px solid black", borderRadius: "12px" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "12px", md: "16px" },

                    color: "green.secondary",
                    justifySelf: "start",
                  }}
                >
                  Vangachi bhaji
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "12px", md: "16px" },

                    color: "green.tertiary",
                    justifySelf: "start",
                  }}
                >
                  Image added
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "12px", md: "16px" },

                    color: "blue.tertiary",
                    justifySelf: "start",
                  }}
                >
                  Update image
                </Typography>
                <img
                  src="/images/addOnsImg.svg"
                  style={{ width: "100%", height: "150px" }}
                  alt=""
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  sx={{
                    color: "blue.tertiary",
                    fontSize: { xs: "12px", md: "16px" },
                  }}
                >
                  Price added
                </Typography>

                <TextField
                  size="small"
                  placeholder="Unit Price"
                  name="unitPrice"
                  type="number"
                  variant="outlined"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={textFieldStyle}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                display="flex"
                justifyContent={"center"}
              >
                <Button
                  // onClick={handleOpenAddOnModal}
                  sx={createBtn}
                  id="sign-in-button"
                  variant="contained"
                  size="large"
                >
                  Approved
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MenuEditHistory;
