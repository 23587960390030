import React, { useState } from "react";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  useMediaQuery,
  Stack,
  Button,
  FormControlLabel,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";

import { useContext } from "react";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";


const EditAddOnCategory = (props) => {
    const matches = useMediaQuery("(min-width:1100px)");
    const matchesDown = useMediaQuery("(min-width:646px)");
    const { setIsLoading, snackbar } = useContext(AdminDataContext);
    const [data, setData] = useState({});
  
    const handleChange = (event) => {
      setData({ ...data, [event.target.name]: event.target.value });
      console.log(data);
    };
  
    const editAddOnCategory = async (e) => {
      e.preventDefault();
      let res = {};   
      try {
        setIsLoading(true);
  
        res = await axios.post(
          `/api/v1/owner/addon/edit?addOnId=${props.addonId}`,
          { ...data }
        );

        props.handleCloseEditAddOnModal();
        props.getAllAddOns();
  
        console.log(res);
  
        snackbar(res.data.message, "success");
        setData({});
        setIsLoading(false);
        props.handleCloseEditCategoryModal();
      } catch (error) {
        console.log(error);
        snackbar(error.response.data.message, "error");
  
        setIsLoading(false);
      }
    };
    const active = {
      width: "auto",
    
      background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
      boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
      color: "white.main",
      minWidth:"140px",
      paddingY: 1,
      paddingX: 2,
      
      textTransform: "capitalize",
      fontSize: "18px",
      fontWeight: 500,
     
      textDecoration: "none",
      borderRadius: "6px",
    };
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: matches ? "30%" : matchesDown ? "60%" : "90%",
      bgcolor: "background.paper",
      borderRadius: "16px",
      boxShadow: 24,
      p: 4,
    };
  
    const textFieldStyle = {
      width: { xs: "100%" },
      borderRadius: "10px",
      // background: "rgba(255, 255, 255, 0.31)",
      color: "rgba(255, 255, 255, 0.96)",
      border: "1px solid black",
      "& fieldset": {
        border: "none",
        color: "rgba(255, 255, 255, 0.96);",
      },
    };
  

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openEditAddOn}
        onClose={props.handleCloseEditAddOnModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.openEditAddOn}>
          <Box noValidate sx={style}>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
                sx={{ position: "relative" }}
              >
                <FormControlLabel
                  control={<ArrowBackIosNewIcon />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "27px",
                        color: "rgba(13, 30, 55, 0.67)",
                        justifySelf: "start",
                      }}
                    >
                   Edit AddOn Category
                    </Typography>
                  }
                />

                <IconButton
                  onClick={props.handleCloseEditAddOnModal}
                  aria-label="delete"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>
              <Grid
                container
                spacing={2}
                component="form"
                onSubmit={editAddOnCategory}
              >
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    fontSize="16px"
                    sx={{ color: "grey.main" }}
                  >
                     AddOn Category
                  </Typography>

                  <TextField
                    size="small"
                    name="title"
                    defaultValue={props.addOnTitle}
                    value={data.title}
                    required
                    onChange={handleChange}
                    placeholder="Title"
                    sx={textFieldStyle}
                  />
                </Grid>

                <Grid container spacing={2} px={3} mt={3}>
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",

                        justifyContent: { xs: "center" },
                        alignItems: "end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        type="submit"
                        sx={active}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default EditAddOnCategory