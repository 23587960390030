import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const center = {
  lat: 23.21,
  lng: 78.38,
};

function SimpleMap(props) {
  const [zoom, setZoom] = useState(14);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDr2kuGpu02LslF4N2Xbg5aopkWbE0Qtkg",
  });

  const [map, setMap] = React.useState(null);

  const onClick = (e) => {
    props.setCoordinates({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
    // props.getWeatherData();
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      onClick={onClick}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(SimpleMap);
