import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Sales from "./Sales/Sales";
import Orders from "./Orders/Orders";
import DeliveredOrderBarChart from "./DeliveredOrderBarChart/DeliveredOrderBarChart";
import OrderOps from "./OrderOps/OrderOps";
import { useContext } from "react";
import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
const Reports = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [today, setToday] = useState({});
  const [week, setWeek] = useState({});
  const [month, setMonth] = useState({});
  const getOrderDetail = async () => {
    try {
      setIsLoading(true);

      const res1 = await axios.get(
        `/api/v1/owner/report/today?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      const res2 = await axios.get(
        `/api/v1/owner/report/week?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      const res3 = await axios.get(
        `/api/v1/owner/report/month?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setToday(res1.data.data);
      setWeek(res2.data.data);
      setMonth(res3.data.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
 

  useEffect(() => {
    getOrderDetail();
  }, []);

  const mainHeading = {
    fontSize: "20px",
    fontWeight: 600,
    color: "green.secondary",
  };

  return (
    <Stack direction="column" width="100%" sx={{ backgroundColor: "#ececec" }}>
      <Box
        sx={{
          height: "fit-content",
          backgroundColor: "#fff",
          // paddingX: "32px",
          paddingY: "16px",
        }}
      >
        {/* <Box>
          <Button
            sx={{
              background:
                "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
            }}
            variant="contained"
          >
            All Reports
          </Button>
          <Box
            sx={{
              width: "187px",
              height: "10px",
              background:
                "linear-gradient(90.16deg, #FFB55E 0.14%, rgba(255, 181, 94, 0) 106.88%)",
              borderRadius: "9px",
              marginTop: "1rem",
            }}
          />
        </Box> */}
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: "fit-content",
          marginX: "32px",
          marginY: "10px",
          padding: "10px",
          border: "1px solid rgba(0, 0, 0, 0.14)",
          borderRadius: "12px",
        }}
      >
        <Typography sx={mainHeading}>Reports</Typography>
        <Box>
          <Typography sx={mainHeading}>Customer Ratings</Typography>
          <Rating
            name="simple-controlled"
            // value={value}
            value="3"
            // onChange={(event, newValue) => {
            // setValue(newValue);
            // }}
          />
        </Box>
      </Stack>
      <Sales status={"Delivered"} today={today} week={week} month={month} />

      <Box p={2} my={2} mb={25}>
        {/* <Orders status={"Delivered"} /> */}
        <DeliveredOrderBarChart />
        <Sales status={"Rejected"} />
        {/* <Orders status={"Rejected"} /> */}
        <DeliveredOrderBarChart />
        {/* <OrderOps /> */}
      </Box>
    </Stack>
  );
};

export default Reports;
