import * as React from "react";
import Box from "@mui/material/Box";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { NavLink } from "react-router-dom";
import Switch from "@mui/material/Switch";

import FormControlLabel from "@mui/material/FormControlLabel";
import FeedbackIcon from '@mui/icons-material/Feedback';
import Badge from "@mui/material/Badge";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import LogoutModalHandler from "./LogoutModalHandler";
import FeedbackNotifications from "./feedbackPages/FeedbackNotifications";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#ffffff",
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ffffff",
  },
}));

const ActionRestaurantHandler = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [logoutModal, setLogoutModal] = React.useState(false);
  const handleOpenLogoutModal = () => setLogoutModal(true);
  const handleCloseLogoutModal = () => setLogoutModal(false);

  const [notificationModal, setNotificationModal] = React.useState(false);
  const handleOpenNotificationModal = () => setNotificationModal(true);
  const handleCloseNotificationModal = () => setNotificationModal(false);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>

      <Tooltip title="Feedback">

        <Badge
        //  badgeContent={4}
          onClick={handleOpenNotificationModal} 
          color="success" >
          <FeedbackIcon
            sx={{
              color: "white.main",
              fontSize: "40px",
              mx:2,
            }}
          />
        </Badge>
        </Tooltip>
        {/* <FormControlLabel
          value="bottom"
          control={
            <PinkSwitch
              // {...label}
              defaultChecked
            />
          }
          label={
            <Typography
              sx={{ mt: -1.5, color: "white.main", fontSize: "14px" }}
            >
              Online
            </Typography>
          }
          labelPlacement="bottom"
        /> */}

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AccountCircleIcon sx={{ fontSize: "40px" }} color="white" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              // ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <NavLink
          style={{
            textDecoration: "none",
            color: "#686868",
          }}
          to="/change-restaurant"
        >
          {" "}
          <MenuItem
            sx={{ backgroundColor: "grey.light" }}
            onClick={handleClose}
          >
            {" "}
            Switch Restaurant{" "}
          </MenuItem>
        </NavLink>

        <NavLink
          style={{
            textDecoration: "none",
            color: "#686868",
          }}
          // to="/logout"
        >
          {" "}
          <MenuItem
            sx={{ backgroundColor: "grey.light", mt: 1 }}
            onClick={() => {
              handleClose();
              handleOpenLogoutModal();
            }}
          >
            Logout
          </MenuItem>
        </NavLink>
      </Menu>
      <LogoutModalHandler
        handleCloseLogoutModal={handleCloseLogoutModal}
        logoutModal={logoutModal}
      />
      <FeedbackNotifications
        notificationModal={notificationModal}
        handleCloseNotificationModal={handleCloseNotificationModal}
      />
    </>
  );
};

export default ActionRestaurantHandler;
