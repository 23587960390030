import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Button } from "@mui/material";

import { TextField } from "@mui/material";

import { styled } from "@mui/material/styles";
import { AdminDataContext } from "../../../../useContext/AdminContext";
import axios from "axios";
import { imgUrl } from "../../../../url";
import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Documents = () => {
  const StyledForm = styled("form")({
    width: "100%",
  });

  const StyledInput = styled(TextField)({
    width: "100%",
  });
  const StyledButton = styled(Button)({
    width: "100%",
  });

  const { snackbar, setIsLoading } = useContext(AdminDataContext);

  const [fssaiDocuments, setFssaiDocuments] = useState({});
  const [gstDocuments, setGstDocuments] = useState({});

  // const onBankDetailChange = (e) => {
  //   setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  // };

  const getDocuments = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/restaurant/details?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );

      setFssaiDocuments(res.data.data.fssaiCertificate);
      setGstDocuments(res.data.data.gstCertificate);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };



  useEffect(() => {
    getDocuments();
  }, []);

  const textFieldStyle = {
    borderRadius: "10px",

    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  const documentBox = {
    borderRadius: "10px",
color: "green.secondary",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    display:'flex',
    justifyContent:"space-between",
    px:1.5,
    py:1,
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };
const documentIcon = {
  display:"flex",
  alignItems: "center",
}

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", mt: 2 }}
      >
        <Grid item xs={12} sm={8} md={6} sx={{ mx: 1 }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "25px",
              color: "green.secondary",
            }}
          >
            {" "}
            FSSAI License
          </Typography>
          <Typography sx={{ color: "green.secondary" }}>
            {" "}
            License Number
          </Typography>

          <StyledInput
            fullWidth
            variant="outlined"
            required
            size="small"
            name="certificateNo"
            disabled
            value={fssaiDocuments.certificateNo}
            sx={textFieldStyle}
          />
        </Grid>

        <Grid item xs={12} sm={8} md={6} sx={{ mx: 1 }}>
          <Typography sx={{ color: "green.secondary" }}>
            {" "}
            License Document
          </Typography>

          <Box sx={documentBox}>
            {fssaiDocuments.documentFile ? (<Box sx={documentIcon}> <ArticleIcon color="blue" /> { " " }  FSSAI Licence</Box>) : (<></>)}

            <CheckCircleIcon color="primary" />
          </Box>

          {/* <StyledInput
            fullWidth
            variant="outlined"
            required
            type="file"
            size="small"
            sx={textFieldStyle}
          /> */}
        </Grid>

        {/* <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              // type="submit"
              sx={{
                background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                color: "white.main",
              }}
            >
              Submit
            </StyledButton>
          </Grid> */}
      </Grid>

      {/* GST */}

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 5 }}
      >
        <Grid item xs={12} sm={8} md={6} sx={{ mx: 1 }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "25px",
              color: "green.secondary",
            }}
          >
            {" "}
            GST Certificate
          </Typography>
          <Typography sx={{ color: "green.secondary" }}> GST Number</Typography>

          <StyledInput
            fullWidth
            variant="outlined"
            required
            size="small"
            disabled
            name="gstNumber"
            value={gstDocuments.gstNumber}
            sx={textFieldStyle}
          />
        </Grid>

        <Grid item xs={12} sm={8} md={6} sx={{ mx: 1 }}>
          <Typography sx={{ color: "green.secondary" }}>
            {" "}
            License Document
          </Typography>

          <Box sx={documentBox}>
            {gstDocuments.documentFile ? (<Box sx={documentIcon}> <ArticleIcon color="blue" /> { " " }  GST Licence</Box>) : (<></>)}

            <CheckCircleIcon color="primary" />
          </Box>



          {/* <StyledInput
            fullWidth
            variant="outlined"
            disabled
            required
            type="file"
            size="small"
            sx={textFieldStyle}
          /> */}
        </Grid>

        {/* <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                // type="submit"
                sx={{
                  background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
                  boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                  color: "white.main",
                }}
              >
                Submit
              </StyledButton>
            </Grid> */}
      </Grid>
    </>
  );
};

export default Documents;
