import {
  Grid,
  Box,
  Stack,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
  Button,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import React, { useContext } from "react";
import { AdminDataContext } from "../../../../../useContext/AdminContext";
import axios from "axios";

const ManagerDeleteConfirmation = (props) => {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const deleteManager = async (managerId) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `/api/v1/owner/remove/manager/${managerId}`
      );
      snackbar(res.data.message, "success");
      props.getAllManager();
      props.handleCloseDeleteManagerConfirmation();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      props.handleCloseDeleteManagerConfirmation();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
  };
  const cancelBtn = {
    width: "auto",

    backgroundColor: "grey.light2",

    color: "green.secondary",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: 1,
    textDecoration: "none",
    borderRadius: "11px",
    px: 5,
    "&:hover": {
      backgroundColor: "grey.light2",
      color: "green.secondary",
    },
  };

  const logoutBtn = {
    width: "auto",
    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white",
    textTransform: "capitalize",
    fontSize: "20px",
    fontWeight: 500,
    mt: 1,
    textDecoration: "none",
    borderRadius: "11px",
    px: 5,
  };
  return (
    <>
      <Modal
        open={props.openDeleteManagerConfirmation}
        onClose={props.handleCloseDeleteManagerConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box noValidate sx={style}>
          <Box>
            <Stack
              direction="row"
              alignItems="start"
              justifyContent={"space-between"}
              gap={1}
              sx={{ position: "relative" }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "22px",

                    color: "green.secondary",
                    justifySelf: "start",
                  }}
                >
                  Are you sure you want to delete the manager ?
                </Typography>
              </Box>

              <IconButton
                onClick={props.handleCloseDeleteManagerConfirmation}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Stack>
            <Grid
              container
              //   onSubmit={refundHandler}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  onClick={props.handleCloseDeleteManagerConfirmation}
                  sx={cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  onClick={() => deleteManager(props.managerId)}
                  variant="contained"
                  sx={logoutBtn}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ManagerDeleteConfirmation;
