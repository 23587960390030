import { Grid, Box, Container, Typography, Button } from "@mui/material";

import React, { useState, useContext, useEffect } from "react";

import { AdminDataContext } from "../../../useContext/AdminContext";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import AddMenuItemVariations from "./modal/AddMenuVariationsModal";
import CreateVariationItem from "./modal/CreateMenuVariationModal";
import EditItemCategory from "./modal/modalForEditCategory&Subcategory/EditItemCategory";
import EditItemSubCategory from "./modal/modalForEditCategory&Subcategory/EditItemSubCategory";

const AddItemCategory = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [openItemModal, setOpenItemModal] = useState(false);
  const [id, setId] = useState("");

  const [itemTitle, setItemTitle] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [menuCategories, setMenuCategories] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [data, setData] = useState({});

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseItemModal = () => setOpenItemModal(false);

  // --------------------------------
  // For edit category
  // --------------------------------

  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);

  const handleOpenEditCategoryModal = (item) => {
    setOpenEditCategoryModal(true);
    setData(item);
  };
  const handleCloseEditCategoryModal = () => setOpenEditCategoryModal(false);

  // --------------------------------
  // For edit  subcategroy
  // --------------------------------

  const [openEditSubCategoryModal, setOpenEditSubCategoryModal] =
    useState(false);
 

  const handleOpenEditSubCategoryModal = (item) => {
    setOpenEditSubCategoryModal(true);
   setData(item);
   console.log("item ",item);
  };
  const handleCloseEditSubCategoryModal = () =>
    setOpenEditSubCategoryModal(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenItem = (id, title) => {
    setOpenItemModal(true);
    setId(id);
    setItemTitle(title);
  };

  const getAllMenuCategory = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `/api/v1/owner/menu-category?restaurantId=${localStorage.getItem(
          "restaurantId"
        )}`
      );
      setMenuCategories(res.data.menuCategory);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllMenuCategory();
  }, []);

  const styleBox = {
    // transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",
    //    boxShadow: 24,
    // p: 2,
  };
  const active = {
    width: "auto",

    background: `linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)`,
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
    color: "white.main",

    paddingY: 1,
    paddingX: 1,

    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,

    textDecoration: "none",
    borderRadius: "6px",
  };



  const headingTextStyle = {
    fontWeight: 600,
    fontSize: "18px",
    color: "white.main",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };
  const subCategoryHeading = {
    fontWeight: 500,
    fontSize: "16px",
    color: "black.main",
    border: "none",
    textTransform: "capitalize",
    "&:hover": {
      border: "none",
    },
  };
  const headingText = { fontWeight: 600,  fontSize: { xs: "17px", md: "20px" } };
  return (
    <>
      <Container fixed sx={{ py: 0.2 }}>
        <Box component="form" noValidate sx={styleBox}>
          <Box>
            <Grid
              container
              sx={{
                my: 2,
                borderRadius: "11px",

                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={headingText}>
                {" "}
                {menuCategories.length} Category List
              </Typography>

              <Button variant="outlined" onClick={handleOpenModal} sx={active}>
                ADD CATEGORY <span style={{ fontSize: "22px" }}>+</span>
              </Button>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "center", pb: 2 }}
            >
              {menuCategories.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleAccordionChange(`panel${index + 1}`)}
                    key={item._id}
                    sx={{
                      backgroundColor: "transparent",
                      border: "1px solid rgba(0, 0, 0, 0.34)",
                      borderRadius: "21px",
                      width: "100%",
                    }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="white" />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      sx={{
                        background:
                          "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                        borderRadius: "2px",
                      }}
                    >
                      <Button
                        onClick={() => handleOpenEditCategoryModal(item)}
                        variant="outlined"
                        boxShadow={0}
                        sx={headingTextStyle}
                        endIcon={<EditIcon />}
                      >
                        {item.title}
                      </Button>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.menuSubCategories.length === 0 ? (
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Grid
                              item
                              xs={10}
                              sm={7}
                              md={6}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "70%",
                                  height: "70%",
                                  borderRadius: "20px",
                                  mt: 3,
                                }}
                                component="img"
                                alt="addOns"
                                src="/images/zeroSubCategory.svg"
                              ></Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} mt={3}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",

                                justifyContent: { xs: "center", md: "end" },
                                alignItems: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleOpenItem(item._id, item.title)
                                }
                                sx={active}
                              >
                                ADD SUB CATEGORY
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {item.menuSubCategories.map(
                            (menuSubCategories, index) => (
                              <Box
                                key={index}
                                pt={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {/* <Typography sx={{ ms: 5 }}>
                                {menuSubCategories.title}
                              </Typography> */}

                                <Button
                                  onClick={() =>
                                    handleOpenEditSubCategoryModal(
                                      menuSubCategories
                                    )
                                  }
                                  variant="outlined"
                                  boxShadow={0}
                                  sx={subCategoryHeading}
                                  endIcon={<EditIcon />}
                                >
                                  {menuSubCategories.title}
                                </Button>

                                {/* <Typography sx={{ me: 5 }}>
                                {"₹" + addOnItem.price}
                              </Typography> */}
                              </Box>
                            )
                          )}

                          <Grid container spacing={2} mt={3}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",

                                justifyContent: { xs: "center", md: "end" },
                                alignItems: "end",
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={() =>
                                 
                                  handleOpenItem(item._id, item.title)

                                }
                                sx={active}
                              >
                                ADD SUB CATEGORY1
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>

            <Box
              sx={{ display: menuCategories.length === 0 ? "block" : "none" }}
            >
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  xs={10}
                  sm={7}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "70%",
                      borderRadius: "20px",
                      mt: 3,
                    }}
                    component="img"
                    alt="menuCategories"
                    src="/images/addSomeCategory.svg"
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <AddMenuItemVariations
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          getAllMenuCategory={getAllMenuCategory}
          heading="Category Title"
        />

        <CreateVariationItem
          openItemModal={openItemModal}
          handleCloseItemModal={handleCloseItemModal}
          getAllMenuCategory={getAllMenuCategory}
          id={id}
          categoryIdtitle={itemTitle}
          heading="Sub Category Title"
          title="Sub Category Title"
        />

        <EditItemCategory
          openEditCategoryModal={openEditCategoryModal}
          handleCloseEditCategoryModal={handleCloseEditCategoryModal}
          getAllMenuCategory={getAllMenuCategory}
          component = "category-list"
          setData={setData}
          data={data}
        />

        <EditItemSubCategory
          openEditSubCategoryModal={openEditSubCategoryModal}
          handleCloseEditSubCategoryModal={handleCloseEditSubCategoryModal}
      
          getAllMenuCategory={getAllMenuCategory}
         
          setData={setData}
          data={data}
        />
      </Container>
    </>
  );
};

export default AddItemCategory;
